/** @jsxImportSource @emotion/react */

// External
import _ from "lodash";
import { useState, useEffect } from "react";
import Loader from "react-loader";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isShipper } from "shared/utils/organizations.utils";

// Components
// import LastUpdateDisplay from "modules/shipment-detail/shipment-detail-styled-components/LastUpdateDisplay";
// import ShipmentDetailTabs from "modules/shipment-detail/ShipmentDetailTabs";
// import { ShipmentTimeline } from "modules/shipment-detail/ShipmentContentPanel";
import { DetailWithMap } from "components/templates/DetailWithMap.template";
import { RoutingMapContextProvider } from "modules/map/components/RoutingMapContext";
import { CoordinatesTable } from "components-old/CoordinatesTable";
import { ContainerTrackingHistoryTab } from "./components/ContainerTrackingHistoryTab";
import { ContainerTrackingExceptionsTab } from "./components/ContainerTrackingExceptionsTab";
import { TabPanelPlaceholder } from "components/no-data-placeholders";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { Tabs } from "components/molecules/Tabs.molecule";
import { ContainerTrackingDetailsWidget } from "./components/ContainerTrackingDetailsWidget";
import GeofenceBuilderMapContainer from "modules/map/components/GeofenceBuilderMap";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
// import ShipmentDetailsCollapsible from "modules/shipment-detail/shipment-detail-styled-components/ShipmentDetailsCollapsible";
// import { ActionNav } from "modules/shipment-detail/ActionNav";
// import {
//   ArrivalAndDeparturePanelGroup,
//   getLocationDataFromShipments
// } from "components/organisms/ArrivalAndDeparturePanelGroup.organism";
// import RoutingMap from "modules/map/components/RoutingMap";
// import { HeatMapButton } from "components/atoms/HeatMapButton.atom";

// // Modals
// import { AlertMeModal } from "shared/components/modals/AlertMe.modal";
// import { UnderReviewModal } from "modules/shipment-detail/UnderReviewModal";
// import { AddAssetModal } from "modules/shipment-detail/AddAssetModal";
// import { ShipmentEventsModal } from "modules/shipment-detail/ShipmentEventsModal";
// import { BillOfLadingModal } from "modules/shipment-detail/BillOfLadingModal";
// import { CancelShipmentModal } from "modules/shipment-detail/CancelShipmentModal";
// import { MobileTrackingModal } from "modules/shipment-detail/MobileTrackingModal";
// import {
//   ReportCarrierDelayModal,
//   ClearCarrierDelayModal
// } from "modules/shipment-detail/ReportCarrierDelayModal";
import { CommentFeed } from "components/organisms/CommentFeed.organism";
import {
  // batchCsvShipmentExample,
  batchCsvContainerExample,
} from "components/search-bar/batch-comment-csv-data";

// Styles
import Colors from "styles/colors";
import {
  tabsCss,
  tabListStyle,
} from "components/multimodal-components/tabStyles";
import { LocationManagementModal } from "./components/ContainerTrackingLocationManagementModal";

// Utils
// import {
//   getAssetId,
//   getReferenceValue,
//   getRouteShipments
// } from "modules/shipment-detail/ShipmentUtils";
// import {
//   getMobileTrackingEnabled,
//   getIsShipmentWithinTrackingWindow
// } from "modules/shipment-detail/ShipmentUtils";
// import {
//   lastUpdateDisplayCss,
//   shipmentDetailTabsCss
// } from "modules/shipment-detail/multimodal/ShipmentLegTabs";
// import { Mode } from "shared/constants/shipments.const";

export const ContainerTrackingDetails = ({
  id,
  organization,
  shipmentModes,
  setWatchShipment,
  isShipmentSubscriptionLoading,
  subscriptionRequestError,
  isSubscriptionUpdating,
  subscriptionUpdateSuccess,
  subscriptionUpdateError,
  setShipmentWithUnresolvedLocation,
  childShipments,
  pushLocationMatchingView,
  subscribe,
  unsubscribe,
  updateSubscription,
  shipmentSubscription,
  editShipmentData,
  updateReviewStatus,
  assignAsset,
  assignTrailer,
  fetchShipmentSubscription,
  solutionId,
  ladsList,
  routeHeatmap,
  fetchDelayOptions,
  delayOptionsData,
  reportDelayData,
  reportDelay,
  clearDelay,
  clearDelayData,
  startMobileTracking,
  stopMobileTracking,
  containerDetails,
  parentShipment = {},
  clearReportDelayInState,
  createShipmentEvents,
  cancelShipment,
  updateShipment,
  fetchContainerComments,
  addContainerComments,
  addContainerBatchComments,
  clearContainerBatchComments,
  cancelContainerAddComment,
  updateContainerComment,
  cancelContainerUpdateComment,
  markContainerCommentsRead,
  isFetchingContainerComments,
  containerComments,
  isContainerBatchCommentInProgress,
  isContainerBatchCommentSuccessful,
  isContainerBatchCommentFailed,
  fetchComments,
  isFetchingComments,
  comments,
  addComment,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  addBatchComments,
  clearBatchComments,
  isBatchCommentInProgress,
  isBatchCommentSuccessful,
  isBatchCommentFailed,
  fetchNotification,
  isContainerDetailsLoading,
  isShipmentDetailsLoading,
  areBothDetailsLoading,
  containerMedia,
  containerPositionUpdates,
  clearReportShipmentEvents,
  iscontainerLocationDetailsLoading,
  containerLocationDetails,
  fetchContainerDetails,
  fetchShipmentDetails,
  fetchContainerMedia,
  clearContainerMedia,
  iscontainerSupplierLocationLoading,
  containerSupplierLocationDetails,
  fetchContainerSupplierLocationDetails,
  clearContainerDetails,
  addCoordinate,
  clearCoordinatesByType,
  selectedMapCoordinate,
  fetchContainerActiveExceptions,
  ctActiveExceptions,
  fetchContainerHistory,
  containerHistory,
  watchContainerActions,
  redirectTo404,
  detailsError,
  isLocationDetailsLoading,
  locationDetails,
  updateBucketLocations,
  fetchLocation,
  fetchBucket,
  bucketDetails,
  fetchBucketDetailsFromName,
  isValidNewBucketName,
  selectedBucketLocations,
  isBucketListLoading,
  isBucketDetailsLoading,
  isValidatingBucketName,
  isFetchingBucketLocations,
  updateLocationsData,
  locationChangesSavedStatus,
  failedLocations,
  resetFailedLocationsLists,
  resetSavedChanges,
  isErrorOnSave,
  resetError,
  resetBucketLoctions,
  resetUpdatedLocations,
  updateLocationsOnFail,
  updatedLocations,
  fetchCountries,
  fetchStates,
  countryList,
  stateList,
  validationError,
  resetValidationError,
}) => {
  const { t } = useTranslation("container-tracking");
  const [watch, setWatch] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showLocationManagementModal, setShowLocationManagementModal] =
    useState(false);
  // states for showing modals
  // const [showAlertMeModal, setShowAlertMeModal] = useState(false);
  // const [showUnderReviewModal, setShowUnderReviewModal] = useState(false);
  // const [showAddAssetModal, setShowAddAssetModal] = useState(false);
  // const [showShipmentEventsModal, setShowShipmentEventsModal] = useState(false);
  // const [showBillOfLadingModal, setShowBillOfLadingModal] = useState(false);
  // const [showCancelShipmentModal, setShowCancelShipmentModal] = useState(false);
  // const [
  //   showEnableMobileTrackingModal,
  //   setShowEnableMobileTrackingModal
  // ] = useState(false);
  // const [
  //   showDisableMobileTrackingModal,
  //   setShowDisableMobileTrackingModal
  // ] = useState(false);
  // const [
  //   showReportCarrierDelayModal,
  //   setShowReportCarrierDelayModal
  // ] = useState(false);
  // const [showClearCarrierDelayModal, setShowClearCarrierDelayModal] = useState(
  //   false
  // );

  // const [selectedLegID, setSelectedLegID] = useState(null);
  // const [showHeatmap, setShowHeatmap] = useState(false);

  const { rackCode = null, supplierCode } = containerDetails ?? {};
  const mapLocations = containerLocationDetails;

  // const enableActionMenu = isCarrier(organization) || isShipper(organization);
  // const enableAlertMeAction =
  //   isCarrier(organization) || isShipper(organization);
  // const enableReviewStatusAction =
  //   isCarrier(organization) || isShipper(organization);
  // const enableShipmentEventsAction =
  //   isCarrier(organization) || isShipper(organization);

  // const shipment =
  //   parentShipment?.shipment_details?.is_multileg &&
  //   Object.keys(childShipments).indexOf(
  //     containerDetails?.tripLegs[0]?.shipmentNumber
  //   ) >= 0
  //     ? childShipments[containerDetails?.tripLegs[0]?.shipmentNumber]
  //     : parentShipment;

  // const {
  //   origin,
  //   destination,
  //   calculatedEta,
  //   frozenEta,
  //   isFvEta
  // } = getLocationDataFromShipments([shipment]);

  // const isAlreadyCanceled = shipment.active_status === "canceled";
  // const enableCancelShipmentAction =
  //   (isCarrier(organization) || isShipper(organization)) && !isAlreadyCanceled;
  // const enableReportDelayAction =
  //   (isCarrier(organization) || isShipper(organization)) &&
  //   !isAlreadyCanceled &&
  //   [Mode.TRUCK, Mode.RAIL, Mode.PARCEL].includes(shipment.mode_name);

  // const enableClearDelayAction =
  //   (isCarrier(organization) || isShipper(organization)) &&
  //   !isAlreadyCanceled &&
  //   [Mode.TRUCK, Mode.RAIL, Mode.PARCEL].includes(shipment.mode_name) &&
  //   shipment.active_exceptions_ng === "Carrier Delayed";

  // const enableMobileTrackingAction =
  //   isCarrier(organization) || isShipper(organization);

  // const enableBillOfLadingAction =
  //   isCarrier(organization) || isShipper(organization);

  useSetTitleOnMount(t("container-tracking:Container Details"));

  useEffect(() => {
    fetchContainerHistory(id);
    fetchContainerActiveExceptions(id);
    fetchContainerDetails(id);
  }, [
    id,
    fetchContainerHistory,
    fetchContainerActiveExceptions,
    fetchContainerDetails,
  ]);

  // Redirect when errors happen
  useEffect(() => {
    if (detailsError?.response?.status === 404) {
      const errorMessage = t(
        'fv-vin-details:Details for Container "[[[id]]]" could not be found',
        {
          id,
        },
      );
      redirectTo404(errorMessage);
    }
  }, [detailsError, redirectTo404, t, id]);

  // useEffect(() => {
  //   if (
  //     containerDetails?.status === "In Transit" &&
  //     containerDetails?.tripLegs?.length > 0
  //   ) {
  //     let tripLeg = containerDetails?.tripLegs[0];
  //     fetchShipmentDetails(
  //       tripLeg?.creatorShipmentId,
  //       tripLeg?.carrierInformation?.carrierScac
  //     );
  //   }
  // }, [fetchShipmentDetails, containerDetails]);

  // useEffect(() => {
  //   if (!_.isEmpty(parentShipment)) {
  //     fetchShipmentSubscription(parentShipment);
  //   }
  // }, [fetchShipmentSubscription, parentShipment]);

  useEffect(() => {
    if (supplierCode) {
      fetchContainerSupplierLocationDetails(supplierCode);
    }
    // If the details have been updated we need to make sure
    // that our watch checkbox matches the value in the props
    const watchFlag =
      containerDetails?.watched?.toLowerCase() === "true" ?? false;
    setWatch(watchFlag);
  }, [supplierCode, containerDetails, fetchContainerSupplierLocationDetails]);

  useEffect(() => {
    clearContainerMedia();
    fetchContainerMedia(rackCode);
  }, [rackCode, clearContainerMedia, fetchContainerMedia]);

  useEffect(() => {
    if (containerPositionUpdates && containerPositionUpdates.length) {
      const data = containerPositionUpdates[0];
      const { latitude, longitude, time } = data;
      addCoordinate(
        MapCoordinateType.SELECTED_COORDINATE,
        latitude,
        longitude,
        time,
        "0_0",
        data,
        null,
        0,
      );
    }
  }, [containerPositionUpdates, addCoordinate]);

  // clean up container details on unmount
  useEffect(() => {
    return () => {
      clearContainerDetails();
    };
  }, [clearContainerDetails]);

  const eventHandler = (type) => {
    switch (type) {
      case "MANAGE_LOCATIONS":
        setShowLocationManagementModal(true);
        break;
      default:
        break;
    }
  };

  function getMapChildren(loading) {
    return loading || !mapLocations || _.isEmpty(mapLocations) ? (
      <GeofenceBuilderMapContainer
        mapLocations={[]}
        selectedMapCoordinate={selectedMapCoordinate}
        initialZoomLevel={selectedMapCoordinate ? 16 : 4}
      />
    ) : (
      <GeofenceBuilderMapContainer
        drawAllGeofences
        selectedLocation={{ ...mapLocations }}
        mapLocations={mapLocations ? [mapLocations] : []}
        selectedLad={mapLocations?.lad}
        selectedMapCoordinate={selectedMapCoordinate}
        useBoxChiclets
      />
    );
  }

  // const getShipmentMapChildren = (
  //   shipment,
  //   ladsList,
  //   routeShipments,
  //   showHeatmap,
  //   heatmapCoords,
  //   isMultiLeg
  // ) => {
  //   return (
  //     <Fragment>
  //       <RoutingMap
  //         ladsList={ladsList}
  //         shipments={routeShipments}
  //         showBreadCrumbs={true}
  //         showStopSequence={true}
  //         showHeatmap={showHeatmap}
  //         heatmapCoords={heatmapCoords}
  //         isMultiLeg={isMultiLeg}
  //         activeShipment={
  //           isMultiLeg
  //             ? shipment && shipment.active_shipment
  //               ? shipment.active_shipment.toString()
  //               : null
  //             : null
  //         }
  //       />
  //       {heatmapCoords?.length > 0 ? (
  //         <HeatMapButton
  //           isToggled={showHeatmap}
  //           onToggle={() => setShowHeatmap(oldShowHeatmap => !oldShowHeatmap)}
  //           css={{
  //             position: "absolute",
  //             top: 5,
  //             right: 0,
  //             zIndex: 2
  //           }}
  //         />
  //       ) : null}
  //     </Fragment>
  //   );
  // };

  // const isMultiLeg = parentShipment?.shipment_details?.is_multileg ?? false;

  // const heatmapCoords =
  //   routeHeatmap?.length > 0
  //     ? routeHeatmap
  //     : _.get(shipment, "current_location.updates");

  // const eventHandler = (val, type) => {
  //   switch (type) {
  //     case "EDIT_REVIEW_STATUS":
  //       setShowUnderReviewModal(true);
  //       break;
  //     case "SHOW_ADD_ASSET":
  //       setShowAddAssetModal(true);
  //       break;
  //     case "SHOW_ADD_EVENTS":
  //       setShowShipmentEventsModal(true);
  //       break;
  //     case "SHOW_CANCEL_SHIPMENT":
  //       setShowCancelShipmentModal(true);
  //       break;
  //     case "TOGGLE_ENABLE_MOBILE_TRACKING":
  //       setShowEnableMobileTrackingModal(true);
  //       break;
  //     case "TOGGLE_DISABLE_MOBILE_TRACKING":
  //       setShowDisableMobileTrackingModal(true);
  //       break;
  //     case "SHOW_REPORT_CARRIER_DELAY":
  //       setShowReportCarrierDelayModal(true);
  //       break;
  //     case "SHOW_CLEAR_CARRIER_DELAY":
  //       setShowClearCarrierDelayModal(true);
  //       break;
  //     case "SHOW_ALERT_ME_MODAL":
  //       setShowAlertMeModal(true);
  //       break;
  //     case "TIMELINE_LAD_CLICK":
  //       setShipmentWithUnresolvedLocation(shipment);
  //       pushLocationMatchingView(val.id);
  //       break;
  //     case "SELECT_LEG":
  //       setSelectedLegID(val);
  //       break;
  //     case "SHOW_BILL_OF_LADING":
  //       setShowBillOfLadingModal(true);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // let routeShipments = isMultiLeg
  //   ? [shipment]
  //   : getRouteShipments(
  //       parentShipment,
  //       childShipments,
  //       selectedLegID,
  //       !isShipmentDetailsLoading // which means is loaded
  //     );

  // Get Asset ID
  // const assetId = getAssetId(organization, shipment);
  // const parentShipmentAssetId = getAssetId(organization, parentShipment);

  // status of successfully created event
  // let successResponseStatusCode =
  //   editShipmentData?.statusArray?.includes(201) ?? null;

  let detailsChildren = (
    <Tabs
      css={tabsCss}
      onSelect={(index) => {
        setSelectedTabIndex(index);
      }}
      defaultIndex={selectedTabIndex}
    >
      <Tabs.TabList style={tabListStyle}>
        <Tabs.Tab
          style={{
            borderColor: `${Colors.tabs.BORDER}`,
            width: "50%",
          }}
        >
          {t("container-tracking:Container Details")}
        </Tabs.Tab>
        {/* {containerDetails?.status === "In Transit" ? (
          <Tabs.Tab
            key="shipment-details"
            style={{
              borderColor: `${Colors.tabs.BORDER}`,
              width: "50%"
            }}
          >
            {t("container-tracking:Shipment Details")}
          </Tabs.Tab>
        ) : null} */}
      </Tabs.TabList>
      <Tabs.TabPanel
        style={{
          backgroundColor: `${Colors.background.LIGHT_GRAY}`,
          border: `1px solid ${Colors.tabs.BORDER}`,
        }}
      >
        <div
          css={{
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
          }}
        >
          <ContainerTrackingDetailsWidget
            containerDetails={containerDetails}
            containerMedia={containerMedia}
            supplierLocation={containerSupplierLocationDetails}
            supplierLocationLoaded={!iscontainerSupplierLocationLoading}
            ctActiveExceptions={ctActiveExceptions}
            activeOrganization={organization}
            watch={watch}
            eventHandler={eventHandler}
            toggleWatchContainer={() => {
              // Toggle our internal state, so the UI remains responsive
              // and then send the update.  If the state is not properly
              // changed on the backend, we will revert when we receive the
              // response from the API
              const newWatchState = !watch;
              setWatch(newWatchState);
              watchContainerActions(id, newWatchState, solutionId);
            }}
          />
          {containerDetails?.id ? (
            <CommentFeed
              commentShareableWithOrgId={null}
              fetchComments={(pageNumber, pageSize) => {
                return fetchContainerComments(
                  containerDetails?.containerId,
                  pageNumber,
                  pageSize,
                );
              }}
              isFetchingComments={isFetchingContainerComments}
              comments={containerComments}
              addComment={(data) => {
                return addContainerComments(
                  containerDetails?.containerId,
                  data,
                );
              }}
              cancelAddComment={cancelContainerAddComment}
              updateComment={(commentId, updatedData) => {
                return updateContainerComment(
                  containerDetails?.containerId,
                  commentId,
                  updatedData,
                );
              }}
              cancelUpdateComment={cancelContainerUpdateComment}
              markCommentsRead={(datetime) => {
                return markContainerCommentsRead(
                  containerDetails?.containerId,
                  datetime,
                );
              }}
              addBatchComments={(data, isCsvFormat) =>
                addContainerBatchComments(data, isCsvFormat, solutionId)
              }
              clearBatchComments={() => clearContainerBatchComments()}
              isBatchCommentInProgress={isContainerBatchCommentInProgress}
              isBatchCommentSuccessful={isContainerBatchCommentSuccessful}
              isBatchCommentFailed={isContainerBatchCommentFailed}
              batchCsvExample={batchCsvContainerExample}
              batchImportLabel={t("container-tracking:Container ID")}
              fetchNotification={fetchNotification}
              shipperOrgId={
                isShipper(organization) ? organization.organization_id : null
              }
            />
          ) : null}
          <Tabs>
            <Tabs.TabList>
              <Tabs.Tab
                style={{
                  width: "25%",
                }}
              >
                {t("container-tracking:Container History")}
              </Tabs.Tab>
              <Tabs.Tab
                style={{
                  width: "25%",
                }}
              >
                {t("container-tracking:Active Exceptions")}
              </Tabs.Tab>
              <Tabs.Tab
                style={{
                  width: "25%",
                }}
              >
                {t("container-tracking:Coordinates")}
              </Tabs.Tab>
            </Tabs.TabList>
            <Tabs.TabPanel
              style={{
                border: `1px solid ${Colors.tabs.BORDER}`,
              }}
            >
              {containerHistory && containerHistory.length > 0 ? (
                <ContainerTrackingHistoryTab
                  containerHistory={containerHistory}
                />
              ) : (
                <TabPanelPlaceholder
                  text={t("container-tracking:No Container History available")}
                />
              )}
            </Tabs.TabPanel>
            <Tabs.TabPanel
              style={{
                border: `1px solid ${Colors.tabs.BORDER}`,
              }}
            >
              {ctActiveExceptions && ctActiveExceptions.length > 0 ? (
                <ContainerTrackingExceptionsTab
                  ctActiveExceptions={ctActiveExceptions}
                />
              ) : (
                <TabPanelPlaceholder
                  text={t("container-tracking:No Active Exceptions")}
                />
              )}
            </Tabs.TabPanel>
            <Tabs.TabPanel
              style={{
                border: `1px solid ${Colors.tabs.BORDER}`,
              }}
            >
              {containerPositionUpdates.length > 0 ? (
                <CoordinatesTable
                  addCoordinate={addCoordinate}
                  selectedMapCoordinate={selectedMapCoordinate}
                  showCityColumn={false}
                  showStateColumn={false}
                  showCityStateColumn={true}
                  clearCoordinatesByType={() => {}}
                  coords={containerPositionUpdates}
                />
              ) : (
                <TabPanelPlaceholder
                  text={t("container-tracking:No Coordinates Available")}
                />
              )}
            </Tabs.TabPanel>
          </Tabs>
        </div>
      </Tabs.TabPanel>
      {
        // containerDetails?.status === "In Transit" ? (
        //   <Tabs.TabPanel key="shipment-details">
        //     <ShipmentDetailsCollapsible
        //       shipmentId={shipment?.creator_shipment_id}
        //       assetId={assetId}
        //       mode={shipment?.mode_name || ""}
        //       status={`${shipment?.active_status_ng} ${shipment?.current_status_ng}`}
        //       shipmentType={shipment?.shipment_type_ng}
        //       shipperName={shipment?.shipper_name}
        //       carrierName={shipment?.carrier_name}
        //       carrierScac={shipment?.carrier_scac}
        //       currentRoad={shipment?.current_road_name}
        //       trailerAndEquipmentNumbers={_.uniq(
        //         shipment?.trailer_equipment_number ?? []
        //       )}
        //       serviceCode={getReferenceValue(shipment, "service_code")}
        //       exception={shipment?.active_exceptions_ng}
        //       isBackOrder={shipment?.shipment_details?.is_back_order}
        //       railTrainId={shipment?.rail_train_id}
        //       loadedStatus={shipment?.loaded_status}
        //       extraHeaderContent={
        //         <div css={{ display: "flex", alignItems: "center" }}>
        //           {enableActionMenu ? (
        //             <ActionNav
        //               shipment={parentShipment}
        //               eventHandler={eventHandler}
        //               enableAlertMeAction={enableAlertMeAction}
        //               enableReviewStatusAction={false}
        //               enableShipmentEventsAction={false}
        //               enableCancelShipmentAction={false}
        //               enableReportDelayAction={false}
        //               enableClearDelayAction={false}
        //               enableMobileTrackingAction={false}
        //               enableBillOfLadingAction={false}
        //               assetId={parentShipmentAssetId}
        //               arrived={parentShipment?.active_status_ng === "Arrived"}
        //               shipmentSubscription={shipmentSubscription}
        //               isShipmentSubscriptionLoading={
        //                 isShipmentSubscriptionLoading
        //               }
        //             />
        //           ) : null}
        //         </div>
        //       }
        //     />
        //     {/* Arrival and Departure panel group */}
        //     <ArrivalAndDeparturePanelGroup
        //       origin={origin}
        //       destination={destination}
        //       eta={!_.isNil(frozenEta) ? frozenEta : calculatedEta}
        //       etaWarningMessage={
        //         !_.isNil(frozenEta) &&
        //         shipment.active_exceptions_ng === "Carrier Delayed"
        //           ? t(
        //               "shipment-details:Carrier has indicated a delay. This ETA is provided by the Carrier."
        //             )
        //           : null
        //       }
        //       isFvEta={isFvEta}
        //       showAvailableForUnload={shipment?.mode_name === "Rail"}
        //       availableForUnloadTs={shipment?.available_for_unload_ts}
        //       style={{ margin: "1em" }}
        //     >
        //       <ArrivalAndDeparturePanelGroup.SubHeaders>
        //         <ArrivalAndDeparturePanelGroup.RouteSubHeader
        //           routeId={shipment?.route_number}
        //           // ISS-9715: Hiding On Time for Cummins org
        //           onTimePercentage={
        //             organization?.fv_id !== "CUMMINS"
        //               ? shipment?.on_time_percentage
        //               : null
        //           }
        //         />
        //         <ArrivalAndDeparturePanelGroup.TripPlanIdSubHeader
        //           tripPlanId={shipment?.trip_plan_number}
        //         />
        //       </ArrivalAndDeparturePanelGroup.SubHeaders>
        //     </ArrivalAndDeparturePanelGroup>
        //     {/* Comments Panel group */}
        //     <CommentFeed
        //       commentShareableWithOrgId={
        //         isShipper(organization)
        //           ? shipment?.carrier_organization_id
        //           : isCarrier(organization)
        //           ? shipment?.created_by_org_id
        //           : null
        //       }
        //       fetchComments={(pageNumber, pageSize) => {
        //         return fetchComments(shipment?.id, pageNumber, pageSize);
        //       }}
        //       isFetchingComments={isFetchingComments}
        //       comments={comments}
        //       addComment={data => {
        //         return addComment(shipment?.id, data);
        //       }}
        //       cancelAddComment={cancelAddComment}
        //       updateComment={(commentId, updatedData) => {
        //         return updateComment(shipment?.id, commentId, updatedData);
        //       }}
        //       cancelUpdateComment={cancelUpdateComment}
        //       markCommentsRead={datetime => {
        //         return markCommentsRead(shipment?.id, datetime);
        //       }}
        //       addBatchComments={(data, isCsvFormat) =>
        //         addBatchComments(data, isCsvFormat)
        //       }
        //       clearBatchComments={() => clearBatchComments()}
        //       isBatchCommentInProgress={isBatchCommentInProgress}
        //       isBatchCommentSuccessful={isBatchCommentSuccessful}
        //       isBatchCommentFailed={isBatchCommentFailed}
        //       batchCsvExample={batchCsvShipmentExample}
        //       batchImportLabel={t("shipment-details:Shipment ID")}
        //       shipperOrgId={
        //         isShipper(organization) ? organization.organization_id : null
        //       }
        //       style={{ margin: "1em" }}
        //       fetchNotification={fetchNotification}
        //     />
        //     <ShipmentTimeline
        //       shipment={shipment}
        //       organization={organization}
        //       shipmentModes={shipmentModes}
        //       hidePercentage={false}
        //       eventHandler={eventHandler}
        //     />
        //     <div css={lastUpdateDisplayCss}>
        //       <LastUpdateDisplay shipment={shipment} />
        //     </div>
        //     <div css={shipmentDetailTabsCss}>
        //       <ShipmentDetailTabs
        //         shipment={shipment}
        //         addCoordinate={addCoordinate}
        //         clearCoordinatesByType={clearCoordinatesByType}
        //         selectedMapCoordinate={selectedMapCoordinate}
        //         organization={organization}
        //       />
        //     </div>
        //   </Tabs.TabPanel>
        // ) : null
      }

      {/* {enableAlertMeAction ? (
        <AlertMeModal
          show={showAlertMeModal}
          hide={() => {
            setShowAlertMeModal(false);
          }}
          subscribeeDetails={parentShipment}
          displayedId={parentShipment.creator_shipment_id}
          subscriptionDetails={shipmentSubscription}
          subscribe={subscribe}
          updateSubscription={updateSubscription}
          unsubscribe={unsubscribe}
          subscriptionRequestError={subscriptionRequestError}
          isSubscriptionUpdating={isSubscriptionUpdating}
          subscriptionUpdateSuccess={subscriptionUpdateSuccess}
          subscriptionUpdateError={subscriptionUpdateError}
        />
      ) : null}
      {enableReviewStatusAction && (
        <UnderReviewModal
          show={showUnderReviewModal}
          shipment={parentShipment}
          updateReviewStatus={updateReviewStatus}
          reviewStatus={parentShipment.active_exceptions_ng === "Under Review"}
          hide={() => setShowUnderReviewModal(false)}
        />
      )}
      {enableShipmentEventsAction && (
        <AddAssetModal
          show={showAddAssetModal}
          assignAsset={assignAsset}
          assignTrailer={assignTrailer}
          shipment={parentShipment}
          organization={organization}
          hide={() => setShowAddAssetModal(false)}
        />
      )}
      {enableShipmentEventsAction && (
        <ShipmentEventsModal
          show={showShipmentEventsModal}
          shipment={parentShipment}
          organization={organization}
          hide={() => {
            setShowShipmentEventsModal(false);
            //refresh shipment details page only on success report event
            if (successResponseStatusCode) {
              fetchShipmentDetails(parentShipment.id, null, false);
            }
          }}
          createShipmentEvents={(eventData, notes, onSuccess) =>
            createShipmentEvents(
              parentShipment,
              organization,
              eventData,
              notes,
              onSuccess
            )
          }
          isSubmitLoading={editShipmentData?.isLoading}
          errorMessages={
            editShipmentData?.isLoadingError
              ? editShipmentData?.loadingError
              : null
          }
          responseStatusCode={editShipmentData?.status ?? null}
          clearReportShipmentEvents={clearReportShipmentEvents}
        />
      )}
      {enableBillOfLadingAction ? (
        <BillOfLadingModal
          show={showBillOfLadingModal}
          hide={() => {
            setShowBillOfLadingModal(false);
          }}
          updateShipment={updateShipment}
          shipment={parentShipment}
          fetchShipmentDetails={fetchShipmentDetails}
          isSubmitLoading={editShipmentData?.isEditShipmentLoading}
          errorMessages={
            editShipmentData?.isEditShipmentLoadingError
              ? editShipmentData?.editShipmentLoadingError
              : null
          }
          responseStatusCode={
            editShipmentData?.editShipmentStatus
              ? editShipmentData.editShipmentStatus
              : null
          }
        />
      ) : null}
      {enableCancelShipmentAction && (
        <CancelShipmentModal
          show={showCancelShipmentModal}
          cancelShipment={cancelShipment}
          shipment={parentShipment}
          hide={() => setShowCancelShipmentModal(false)}
        />
      )}
      {enableMobileTrackingAction &&
        !getMobileTrackingEnabled(parentShipment) &&
        getIsShipmentWithinTrackingWindow(parentShipment) && (
          <MobileTrackingModal
            show={showEnableMobileTrackingModal}
            startMobileTracking={startMobileTracking}
            shipment={parentShipment}
            organization={organization}
            toEnable={true}
            hide={() => setShowEnableMobileTrackingModal(false)}
          />
        )}
      {enableMobileTrackingAction &&
        getMobileTrackingEnabled(parentShipment) &&
        getIsShipmentWithinTrackingWindow(parentShipment) && (
          <MobileTrackingModal
            show={showDisableMobileTrackingModal}
            stopMobileTracking={stopMobileTracking}
            shipment={parentShipment}
            organization={organization}
            toEnable={false}
            hide={() => setShowDisableMobileTrackingModal(false)}
          />
        )}
      {enableReportDelayAction ? (
        <ReportCarrierDelayModal
          show={showReportCarrierDelayModal}
          hide={() => {
            setShowReportCarrierDelayModal(false);
            clearReportDelayInState();
          }}
          isOrgTypeShipper={isShipper(organization)}
          selectedShipmentID={parentShipment?.creator_shipment_id}
          fetchDelayOptions={fetchDelayOptions}
          reportDelayOptionsList={delayOptionsData?.data}
          reportDelayOptionsIsLoading={delayOptionsData?.isLoading}
          reportDelayOptionsIsLoadingError={delayOptionsData?.isLoadingError}
          reportDelay={(
            reasonCode,
            frozenEta,
            comments,
            importShipmentIDs,
            onSuccess
          ) =>
            reportDelay(
              parentShipment,
              organization,
              reasonCode,
              frozenEta,
              comments,
              importShipmentIDs,
              onSuccess
            )
          }
          isSubmitLoading={reportDelayData?.isLoading}
          responseStatusCode={reportDelayData?.status}
          errorResponse={reportDelayData?.loadingError?.response?.data}
        />
      ) : null}
      {enableClearDelayAction ? (
        <ClearCarrierDelayModal
          show={showClearCarrierDelayModal}
          hide={() => {
            setShowClearCarrierDelayModal(false);
          }}
          clearDelay={onSuccess =>
            clearDelay(parentShipment, organization, onSuccess)
          }
          isSubmitLoading={clearDelayData?.isLoading}
          errorMessage={
            clearDelayData?.isLoadingError
              ? clearDelayData?.loadingError?.message
              : null
          }
        />
      ) : null} */}
      <LocationManagementModal
        updateBucketLocations={updateBucketLocations}
        isLocationDetailsLoading={isLocationDetailsLoading}
        locationDetails={locationDetails}
        fetchLocation={fetchLocation}
        fetchBucket={fetchBucket}
        bucketDetails={bucketDetails}
        fetchBucketDetailsFromName={fetchBucketDetailsFromName}
        isValidNewBucketName={isValidNewBucketName}
        selectedBucketLocations={selectedBucketLocations}
        isBucketListLoading={isBucketListLoading}
        isBucketDetailsLoading={isBucketDetailsLoading}
        isValidatingBucketName={isValidatingBucketName}
        isFetchingBucketLocations={isFetchingBucketLocations}
        updateLocationsData={updateLocationsData}
        locationChangesSavedStatus={locationChangesSavedStatus}
        failedLocations={failedLocations}
        resetFailedLocationsLists={resetFailedLocationsLists}
        resetSavedChanges={resetSavedChanges}
        id={id}
        show={showLocationManagementModal}
        resetBucketLoctions={resetBucketLoctions}
        isErrorOnSave={isErrorOnSave}
        resetError={resetError}
        resetUpdatedLocations={resetUpdatedLocations}
        updateLocations={updatedLocations}
        updateLocationsOnFail={updateLocationsOnFail}
        fetchCountries={fetchCountries}
        fetchStates={fetchStates}
        countryList={countryList}
        stateList={stateList}
        validationError={validationError}
        resetValidationError={resetValidationError}
        hide={() => {
          setShowLocationManagementModal(false);
        }}
      />
    </Tabs>
  );
  return (
    <RoutingMapContextProvider>
      <DetailWithMap
        mapChildren={
          !isContainerDetailsLoading &&
          containerDetails?.status === "In Transit" ? (
            <GeofenceBuilderMapContainer />
          ) : (
            // getShipmentMapChildren(
            //     shipment,
            //     ladsList,
            //     routeShipments,
            //     showHeatmap,
            //     heatmapCoords,
            //     isMultiLeg
            //   )
            getMapChildren(iscontainerLocationDetailsLoading)
          )
        }
        detailsChildren={
          // <Loader loaded={!areBothDetailsLoading}>{detailsChildren}</Loader>
          <Loader loaded={!isContainerDetailsLoading}>{detailsChildren}</Loader>
        }
      ></DetailWithMap>
    </RoutingMapContextProvider>
  );
};

ContainerTrackingDetails.propTypes = {
  id: PropTypes.string,
  organization: PropTypes.object,
  shipmentModes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ),
  isShipmentSubscriptionLoading: PropTypes.bool,
  subscribe: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  shipmentSubscription: PropTypes.shape({
    email: PropTypes.string,
    enable_email: PropTypes.bool,
    enable_platform: PropTypes.bool,
    enable_sms: PropTypes.bool,
    id: PropTypes.any,
    mobile_number: PropTypes.any,
    owner_id: PropTypes.any,
    reference_id: PropTypes.string,
    source_service: PropTypes.string,
    timezone: PropTypes.string,
    type: PropTypes.string,
  }),
  subscriptionRequestError: PropTypes.bool,
  isSubscriptionUpdating: PropTypes.bool,
  subscriptionUpdateSuccess: PropTypes.bool,
  subscriptionUpdateError: PropTypes.bool,
  editShipmentData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.string,
    status: PropTypes.number,
    statusArray: PropTypes.array,
  }),
  fetchShipmentSubscription: PropTypes.func.isRequired,
  setWatchShipment: PropTypes.func.isRequired,
  updateReviewStatus: PropTypes.func.isRequired,
  assignAsset: PropTypes.func.isRequired,
  assignTrailer: PropTypes.func.isRequired,
  shipment: PropTypes.object,
  containerDetails: PropTypes.object,
  isContainerDetailsLoading: PropTypes.bool,
  isShipmentDetailsLoading: PropTypes.bool,
  areBothDetailsLoading: PropTypes.bool,
  containerMedia: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  containerPositionUpdates: PropTypes.array,
  fetchContainerDetails: PropTypes.func,
  fetchShipmentDetails: PropTypes.func,
  fetchContainerActiveExceptions: PropTypes.func,
  fetchContainerMedia: PropTypes.func,
  clearContainerMedia: PropTypes.func,
  addCoordinate: PropTypes.func,
  clearCoordinatesByType: PropTypes.func,
  selectedMapCoordinate: PropTypes.object,
  fetchContainerHistory: PropTypes.func,
  containerHistory: PropTypes.array,
  createShipmentEvents: PropTypes.func.isRequired,
  clearReportShipmentEvents: PropTypes.func,
  updateShipment: PropTypes.func,
  cancelShipment: PropTypes.func.isRequired,
  startMobileTracking: PropTypes.func.isRequired,
  stopMobileTracking: PropTypes.func.isRequired,
  reportDelayData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.object,
    status: PropTypes.number,
  }),
  clearDelay: PropTypes.func.isRequired,
  clearReportDelayInState: PropTypes.func.isRequired,
  clearDelayData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.object,
  }),
  reportDelay: PropTypes.func.isRequired,
  fetchDelayOptions: PropTypes.func,
  delayOptionsData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.object,
  }),
  setShipmentWithUnresolvedLocation: PropTypes.func.isRequired,
  pushLocationMatchingView: PropTypes.func.isRequired,
  ladsList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  childShipments: PropTypes.object.isRequired,
  routeHeatmap: PropTypes.array,
  // Error redirects
  redirectTo404: PropTypes.func.isRequired,
  detailsError: PropTypes.object,
};
