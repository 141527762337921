/** @jsxImportSource @emotion/react */
import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { MdAdd } from "react-icons/md";

import { useTranslation } from "react-i18next";

import { Text } from "components/atoms/Text.atom";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { OrganizationType } from "shared/constants/organization.const";
import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { useOrganizationSearchColumns } from "./OrganizationSearch.columns";

import SearchBarContainer from "./OrganizationsSearchBarContainer";
import FilterSectionContainer from "./OrganizationsFilterSectionContainer";
import AddOrganizationModal from "./components/AddOrganizationModal";
import EditOrganizationModal from "./components/EditOrganizationModal";
import DeleteOrganizationModal from "./components/DeleteOrganizationModal";
import { useOrganizationsTranslation } from "shared/utils/organizations.utils";
import { Section } from "styles/container-elements";
import Colors from "styles/colors";

// Definitions
export const ModalMode = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

const useOrganizationTypeOptions = () => {
  const { getTranslatedOrganizationType } = useOrganizationsTranslation();

  return useMemo(() => {
    return [
      OrganizationType.CARRIER,
      OrganizationType.DEALER,
      OrganizationType.PARTNER,
      OrganizationType.SHIPPER,
      OrganizationType.SUPPLIER,
      OrganizationType.THIRDPARTY,
    ].map((type) => {
      return {
        label: getTranslatedOrganizationType(type),
        value: type,
      };
    });
  }, [getTranslatedOrganizationType]);
};

const OrganizationsView = ({
  searchResults = [],
  isLoading,
  searchFilters,
  isShowingFilters,
  toggleShowFilters,
  sortColumn,
  reverseSort,
  defaultSortColumn,
  defaultReverseSort,
  setSort,
  page,
  pageSize,
  totalPages,
  totalCount,
  setPagination,
  searchOrganizations,
  fetchOrganizationTypes,
  hasSearchCriteriaChanged,
}) => {
  const { t } = useTranslation("organizations");

  useSetTitleOnMount(t("organizations:Organization Management"));
  useSetDescriptionOnMount(
    t(
      "organizations:Add, delete, or update an organization within the platform",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: Organization Management");

  const [showModal, setShowModal] = useState({
    modalMode: null,
    organization: null,
    organizationName: "",
  });

  useEffect(
    () => {
      searchOrganizations();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    fetchOrganizationTypes();
  }, [fetchOrganizationTypes]);

  const organizationTypesOptions = useOrganizationTypeOptions();

  // Keeping the selected option in a ref so we can update it only after a search has been made.
  // This selected value updates the total count label and Name header.
  // See render below and useOrganizationSearchColumns.
  const selectedOrgTypeOptionRef = useRef(null);
  // If hasSearchCriteriaChanged is false, the user either just visited this page or made a search.
  // After either of these actions, we want to update the selected option for display purposes.
  if (!hasSearchCriteriaChanged) {
    selectedOrgTypeOptionRef.current =
      organizationTypesOptions.find((option) => {
        return option.value === searchFilters?.orgType;
      }) ?? null;
  }

  const rowActionHandler = (action, val, name = "") => {
    setShowModal({
      modalMode: ModalMode[action],
      organization: val,
      organizationName: name,
    });
  };

  const columns = useOrganizationSearchColumns(
    selectedOrgTypeOptionRef.current,
    rowActionHandler,
  );

  // Wrapping in useCallback because the modal components may use this in a dependency array.
  const closeModal = useCallback(() => {
    setShowModal({ modalMode: null, organization: null, organizationName: "" });
  }, [setShowModal]);

  const onPageChange = useCallback(
    (newPage) => {
      setPagination(null, newPage, pageSize);
    },
    [setPagination, pageSize],
  );

  const onPageSizeChange = useCallback(
    (newPageSize) => {
      setPagination(null, page, newPageSize);
    },
    [setPagination, page],
  );

  const onPageSort = useCallback(
    (column, isReverse) => {
      setSort(null, column, isReverse);
    },
    [setSort],
  );

  return (
    <Section
      style={{
        paddingLeft: "1em",
        paddingRight: "1em",
        justifyContent: "initial",
      }}
    >
      <div className="d-flex flex-column mt-3">
        <SearchBarContainer
          showSearchOptions={false}
          isShowingFilters={isShowingFilters}
          toggleShowFilters={() => toggleShowFilters(!isShowingFilters)}
        />
        <FilterSectionContainer
          show={isShowingFilters}
          organizationTypeOptions={organizationTypesOptions}
          backgroundColor={Colors.background.GRAY}
        />
        <div className="d-flex flex-column-reverse flex-sm-row mt-1">
          <div className="me-0 me-sm-auto align-self-center my-1 my-sm-0">
            <Text bold>
              {selectedOrgTypeOptionRef.current?.label
                ? t(
                    "organizations:[[[totalCount]]] [[[organizationType]]] organizations",
                    {
                      totalCount: totalCount,
                      organizationType: selectedOrgTypeOptionRef.current?.label,
                    },
                  )
                : t("organizations:[[[totalCount]]] organizations", {
                    totalCount: totalCount,
                  })}
            </Text>
          </div>
          {/* Add Organization button */}
          <Button
            variant="success"
            style={{ height: "3em" }}
            className="mb-2 ms-sm-2"
            onClick={() => rowActionHandler("CREATE", null, "")}
          >
            <MdAdd /> {t("organizations:Add New Organization")}
          </Button>
        </div>
      </div>

      <BaseTable
        columns={columns}
        data={searchResults}
        totalCount={totalCount}
        isLoading={isLoading}
        showPagination={true}
        fixPaginationToBottom={true}
        isManualPagination={true}
        pageIndex={page}
        pageSize={pageSize}
        pageCount={totalPages}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        isManualSorting={true}
        sortColumn={sortColumn}
        reverseSort={reverseSort}
        defaultSortColumn={defaultSortColumn}
        defaultReverseSort={defaultReverseSort}
        onPageSort={onPageSort}
      />

      <AddOrganizationModal
        show={showModal.modalMode === ModalMode.CREATE}
        hide={closeModal}
        organizationName={showModal.organizationName}
      />
      <EditOrganizationModal
        show={showModal.modalMode === ModalMode.UPDATE}
        hide={closeModal}
        organizationId={showModal.organization?.organization_id}
      />
      <DeleteOrganizationModal
        show={showModal.modalMode === ModalMode.DELETE}
        hide={closeModal}
        organization={showModal.organization}
      />
    </Section>
  );
};

OrganizationsView.propTypes = {
  searchResults: PropTypes.array,
  isLoading: PropTypes.bool,
  searchFilters: PropTypes.object,
  isShowingFilters: PropTypes.bool,
  toggleShowFilters: PropTypes.func.isRequired,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  defaultSortColumn: PropTypes.string,
  defaultReverseSort: PropTypes.bool,
  setSort: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setPagination: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  searchOrganizations: PropTypes.func.isRequired,
  fetchOrganizationTypes: PropTypes.func.isRequired,
  hasSearchCriteriaChanged: PropTypes.bool,
};

export default OrganizationsView;
