/** @jsxImportSource @emotion/react */
/**
 * In a SavedSearch there is a modal when you decide to create/edit a new
 * saved search where you will check if it is exactly the search you want to
 * save and also you may edit some data/change some fields there. After doing
 * this you are able to save the search inside the own modal.
 *
 */
import { Fragment, useEffect, useState } from "react";
import { Modal, FormControl } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import "react-widgets/dist/css/react-widgets.css";
import Loader from "react-loader";
import PropTypes from "prop-types";
import _ from "lodash";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

import { Text } from "components/atoms/Text.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Button } from "components/atoms/Button.atom";
import { Tabs, TabsClassNames } from "components/molecules/Tabs.molecule";
import { AlertMeForm } from "shared/components/organisms/AlertMeForm.organism";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import Colors from "styles/colors";
import { MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH } from "shared/redux/SubscriptionStateBuilder";

const initialShowSavedSearchUpdateModalState = {
  showAlert: false,
  showAlertInDeleteConfirmationModal: false,
  showSaveConfirmationModal: false,
  showDeleteConfirmationModal: false,
};

const initialShowSpinnerState = {
  subscribeOnly: false,
  subscribeOnlyError: false,
  reset: false,
  resetError: false,
  deleteSavedSearch: false,
  deleteSavedSearchError: false,
  unsubscribeAndDelete: false,
  unsubscribeAndDeleteError: false,
  saveAndUnsubscribe: false,
  saveAndUnsubscribeError: false,
};

const EditSavedSearchModalContent = ({
  t,
  hide,
  isExistingSavedSearch,
  showTabs,
  name,
  setName,
  showFilters,
  setShowFilters,
  SearchBarContainer,
  FilterSectionContainer,
  savedSearch,
  saveSearch,
  editSearch,
  deleteSearch,
  searchFilters,
  searchCategory,
  searchText,
  fetchSavedSearchCardData = () => {},
  editSearchData,
  showSubscriptionTab = false,
  isCurrentSavedSearchSubscribed = false,
  subscriptionDetails,
  subscribe,
  refreshSubscription,
  unsubscribe,
}) => {
  const [showSavedSearchUpdateModal, setShowSavedSearchUpdateModal] = useState(
    initialShowSavedSearchUpdateModalState,
  );
  const [showSpinner, setShowSpinner] = useState(initialShowSpinnerState);
  const isUpdating =
    showSpinner.subscribeOnly ||
    showSpinner.reset ||
    showSpinner.saveAndUnsubscribe;
  // This is because after update saved search api
  // shipping-ng/shipments/totals api will be triggered to get the latest details on the background
  // This will show the spinner again on the modal while subscription api's are in progress.
  const showModalContent =
    isUpdating ||
    showSpinner.subscribeOnlyError ||
    showSpinner.resetError ||
    showSpinner.saveAndUnsubscribeError;

  const isAboveLimit =
    editSearchData?.data?.total_shipments >
      MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH ?? false;
  const isUpdateSavedSearchError =
    showSpinner.subscribeOnlyError ||
    showSpinner.resetError ||
    showSpinner.saveAndUnsubscribeError;
  // Show unsubscribe and delete button only when saved search is subscribed else unsubsribe api will throw error
  // after unsubsribe isCurrentSavedSearchSubscribed value changes and modal content is getting updated
  // to avoid that showing that content till all api's complete in the then chaining.
  const showDeleteModalContent =
    isCurrentSavedSearchSubscribed || showSpinner.unsubscribeAndDelete;

  // On Close of Edit Saved Search Modal
  const onHide = () => {
    hide();
    setShowSavedSearchUpdateModal(initialShowSavedSearchUpdateModalState);
    setShowSpinner(initialShowSpinnerState);
  };

  // On Close of Edit Saved Search Update Confirmation Modal
  const onSavedSearchUpdateModalHide = () => {
    if (!editSearchData?.isLoadingError) {
      // fetch the card data present before editing the filters - on cancel/close of the modal before updating.
      fetchSavedSearchCardData(savedSearch);
    }

    setShowSavedSearchUpdateModal(initialShowSavedSearchUpdateModalState);
  };

  // get updated filters and search category values for fetching saved search card data
  const getUpdatedSavedSearch = () => {
    const updatedSavedSearch = { ...searchFilters };
    const searchCategoryKey = searchCategory?.queryKey ?? null;
    if (searchCategoryKey) {
      updatedSavedSearch[searchCategoryKey] = searchText;
    }
    return updatedSavedSearch;
  };

  // On Click of Save - in Edit Saved Search Modal or Edit Saved Search Update Confirmation Modal
  const onSave = () => {
    if (_.isEmpty(savedSearch)) {
      saveSearch(name, getUpdatedSavedSearch());
    } else {
      editSearch(savedSearch.id, name, getUpdatedSavedSearch());
    }

    onHide();
  };

  // On Click of Save - in Edit Saved Search Modal or Edit Saved Search Update Confirmation Modal
  const onSubscribeOnly = () => {
    setShowSpinner({ ...initialShowSpinnerState, subscribeOnly: true });
    setShowSavedSearchUpdateModal({
      ...showSavedSearchUpdateModal,
      showAlert: true,
    });

    const updatedSavedSearch = getUpdatedSavedSearch();
    editSearch(savedSearch.id, name, updatedSavedSearch)
      .then(() =>
        refreshSubscription(
          { id: savedSearch.id, name, search: updatedSavedSearch },
          subscriptionDetails,
        ),
      )
      .then(() => onHide())
      .catch(() =>
        setShowSpinner({
          ...initialShowSpinnerState,
          subscribeOnlyError: true,
        }),
      );
  };

  // On Click of Reset button - in Edit Saved Search Update Confirmation Modal
  const onReset = () => {
    setShowSpinner({ ...initialShowSpinnerState, reset: true });
    setShowSavedSearchUpdateModal({
      ...showSavedSearchUpdateModal,
      showAlert: true,
    });

    const updatedSavedSearch = getUpdatedSavedSearch();
    editSearch(savedSearch.id, name, updatedSavedSearch)
      .then(() => unsubscribe(savedSearch))
      .then(() =>
        subscribe(
          {
            id: savedSearch.id,
            name,
            search: updatedSavedSearch,
          },
          subscriptionDetails,
        ),
      )
      .then(() => onHide())
      .catch(() =>
        setShowSpinner({
          ...initialShowSpinnerState,
          resetError: true,
        }),
      );
  };

  const onSaveAndUnsubscribe = () => {
    setShowSpinner({ ...initialShowSpinnerState, saveAndUnsubscribe: true });
    setShowSavedSearchUpdateModal({
      ...showSavedSearchUpdateModal,
      showAlert: true,
    });

    editSearch(savedSearch.id, name, getUpdatedSavedSearch())
      .then(() => unsubscribe(savedSearch))
      .then(() => onHide())
      .catch(() =>
        setShowSpinner({
          ...initialShowSpinnerState,
          saveAndUnsubscribeError: true,
        }),
      );
  };

  // On Click of Save - in Edit Saved Search Modal
  const onSavedSearchUpdate = () => {
    if (showSubscriptionTab && isCurrentSavedSearchSubscribed) {
      setShowSavedSearchUpdateModal({
        ...showSavedSearchUpdateModal,
        showSaveConfirmationModal: true,
      });

      // send the recent filters and category selection in search object
      fetchSavedSearchCardData({
        ...savedSearch,
        search: getUpdatedSavedSearch(),
      });
    } else {
      onSave();
    }
  };

  // On Click of Delete - in Edit Saved Search Modal
  const onSavedSearchDelete = () => {
    if (showSubscriptionTab) {
      setShowSavedSearchUpdateModal({
        ...showSavedSearchUpdateModal,
        showDeleteConfirmationModal: true,
      });
    } else {
      deleteSearch(savedSearch.id);
      onHide();
    }
  };

  return (
    <Fragment>
      <div css={{ padding: "1rem 1rem 0 1rem" }}>
        <div css={{ paddingBottom: "0.5em" }}> {t("components:Name")} </div>
        <FormControl
          css={{
            backgroundColor: "white",
            height: "3em",
            boxShadow: "none",
          }}
          type="text"
          value={name}
          isInvalid={!name}
          placeholder={t("components:My Saved Search")}
          onChange={(event) => setName(event.target.value)}
          data-qa="input-name-modal-saved-search"
        />

        <div
          css={{
            height: "1em",
            borderBottom: "1px solid #aaa",
          }}
        />
      </div>
      <div css={{ padding: "1rem" }}>
        <div css={{ paddingBottom: "0.5em" }}> {t("components:Search")} </div>
        <SearchBarContainer
          isShowingFilters={showFilters}
          toggleShowFilters={() => setShowFilters(!showFilters)}
          preventSearchOnChange
        />
        <FilterSectionContainer
          show={showFilters}
          hideSearchButton
          sectionStyle={{
            backgroundColor: "#E2E2E2",
            paddingRight: "6.3em",
          }}
        />
      </div>

      <div
        css={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "1rem",
          backgroundColor: showTabs
            ? Colors.background.LIGHT_GRAY
            : Colors.comments.footerBackground,
        }}
      >
        {deleteSearch && isExistingSavedSearch ? (
          <Button
            variant="danger"
            css={{
              position: "absolute",
              left: "1rem",
            }}
            onClick={() => {
              onSavedSearchDelete();
            }}
            data-qa="button-delete-modal-saved-search"
          >
            {t("components:Delete")}
          </Button>
        ) : null}
        <span>
          <Button
            variant="outline-secondary"
            css={{
              marginRight: "1em",
            }}
            onClick={() => {
              onHide();
            }}
            data-qa="button-cancel-modal-saved-search"
          >
            {t("components:Cancel")}
          </Button>
          <Button
            disabled={!name}
            variant="primary"
            onClick={() => {
              onSavedSearchUpdate();
            }}
            data-qa="button-save-modal-saved-search"
          >
            {t("components:Save")}
          </Button>
        </span>
      </div>

      <DialogModal
        show={showSavedSearchUpdateModal.showSaveConfirmationModal}
        onHide={onSavedSearchUpdateModalHide}
        title={t("components:Saved Search Update")}
        closeOnBlur={false}
        size="md"
        footerContent={
          <Fragment>
            <Button
              variant="light"
              css={{ marginRight: "0.5em" }}
              onClick={() => onSavedSearchUpdateModalHide()}
              size="sm"
            >
              {t("components:Cancel")}
            </Button>
            {!isAboveLimit ? (
              <Fragment>
                <Button
                  variant="primary"
                  onClick={() => onSubscribeOnly()}
                  disabled={
                    editSearchData?.isLoading ||
                    editSearchData?.isLoadingError ||
                    isUpdating
                  }
                  size="sm"
                >
                  {showSpinner.subscribeOnly ? (
                    <Icon src={faSpinner} spin />
                  ) : (
                    t("components:Subscribe Only")
                  )}
                </Button>

                <Button
                  variant="primary"
                  onClick={() => onReset()}
                  disabled={
                    editSearchData?.isLoading ||
                    editSearchData?.isLoadingError ||
                    isUpdating
                  }
                  size="sm"
                >
                  {showSpinner.reset ? (
                    <Icon src={faSpinner} spin />
                  ) : (
                    t("components:Reset")
                  )}
                </Button>
              </Fragment>
            ) : (
              <Button
                variant="primary"
                onClick={() => onSaveAndUnsubscribe()}
                disabled={
                  editSearchData?.isLoading ||
                  editSearchData?.isLoadingError ||
                  isUpdating
                }
                size="sm"
              >
                {showSpinner.saveAndUnsubscribe ? (
                  <Icon src={faSpinner} spin />
                ) : (
                  t("components:Save")
                )}
              </Button>
            )}
          </Fragment>
        }
      >
        <Loader
          loaded={!editSearchData?.isLoading || showModalContent}
          scale={0.5}
        >
          {!editSearchData?.isLoadingError
            ? isAboveLimit
              ? t(
                  "components:Your updated saved search contains [[[count]]] shipments, which is more than the maximum allowed of [[[maxCount]]] shipments for automatic subscriptions. Do you want to save this without an alert subscription? This will unsubscribe from the previous saved search and delete the alert subscription.",
                  {
                    count: editSearchData?.data?.total_shipments ?? null,
                    maxCount: MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH,
                  },
                )
              : t(
                  "components:Your updated save search contains [[[count]]] shipments. Are you sure you want to save this change? Click the Reset button to unsubscribe to all existing shipments for your old saved search and subscribe to your updated saved search. Click the Subscribe only button to subscribe to the updated saved search.",
                  {
                    count: editSearchData?.data?.total_shipments ?? null,
                  },
                )
            : null}
        </Loader>
        {editSearchData?.isLoadingError || isUpdateSavedSearchError ? (
          <Alert
            show={
              showSavedSearchUpdateModal.showAlert && isUpdateSavedSearchError
            }
            variant={AlertVariant.Danger}
            css={{ margin: "1rem 0 0.5rem", paddingRight: "2.5rem" }}
          >
            <Text>
              {editSearchData?.isLoadingError
                ? t(
                    "components:There was an error when fetching your Search Count. Please try again later.",
                  )
                : t(
                    "components:There was an error while updating saved search and the subscriptions. Please try again later.",
                  )}
            </Text>
          </Alert>
        ) : null}
      </DialogModal>
      <DialogModal
        show={showSavedSearchUpdateModal.showDeleteConfirmationModal}
        onHide={() =>
          setShowSavedSearchUpdateModal({
            ...showSavedSearchUpdateModal,
            showDeleteConfirmationModal: false,
          })
        }
        title={t("components:Confirm Delete Saved Search")}
        closeOnBlur={false}
        size="md"
        footerContent={
          <Fragment>
            <Button
              variant="light"
              css={{ marginRight: "0.5em" }}
              onClick={() =>
                setShowSavedSearchUpdateModal({
                  ...showSavedSearchUpdateModal,
                  showDeleteConfirmationModal: false,
                })
              }
              size="sm"
            >
              {t("components:Cancel")}
            </Button>

            <Button
              variant="danger"
              onClick={() => {
                setShowSpinner({
                  ...initialShowSpinnerState,
                  deleteSavedSearch: true,
                });
                setShowSavedSearchUpdateModal({
                  ...showSavedSearchUpdateModal,
                  showAlertInDeleteConfirmationModal: true,
                });

                deleteSearch(savedSearch.id)
                  .then(() => onHide())
                  .then(() =>
                    setShowSpinner({
                      ...initialShowSpinnerState,
                      deleteSavedSearchError: true,
                    }),
                  );
              }}
              disabled={
                showSpinner.deleteSavedSearch ||
                showSpinner.unsubscribeAndDelete
              }
              size="sm"
            >
              {showSpinner.deleteSavedSearch ? (
                <Icon src={faSpinner} spin />
              ) : (
                t("components:Delete")
              )}
            </Button>

            {showDeleteModalContent ? (
              <Button
                variant="danger"
                onClick={() => {
                  setShowSpinner({
                    ...initialShowSpinnerState,
                    unsubscribeAndDelete: true,
                  });
                  setShowSavedSearchUpdateModal({
                    ...showSavedSearchUpdateModal,
                    showAlertInDeleteConfirmationModal: true,
                  });

                  unsubscribe(savedSearch).then(() =>
                    deleteSearch(savedSearch.id)
                      .then(() => onHide())
                      .then(() =>
                        setShowSpinner({
                          ...initialShowSpinnerState,
                          unsubscribeAndDeleteError: true,
                        }),
                      ),
                  );
                }}
                disabled={
                  showSpinner.deleteSavedSearch ||
                  showSpinner.unsubscribeAndDelete
                }
                size="sm"
              >
                {showSpinner.unsubscribeAndDelete ? (
                  <Icon src={faSpinner} spin />
                ) : (
                  t("components:Unsubscribe and Delete")
                )}
              </Button>
            ) : null}
          </Fragment>
        }
      >
        <Trans t={t}>
          Are you sure you want to delete? Click the Delete button to delete
          your saved search named <b>{name}</b>. If you have a saved search
          alert subscription, it will also be deleted.
        </Trans>
        {showDeleteModalContent ? (
          <Fragment>
            {" "}
            <Trans t={t} count={editSearchData?.data?.total_shipments}>
              Click the Unsubscribe and Delete button to unsubscribe from{" "}
              [[[count]]] shipments that match this saved search and delete your
              saved search named <b>{savedSearch?.name}</b>.
            </Trans>
          </Fragment>
        ) : null}

        {showSpinner.deleteSavedSearchError ||
        showSpinner.unsubscribeAndDeleteError ? (
          <Alert
            show={
              showSavedSearchUpdateModal.showAlertInDeleteConfirmationModal &&
              (showSpinner.deleteSavedSearchError ||
                showSpinner.unsubscribeAndDeleteError)
            }
            variant={AlertVariant.Danger}
            css={{ margin: "1rem 0 0.5rem", paddingRight: "2.5rem" }}
          >
            <Text>
              {showSpinner.deleteSavedSearchError
                ? t(
                    "components:There was an error while deleting the saved search.",
                  )
                : t(
                    "components:There was an error while unsubscribing saved search subscriptions and deleting the saved search. Please try again later.",
                  )}
            </Text>
          </Alert>
        ) : null}
      </DialogModal>
    </Fragment>
  );
};

EditSavedSearchModalContent.propTypes = {
  t: PropTypes.func,
  hide: PropTypes.func,
  isExistingSavedSearch: PropTypes.bool,
  showTabs: PropTypes.bool,
  name: PropTypes.string,
  setName: PropTypes.func,
  showFilters: PropTypes.bool,
  setShowFilters: PropTypes.func,
  SearchBarContainer: PropTypes.elementType.isRequired,
  FilterSectionContainer: PropTypes.elementType.isRequired,
  savedSearch: PropTypes.object,
  saveSearch: PropTypes.func.isRequired,
  editSearch: PropTypes.func.isRequired,
  deleteSearch: PropTypes.func,
  searchFilters: PropTypes.object,
  searchCategory: PropTypes.object,
  searchText: PropTypes.string,
  fetchSavedSearchCardData: PropTypes.func,
  editSearchData: PropTypes.object,
  showSubscriptionTab: PropTypes.bool,
  isCurrentSavedSearchSubscribed: PropTypes.bool,
  subscriptionDetails: PropTypes.object,
  subscribe: PropTypes.func,
  refreshSubscription: PropTypes.func,
  unsubscribe: PropTypes.func,
};

export const EditSavedSearchModal = ({
  show,
  hide,
  savedSearch,
  saveSearch,
  editSearch,
  deleteSearch,
  searchFilters,
  searchCategory,
  searchText,
  SearchBarContainer,
  FilterSectionContainer,
  fetchSavedSearchCardData,

  //Alert Me
  showSubscriptionTab = false,
  tabIndex = 0,
  setTabIndex,
  editSearchData,
  isCurrentSavedSearchSubscribed,
  isSavedSearchSubscriptionLoading,
  savedSearchSubscription,
  subscriptionRequestError,
  fetchSavedSearchSubscription,
  subscribe,
  updateSubscription,
  refreshSubscription,
  unsubscribe,
  deleteSubscription,
  isSubscriptionUpdating,
  subscriptionUpdateSuccess,
  subscriptionUpdateInProgress,
  subscriptionUpdateError,
}) => {
  const { t } = useTranslation("components");
  const [name, setName] = useState("");
  const [showFilters, setShowFilters] = useState(true);

  const isExistingSavedSearch = savedSearch?.id ? true : false;
  const showTabs = isExistingSavedSearch && showSubscriptionTab;

  // When modal is shown the name of search will be set by either the
  // savedSearch data OR default to empty string if it is a new search
  useEffect(() => {
    const name = isExistingSavedSearch ? savedSearch.name : "";
    setName(name);
  }, [savedSearch, isExistingSavedSearch, show]);

  return (
    <Modal
      show={show}
      onHide={() => hide()}
      size="lg"
      css={{
        ".modal-content": {
          background: Colors.background.LIGHT_GRAY,
        },
      }}
    >
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
        title={
          showTabs
            ? `${t("components:My Saved Search Configuration")}: ${name}`
            : isExistingSavedSearch
            ? t("components:Edit Saved Search")
            : t("components:Add Saved Search")
        }
      />

      <Modal.Body css={{ padding: 0 }}>
        {showTabs ? (
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => {
              setTabIndex(index);
            }}
            css={{
              // Remove side and bottom borders because the modal has it's own
              [TabsClassNames.TabPanelSelected]: {
                borderInline: "none",
                borderBlockEnd: "none",
              },
            }}
          >
            <Tabs.TabList style={{ paddingInline: "1rem" }}>
              <Tabs.Tab>{t("components:Saved Search Configuration")}</Tabs.Tab>
              {showSubscriptionTab ? (
                <Tabs.Tab>
                  {t("components:Alert Configuration")}
                  {isSavedSearchSubscriptionLoading ? (
                    <Icon src={faSpinner} spin style={{ marginLeft: ".5em" }} />
                  ) : null}
                </Tabs.Tab>
              ) : null}
            </Tabs.TabList>

            <Tabs.TabPanel>
              <EditSavedSearchModalContent
                t={t}
                hide={hide}
                isExistingSavedSearch={isExistingSavedSearch}
                showTabs={showTabs}
                name={name}
                setName={setName}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                SearchBarContainer={SearchBarContainer}
                FilterSectionContainer={FilterSectionContainer}
                savedSearch={savedSearch}
                saveSearch={saveSearch}
                editSearch={editSearch}
                deleteSearch={deleteSearch}
                searchFilters={searchFilters}
                searchCategory={searchCategory}
                searchText={searchText}
                fetchSavedSearchCardData={fetchSavedSearchCardData}
                editSearchData={editSearchData}
                showSubscriptionTab={showSubscriptionTab}
                isCurrentSavedSearchSubscribed={isCurrentSavedSearchSubscribed}
                subscriptionDetails={
                  savedSearchSubscription ?? {
                    enable_email: false,
                    enable_sms: false,
                    enable_platform: false,
                  }
                }
                subscribe={subscribe}
                refreshSubscription={refreshSubscription}
                unsubscribe={unsubscribe}
              />
            </Tabs.TabPanel>
            {showSubscriptionTab ? (
              <Tabs.TabPanel>
                <AlertMeForm
                  name={name}
                  totalCount={editSearchData?.data?.total_shipments}
                  subscribeeDetails={savedSearch}
                  fetchSubscription={fetchSavedSearchSubscription}
                  isSubscriptionLoading={isSavedSearchSubscriptionLoading}
                  subscriptionDetails={savedSearchSubscription}
                  subscriptionRequestError={subscriptionRequestError}
                  subscribe={subscribe}
                  updateSubscription={updateSubscription}
                  unsubscribe={unsubscribe}
                  deleteSubscription={deleteSubscription}
                  isSubscriptionUpdating={isSubscriptionUpdating}
                  subscriptionUpdateSuccess={subscriptionUpdateSuccess}
                  subscriptionUpdateInProgress={subscriptionUpdateInProgress}
                  subscriptionUpdateError={subscriptionUpdateError}
                  onCancel={hide}
                />
              </Tabs.TabPanel>
            ) : null}
          </Tabs>
        ) : (
          <EditSavedSearchModalContent
            t={t}
            hide={hide}
            isExistingSavedSearch={isExistingSavedSearch}
            showTabs={showTabs}
            name={name}
            setName={setName}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            SearchBarContainer={SearchBarContainer}
            FilterSectionContainer={FilterSectionContainer}
            savedSearch={savedSearch}
            saveSearch={saveSearch}
            editSearch={editSearch}
            deleteSearch={deleteSearch}
            searchFilters={searchFilters}
            searchCategory={searchCategory}
            searchText={searchText}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

EditSavedSearchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  savedSearch: PropTypes.object,
  saveSearch: PropTypes.func.isRequired,
  editSearch: PropTypes.func.isRequired,
  deleteSearch: PropTypes.func,
  searchFilters: PropTypes.object,
  searchCategory: PropTypes.object,
  searchText: PropTypes.string,
  SearchBarContainer: PropTypes.elementType.isRequired,
  FilterSectionContainer: PropTypes.elementType.isRequired,
  fetchSavedSearchCardData: PropTypes.func,

  //Alert Me
  showSubscriptionTab: PropTypes.bool,
  tabIndex: PropTypes.number,
  setTabIndex: PropTypes.func,
  editSearchData: PropTypes.object,
  isCurrentSavedSearchSubscribed: PropTypes.bool,
  isSavedSearchSubscriptionLoading: PropTypes.bool,
  savedSearchSubscription: PropTypes.object,
  subscriptionRequestError: PropTypes.bool,
  fetchSavedSearchSubscription: PropTypes.func,
  subscribe: PropTypes.func,
  updateSubscription: PropTypes.func,
  refreshSubscription: PropTypes.func,
  unsubscribe: PropTypes.func,
  deleteSubscription: PropTypes.func,
  isSubscriptionUpdating: PropTypes.bool,
  subscriptionUpdateSuccess: PropTypes.bool,
  subscriptionUpdateInProgress: PropTypes.bool,
  subscriptionUpdateError: PropTypes.bool,
};
