import { connect } from "react-redux";
import DamageViewSearch from "./DamageView.Search.page";
import DamageViewSearchBarState from "../redux/DamageViewSearchBarState";
import {
  getSolutionId,
  getActiveOrganization,
} from "modules/organizations/OrganizationsState";
import DamageViewExportSubmissionState from "../redux/DamageViewExportSubmissionState";
import ProfileState from "modules/profile/ProfileState";
import UsersState from "modules/users/UsersState";

const mapStateToProps = (state) => {
  return {
    solutionId: getSolutionId(state),
    activeOrganization: getActiveOrganization(state),
    showFilters:
      DamageViewSearchBarState.selectors.getShowAdvancedSearch(state),
    damageViewSearchResults:
      DamageViewSearchBarState.selectors.getSearchResults(state),
    isLoading: DamageViewSearchBarState.selectors.getIsLoading(state),
    loadingError: DamageViewSearchBarState.selectors.getLoadingError(state),
    isLoadingError: DamageViewSearchBarState.selectors.getIsLoadingError(state),
    defaultSortColumn:
      DamageViewSearchBarState.selectors.getDefaultSortColumn(state),
    defaultReverseSort:
      DamageViewSearchBarState.selectors.getDefaultReverseSort(state),
    sortColumn: DamageViewSearchBarState.selectors.getSortColumn(state),
    reverseSort: DamageViewSearchBarState.selectors.getReverseSort(state),
    page: DamageViewSearchBarState.selectors.getPage(state),
    pageSize: DamageViewSearchBarState.selectors.getPageSize(state),
    totalPages: DamageViewSearchBarState.selectors.getTotalPages(state),
    totalCount: DamageViewSearchBarState.selectors.getTotalEntities(state),
    totalCountIsLoading: DamageViewSearchBarState.selectors.getIsLoading(state),
    exportIdentifier:
      DamageViewSearchBarState.selectors.getExportIdentifier(state),
    exportName: DamageViewSearchBarState.selectors.getExportName(state),
    isExporting: DamageViewSearchBarState.selectors.getIsExporting(state),
    exportFailed: DamageViewSearchBarState.selectors.getExportFailed(state),
    allSubmissionIds:
      DamageViewSearchBarState.selectors.getAllSubmissionIds(state),
    allSubmissionIdsIsLoading:
      DamageViewSearchBarState.selectors.getAllSubmissionIdsIsLoading(state),
    exportPDFIdentifier:
      DamageViewExportSubmissionState.selectors.getExportIdentifier(state),
    exportPDFName:
      DamageViewExportSubmissionState.selectors.getExportName(state),
    isExportingPDF:
      DamageViewExportSubmissionState.selectors.getIsExporting(state),
    userPreference: ProfileState.selectors.getUserPreferences(state),
    currentUser: UsersState.selectors.getCurrentUser(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushDamageViewDetailsPage: (submissionId) => {
      dispatch({
        type: "DAMAGE_VIEW_DETAILS",
        payload: { submission_id: submissionId },
      });
    },
    toggleShowFilters: (showFilters) =>
      dispatch(
        DamageViewSearchBarState.actionCreators.toggleShowFilters(showFilters),
      ),
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        DamageViewSearchBarState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize,
        ),
      ),
    setSort: (sortColumn, reverse) =>
      dispatch(
        DamageViewSearchBarState.actionCreators.setSort(
          null,
          sortColumn,
          reverse,
        ),
      ),
    resetSearch: (solutionId) => {
      dispatch(DamageViewSearchBarState.actionCreators.resetSearchAndFilters());
      dispatch(
        DamageViewSearchBarState.actionCreators.searchEntities(solutionId),
      );
    },
    exportEntities: (solutionId) => {
      dispatch(
        DamageViewSearchBarState.actionCreators.exportSearch(solutionId),
      );
    },
    clearExportErrors: () => {
      dispatch(DamageViewSearchBarState.actionCreators.clearExportErrors());
    },
    resetExport: () => {
      dispatch(DamageViewSearchBarState.actionCreators.resetExport());
    },
    searchEntities: (solutionId) => {
      dispatch(
        DamageViewSearchBarState.actionCreators.searchEntities(solutionId),
      );
    },
    saveBatchAssigned:
      DamageViewSearchBarState.actionCreators.saveBatchAssigned,
    exportPDFEntities: (submissionId, locale) => {
      dispatch(
        DamageViewExportSubmissionState.actionCreators.exportSubmissonData(
          submissionId,
        ),
      );
    },
    resetPDFExports: () =>
      dispatch(DamageViewExportSubmissionState.actionCreators.resetExport()),
    watchActions: (solutionId, submissionId, newWatchValue) =>
      dispatch(
        DamageViewSearchBarState.actionCreators.watchActions(
          solutionId,
          submissionId,
          newWatchValue,
        ),
      ),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    resetSearch: () => dispatchProps.resetSearch(stateProps.solutionId),
    exportEntities: () => dispatchProps.exportEntities(stateProps.solutionId),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DamageViewSearch);
