/** @jsxImportSource @emotion/react */
import { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import Colors from "styles/colors";
import { FlexDiv } from "styles/container-elements";
import { getAuthorizedFilters } from "./filters";

// TODO: transform these FilterButtons in a component
import { MultiSelectFilterButton } from "./FilterButtons";

const FilterContainer = styled.div(
  {
    display: "flex",
    flexFlow: "row wrap",
    borderRadius: "3px",
    borderTopRightRadius: "0px",
    paddingLeft: "0.5em",
  },
  ({
    show = false,
    standalone = false,
    backgroundColor = Colors.background.LIGHT_GRAY,
    style = null,
  }) => ({
    ...style,
    backgroundColor: backgroundColor,
    overflow: show ? "visible" : "hidden",
    maxHeight: show ? "100em" : "0em",
    transition: show ? "max-height 0.4s ease-in" : "max-height 0.4s ease-out",
    marginBottom: standalone ? "0px" : "0.5em",
  }),
);

export const FilterSection = (props) => {
  const {
    show,
    solutionId,
    searchEntities,
    backgroundColor,
    sectionStyle,
    searchFilters,
    hasSearchCriteriaChanged,
    filtersMetadata,
    hideBatchFilter,
    hideSearchButton,
    auth,
    areAllPrerequisiteFiltersSelected,
    clearSavedSearch,
    canUserSearch,
    fetchDomainData,
    standalone,
  } = props;
  const { t } = useTranslation(["components"]);

  const [toggledFilter, setToggledFilter] = useState(null);

  let containerRef = useRef();

  const openFilter = (filterName) => {
    if (toggledFilter !== filterName) {
      setToggledFilter(filterName);
    }
  };

  const closeFilter = (filterName) => {
    // close filter only if it is currently the one open
    if (toggledFilter === filterName) {
      setToggledFilter(null);
    }
  };

  const isFilterToggled = (filter) => {
    return show && filter === toggledFilter;
  };

  const setSearchFilter = (key, value, ignoreValueChange) => {
    const { setSearchFilter } = props;
    clearSavedSearch();
    // If we are hiding the search button, then we want to ignore changes to filters.
    setSearchFilter(key, value, hideSearchButton || ignoreValueChange);
  };

  const clearSearchFilter = (key, ignoreValueChange) => {
    const { clearSearchFilter } = props;
    clearSavedSearch();
    // If we are hiding the search button, then we want to ignore changes to filters.
    clearSearchFilter(key, hideSearchButton || ignoreValueChange);
  };

  const handleSearch = () => {
    searchEntities(solutionId);
  };

  useEffect(() => {
    if (fetchDomainData) {
      fetchDomainData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let authorizedFilters = getAuthorizedFilters(filtersMetadata, auth);

  const filterComponents = authorizedFilters.map((f) => {
    // H2-816: Do not render hidden filters (such as Batch VIN)
    if (f.hidden && f.hidden(props)) {
      return null;
    }

    // Don't render non prerequisite filters if not all prereq filters have been set.
    if (
      !f.prerequisiteForSearchAndFilters &&
      !areAllPrerequisiteFiltersSelected
    ) {
      return null;
    }

    // Do not render batch filters if the hideBatchFilter prop is true
    if (hideBatchFilter && f.queryKey === "batch") {
      return null;
    }

    const Component = f.Component ?? MultiSelectFilterButton;

    return (
      <Component
        sectionRef={containerRef}
        filterKey={f.queryKey}
        label={f.label(t, props)}
        key={`filter-${f.queryKey}`}
        toggled={isFilterToggled(f.queryKey)}
        openFilter={openFilter}
        closeFilter={closeFilter}
        options={f.optionsGetter ? f.optionsGetter(props, t) : undefined}
        optionsState={f.optionsState}
        searchFilters={searchFilters}
        hasSearchCriteriaChanged={hasSearchCriteriaChanged}
        canUserSearch={canUserSearch}
        setSearchFilter={setSearchFilter}
        clearSearchFilter={clearSearchFilter}
        values={_.get(searchFilters, f.queryKey)}
        isFilterContainerVisible={show}
        showAll={f.showAll}
        nIsAsync={f.nIsAsync}
        nOptionsState={f.nOptionsState ?? {}}
        nLabels={f.nLabels}
        nRequirments={f.nRequirments}
        nIsMulti={f.nIsMulti ?? {}}
        nHideFuzzySearch={f.nHideFuzzySearch}
        nHideSelectAll={f.nHideSelectAll}
        nHideSelectEmpty={f.nHideSelectEmpty}
        nDefaultValue={f.nDefaultValue}
        hideSearchButton={hideSearchButton}
        isMulti={f.isMulti ?? false}
        hideFuzzySearch={f.hideFuzzySearch}
        hideSelectAll={f.hideSelectAll}
        hideSelectEmpty={f.hideSelectEmpty}
        dateTypeOptions={
          f.dateTypeOptions ? f.dateTypeOptions(t, auth) : undefined
        }
        onSearchClick={handleSearch}
        selectListSize={f.selectListSize}
        isValueValid={f.isValueValid ?? (() => true)}
        nIsDateRange={f.nIsDateRange}
        nRequirmentsStrategy={f.nRequirmentsStrategy}
      />
    );
  });

  return (
    <div style={{ width: "100%" }} data-qa="filter-section">
      <FilterContainer
        ref={containerRef}
        show={standalone ? true : show}
        backgroundColor={backgroundColor}
        style={{ ...sectionStyle, position: "relative" }}
        standalone={standalone}
      >
        <FlexDiv style={{ flexWrap: "wrap" }}>{filterComponents}</FlexDiv>
      </FilterContainer>
    </div>
  );
};

FilterSection.propTypes = {
  show: PropTypes.bool,
  backgroundColor: PropTypes.string,
  sectionStyle: PropTypes.object,
  searchFilters: PropTypes.object,
  filtersMetadata: PropTypes.arrayOf(
    PropTypes.exact({
      applyFilter: PropTypes.func,
      Component: PropTypes.func,
      dateTypeOptions: PropTypes.func,
      hidden: PropTypes.func,
      hideFuzzySearch: PropTypes.bool,
      hideSelectAll: PropTypes.bool,
      hideSelectEmpty: PropTypes.bool,
      isMulti: PropTypes.bool,
      isValueValid: PropTypes.func,
      label: PropTypes.func,
      nDefaultValue: PropTypes.object,
      nHideFuzzySearch: PropTypes.object,
      nHideSelectAll: PropTypes.object,
      nHideSelectEmpty: PropTypes.object,
      nIsAsync: PropTypes.object,
      nIsDateRange: PropTypes.object,
      nIsMulti: PropTypes.object,
      nLabels: PropTypes.object,
      nOptionsState: PropTypes.object,
      nRequirments: PropTypes.object,
      nRequirmentsStrategy: PropTypes.object,
      onlyUserClearable: PropTypes.bool,
      optionsGetter: PropTypes.func,
      optionsState: PropTypes.object,
      prerequisiteForSearchAndFilters: PropTypes.bool,
      privileges: PropTypes.arrayOf(PropTypes.string),
      queryBuilder: PropTypes.func,
      queryKey: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      requiredEntitySystemConfig: PropTypes.arrayOf(PropTypes.object),
      requiredFeatures: PropTypes.arrayOf(PropTypes.string),
      requiredOrgFilters: PropTypes.string,
      requiredOrgTypes: PropTypes.arrayOf(PropTypes.string),
      selectListSize: PropTypes.string,
      showAll: PropTypes.bool,
    }),
  ),
  areAllPrerequisiteFiltersSelected: PropTypes.bool,
  hasSearchCriteriaChanged: PropTypes.bool,
  canUserSearch: PropTypes.bool,
  solutionId: PropTypes.string,
  auth: PropTypes.object,
  clearSavedSearch: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  setSearchFilter: PropTypes.func,
  hideBatchFilter: PropTypes.bool,
  hideSearchButton: PropTypes.bool,
  searchEntities: PropTypes.func,
  /** Fetch if need to request dependent data for filters. */
  fetchDomainData: PropTypes.func,
  standalone: PropTypes.bool,
};

FilterSection.defaultProps = {
  areAllPrerequisiteFiltersSelected: true,
  canUserSearch: true,
};
