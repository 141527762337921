/** @jsxImportSource @emotion/react */
// Third party Libraries
import PropTypes from "prop-types";
import _ from "lodash";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useTranslation } from "react-i18next";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { Fragment, useEffect, useState } from "react";

// Third party UI Libraries
import { Button } from "react-bootstrap";
import { faHammer } from "@fortawesome/pro-regular-svg-icons";

// UI Libraries
import { Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import { Search } from "components/templates/Search.template";

import DriveAwayBulkActionModalContainer from "../components/search/DriveAway.BulkActionModal.container";
import SearchBarContainer from "pages/driveaway/components/search/DriveAway.SearchBar.container";
import FiltersContainer from "pages/driveaway//components/search/DriveAway.SearchFilters.container";
import DriveAwayEditSavedSearchModalContainer from "pages/driveaway/components/search/DriveAway.EditSavedSearchModal.container";
import ReviewRequestModalContainer from "../components/search/DriveAway.ReviewRequestModal.container";

import { columns } from "pages/driveaway/search/DriveAway.Search.columns";
import ExportModal from "modules/exports/ExportModal";
import {
  isDealer,
  isCarrier,
  isShipper,
} from "shared/utils/organizations.utils";
import { ExportType } from "modules/exports/ExportsState";
import { BulkActionType } from "../utils/enums.utils";

const DriveAwaySearch = (props) => {
  const {
    toggleShowFilters,
    driveAwaySearchResults,
    isLoading,
    isLoadingError,
    loadingError,
    showFilters,
    sortColumn,
    reverseSort,
    page,
    pageSize,
    totalPages,
    setPagination,
    setSort,
    resetSearch,
    exportEntities,
    totalEntities,
    totalEntitiesIsLoading,
    isExporting,
    exportIdentifier,
    exportName,
    exportFailed,
    clearExportErrors,
    selectedDealerOrgId = null,
    isDealerOrg,
    resetExport,
    requestDriveAway,
    cancelDriveAway,
    confirmPickUpRequest,
    requestDriveAwayResult,
    resetDriveAwayRequestStatus,
    searchEntities,
    driveAwayReviewRequest,
    prefilledTimeWindows,
    pickUpWindowTzAbbr,
    pickUpWindowTz,
    resetDriveAwayRequestReviewStatus,
    driveAwayReviewRequestResult,
    driveAwayDenyReasons,
    fetchDenyReasons,
    solutionId,
    showReviewRequestModal,
    setShowReviewRequestModal,
    showDenyRadioButton,
    selectedDaId,
    activeOrganization,
    pushCarrierViewDetailsPage,
    pushVinViewDetailsPage,
    pushFinishedVehicleDetailsView,
    exportPDFIdentifier,
    exportPDFName,
    isExportingPDF,
    exportPDFEntities,
    resetPDFExports,
    bulkActionStatus,
    ddaComments,
    fetchComments,
    errorFetchingDdaComments,
    fetchStatusHistory,
    statusHistory,
    statusHistoryFetchStatus,
    userPreference,
    isDenyReasonUpdate,
    deniedComment,
    deniedReason,
    isModalLoading,
  } = props;

  const { t } = useTranslation("driveaway-search");
  useSetTitleOnMount(t("driveaway-search:Search Results"));

  useTrackWithMixpanelOnce("Viewed Page: DriveAway / Search");

  const [requestLoading, setRequestLoading] = useState(false);
  const [bulkActionModalShow, setBulkActionModalShow] = useState(false);

  useEffect(() => {
    // Request successful, reload result table and reset to initial state.
    if (
      requestDriveAwayResult === "SUCCESS" ||
      driveAwayReviewRequestResult === "SUCCESS" ||
      bulkActionStatus === "SUCCESS"
    ) {
      searchEntities();
      resetDriveAwayRequestStatus();
      setRequestLoading(false);
    }
    if (
      requestDriveAwayResult === "IN_PROGRESS" ||
      driveAwayReviewRequestResult === "IN_PROGRESS" ||
      bulkActionStatus === "IN_PROGRESS"
    ) {
      setRequestLoading(true);
    }
    if (
      requestDriveAwayResult === "DUPLICATE" ||
      requestDriveAwayResult === "ERROR" ||
      driveAwayReviewRequestResult === "DUPLICATE" ||
      driveAwayReviewRequestResult === "ERROR" ||
      bulkActionStatus === "NOT_ALL_SUCCESS" ||
      bulkActionStatus === "ERROR"
    ) {
      setRequestLoading(false);
    }
  }, [
    requestDriveAwayResult,
    driveAwayReviewRequestResult,
    resetDriveAwayRequestReviewStatus,
    bulkActionStatus,
  ]);

  const getBulkActionTypes = () => {
    if (isDealer(activeOrganization)) {
      return [BulkActionType.BULK_SUBMIT];
    }
    if (isCarrier(activeOrganization)) {
      return [BulkActionType.BULK_APPROVE, BulkActionType.BULK_DENY];
    }
    return [];
  };

  const rowClickHandler = (row, cell) => {
    if (!row || !cell) {
      return;
    }

    if (isCarrier(activeOrganization)) {
      pushCarrierViewDetailsPage(row.original.internal_id);
    }

    if (isDealer(activeOrganization)) {
      pushVinViewDetailsPage(row.original.internal_id);
    }

    if (isShipper(activeOrganization)) {
      pushFinishedVehicleDetailsView(row.original.id);
    }
  };

  const btnContainer =
    isDealer(activeOrganization) || isCarrier(activeOrganization)
      ? [
          <Button
            key="driveAwayBulkActionBtn1"
            variant="link"
            css={{
              color: "#8b8b8b",
              ":hover": {
                color: "#273042",
              },
              display: "flex",
              alignItems: "center",
            }}
            data-qa="button-save-this-search"
            onClick={() => setBulkActionModalShow(true)}
          >
            {isDealer(activeOrganization) ? (
              <Text italic underline>
                {t("driveaway-search:Bulk Submit")}
              </Text>
            ) : (
              <Text italic underline>
                {t("driveaway-search:Bulk Action")}
              </Text>
            )}
            <Icon src={faHammer} style={{ marginLeft: "0.3em" }} />
          </Button>,
        ]
      : [];

  return (
    <Fragment>
      <Search
        isLoading={isLoading}
        searchResults={driveAwaySearchResults}
        hideSearchResults={!isDealerOrg && _.isNil(selectedDealerOrgId)}
        hideSearchResultsMessage={t(
          "driveaway-search:Select a Dealer Organization from the filter above to continue.",
        )}
        totalCount={totalEntities}
        totalCountIsLoading={totalEntitiesIsLoading}
        totalCountTitle={t("driveaway-search:Total VINs")}
        SavedSearchModalContainer={DriveAwayEditSavedSearchModalContainer}
        resetSearch={resetSearch}
        showFilters={showFilters}
        toggleShowFilters={toggleShowFilters}
        SearchBarContainer={SearchBarContainer}
        FiltersContainer={FiltersContainer}
        showError={isLoadingError}
        showErrorMessage={loadingError?.response?.data?.error?.message}
        productSpecificSearchBtn={btnContainer}
        exportProps={{
          exportEntities: exportEntities,
          exportIdentifier: exportIdentifier,
          exportName: exportName,
          isExporting: isExporting,
          exportFailed: exportFailed,
          clearExportErrors: clearExportErrors,
          resetExport: resetExport,
        }}
        tableProps={{
          data: driveAwaySearchResults,
          columns: columns(
            t,
            selectedDealerOrgId,
            driveAwaySearchResults,
            requestDriveAway,
            cancelDriveAway,
            confirmPickUpRequest,
            driveAwayReviewRequest,
            driveAwayDenyReasons,
            fetchDenyReasons,
            solutionId,
            setShowReviewRequestModal,
            isExportingPDF,
            exportPDFEntities,
            ddaComments,
            fetchComments,
            errorFetchingDdaComments,
            fetchStatusHistory,
            statusHistory,
            statusHistoryFetchStatus,
            userPreference,
          ),
          isLoading: isLoading || requestLoading,
          showPagination: true,
          fixPaginationToBottom: true,
          isManualPagination: true,
          isManualSorting: true,
          sortColumn: sortColumn,
          reverseSort: reverseSort,
          pageIndex: page,
          pageSize: pageSize,
          pageCount: totalPages,
          onPageChange: (newPage) => {
            setPagination(solutionId, newPage, pageSize);
          },
          onPageSizeChange: (newPageSize) => {
            setPagination(solutionId, 0, newPageSize);
          },
          onPageSort: (column, isReverse) => {
            setSort(column, isReverse);
          },
          rowClickHandler: rowClickHandler,
        }}
      />
      <ReviewRequestModalContainer
        daId={selectedDaId}
        prefilledTimeWindows={prefilledTimeWindows}
        pickUpWindowTzAbbr={pickUpWindowTzAbbr}
        pickUpWindowTz={pickUpWindowTz}
        show={showReviewRequestModal}
        setShowReviewRequestModal={setShowReviewRequestModal}
        showDenyRadioButton={showDenyRadioButton}
        isDenyReasonModifyModal={isDenyReasonUpdate}
        deniedComment={deniedComment}
        deniedReason={deniedReason}
        isModalLoading={isModalLoading}
      ></ReviewRequestModalContainer>

      <ExportModal
        exportIdentifier={exportPDFIdentifier}
        exportName={exportPDFName}
        resetExport={resetPDFExports}
        exportType={ExportType.CSV} // Currently using CSV because backend haven't added a new endpoint yet
      />
      <DriveAwayBulkActionModalContainer
        bulkActionTypes={getBulkActionTypes()}
        show={bulkActionModalShow}
        setShow={setBulkActionModalShow}
      />
    </Fragment>
  );
};

DriveAwaySearch.propTypes = {
  toggleShowFilters: PropTypes.func.isRequired,
  driveAwaySearchResults: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingError: PropTypes.bool,
  loadingError: PropTypes.any,
  showFilters: PropTypes.bool.isRequired,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setPagination: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  totalEntities: PropTypes.number.isRequired,
  totalEntitiesIsLoading: PropTypes.bool.isRequired,
  isExporting: PropTypes.bool.isRequired,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  exportFailed: PropTypes.bool,
  clearExportErrors: PropTypes.func,
  selectedDealerOrgId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  isDealerOrg: PropTypes.bool,
  resetExport: PropTypes.func,
  requestDriveAway: PropTypes.func.isRequired,
  cancelDriveAway: PropTypes.func.isRequired,
  confirmPickUpRequest: PropTypes.func.isRequired,
  requestDriveAwayResult: PropTypes.string,
  resetDriveAwayRequestStatus: PropTypes.func.isRequired,
  searchEntities: PropTypes.func.isRequired,
  driveAwayReviewRequest: PropTypes.func.isRequired,
  resetDriveAwayRequestReviewStatus: PropTypes.func.isRequired,
  driveAwayReviewRequestResult: PropTypes.string,
  driveAwayDenyReasons: PropTypes.array.isRequired,
  fetchDenyReasons: PropTypes.func.isRequired,
  solutionId: PropTypes.string,
  showReviewRequestModal: PropTypes.bool.isRequired,
  showDenyRadioButton: PropTypes.bool,
  prefilledTimeWindows: PropTypes.array,
  pickUpWindowTzAbbr: PropTypes.string,
  pickUpWindowTz: PropTypes.string,
  setShowReviewRequestModal: PropTypes.func.isRequired,
  selectedDaId: PropTypes.string,
  exportPDFEntities: PropTypes.func,
  exportPDFIdentifier: PropTypes.string,
  exportPDFName: PropTypes.string,
  isExportingPDF: PropTypes.bool,
  resetPDFExports: PropTypes.func,
};

export default DriveAwaySearch;
