import { connect } from "react-redux";

import { GeneralInformationStep } from "./GeneralInformationStep.organism";
import NotificationManagementWizardState from "pages/administration/notification-management/redux/NotificationManagementWizardState";
import ProfileState from "modules/profile/ProfileState";

const mapStateToProps = (state) => {
  const { getNotificationRuleName, getDeliveryActions, getUnsubscribeIdMap } =
    NotificationManagementWizardState.selectors;
  const { getUserPreferences } = ProfileState.selectors;

  return {
    userDefaultCommunication: getUserPreferences(state)?.defaultCommunication,
    deliveryActions: getDeliveryActions(state),
    notificationRuleName: getNotificationRuleName(state),
    unsubscribeIdMap: getUnsubscribeIdMap(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    setNotificationRuleName,
    setIsNextButtonDisabled,
    setDeliveryActions,
  } = NotificationManagementWizardState.actionCreators;

  return {
    setNotificationRuleName: (notificationRuleName) => {
      dispatch(setNotificationRuleName(notificationRuleName));
    },
    setIsNextButtonDisabled: (isNextButtonDisabled) => {
      dispatch(setIsNextButtonDisabled(isNextButtonDisabled));
    },
    setDeliveryActions: (deliveryActions) => {
      dispatch(setDeliveryActions(deliveryActions));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneralInformationStep);
