/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect, useState, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import apiUrl from "api-url";
import axios from "axios";
import moment from "moment";
import {
  isCarrier,
  isPartner,
  isShipper,
} from "shared/utils/organizations.utils";

import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { ListGroup } from "components/atoms/ListGroup.atom";
import { Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import ExportModal from "modules/exports/ExportModal";
import { Form } from "react-bootstrap";
import { Icon } from "components/atoms/Icon.atom";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import AsyncSelect from "react-select/async";
import Loader from "react-loader";
import { AsyncPaginate } from "react-select-async-paginate";
import { parseDateTime } from "utils/date-time";
import Colors from "styles/colors";
import { IoMdTimer } from "react-icons/io";
import SelectField from "components-old/forms/fields/SelectField";

import { MediaQueries } from "components/responsive";
import { ExportType } from "modules/exports/ExportsState";
import {
  useDamageViewStatusOptions,
  useDamageViewStatusTranslation,
} from "pages/damageview/utils/translation.utils";
import { tsToDaysHrsMins } from "utils/date-time";

export const SubmissionDetailsWidget = ({
  solutionId,
  submissionId,
  submissionDetailsRequestData = {},
  organization,
  fetchSubmissionDetails,
  saveSubmissionChanges,
  exportPDFEntities,
  exportPDFIdentifier,
  exportPDFName,
  isExportingPDF,
  resetPDFExports,
  userPreference,
  fetchStatusOptions,
  fetchInProgressStatusOptions,
  isInProgressStatusConfigured = false,
  inProgressStatusOptionsMapping,
}) => {
  const { t } = useTranslation("damageview-details");
  const { options: translatedStatusOptions } = useDamageViewStatusOptions();
  const { getTranslatedInProgressStatus } = useDamageViewStatusTranslation();
  const [status, setStatus] = useState(translatedStatusOptions[0]);
  const [rejectedComments, setRejectedComments] = useState("");
  const [repairLocation, setRepairLocation] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [inProgressStatus, setInProgressStatus] = useState(null);

  useEffect(() => {
    fetchSubmissionDetails(submissionId);

    if (isShipper(organization)) {
      fetchInProgressStatusOptions(solutionId);
    }
  }, []);

  useEffect(() => {
    initInputFields(submissionDetailsRequestData);
  }, [submissionDetailsRequestData]);

  const inProgressStatusOptions = useMemo(() => {
    const filteredInProgressStatusOptions = (status) => {
      let array = inProgressStatusOptionsMapping[status] ?? [];
      let filtered = array.filter((el) => el != null);
      return filtered?.map((ele) => ({
        label: getTranslatedInProgressStatus(ele),
        value: ele,
      }));
    };

    if (isShipper(organization)) {
      if (isInProgressStatusConfigured) {
        // Show in progress dropdown options depends on status
        switch (status?.value) {
          case "submitted":
            return filteredInProgressStatusOptions("Submitted");
          case "minor":
            return filteredInProgressStatusOptions("Minor");
          case "major":
            return filteredInProgressStatusOptions("Major");
          case "warranty":
            return filteredInProgressStatusOptions("Warranty");
          case "rejected":
            return filteredInProgressStatusOptions("Rejected");
          case "completed":
            return filteredInProgressStatusOptions("Completed");
          default:
            return null;
        }
      }
    }
    return null;
  }, [
    getTranslatedInProgressStatus,
    inProgressStatusOptionsMapping,
    isInProgressStatusConfigured,
    organization,
    status,
  ]);

  const showInProgressStatusDropdown = useMemo(() => {
    // Show in progress dropdown depends on org types, config and selected status
    if (isShipper(organization)) {
      if (isInProgressStatusConfigured) {
        switch (status?.value) {
          case "submitted":
            return false;
          case "minor":
            return true;
          case "major":
            return true;
          case "warranty":
            return true;
          case "rejected":
            return false;
          case "completed":
            return true;
          default:
            return false;
        }
      } else {
        // Clean up the field if the shipper is not configured for it
        setInProgressStatus(null);
        return false;
      }
    } else {
      // Show the dropdown for carrier and partner to see the value but they cannot change it since it's disabled
      return true;
    }
  }, [isInProgressStatusConfigured, organization, status]);

  const initInputFields = (requestData) => {
    if (requestData?.data?.assignee_email) {
      setSelectedAssignee({
        label: requestData?.data?.assignee_email,
        value: requestData?.data?.assignee_email,
        data: requestData?.data?.assignee_email,
      });
    }

    if (requestData?.data?.status) {
      const idx = translatedStatusOptions.findIndex((obj) => {
        return obj.value === requestData?.data?.status.toLowerCase();
      });
      setStatus(translatedStatusOptions[idx >= 0 ? idx : 0]);
    }

    if (
      requestData?.data?.status.toLowerCase() === "rejected" &&
      requestData?.data?.rejectedComments
    ) {
      setRejectedComments(requestData?.data?.rejectedComments);
    }

    if (requestData?.data?.repairLocation) {
      setRepairLocation(requestData?.data?.repairLocation);
    }

    if (requestData?.data?.inProgressStatus) {
      setInProgressStatus(requestData?.data?.inProgressStatus);
    }
  };

  const formattedTime = (rawTime) => {
    const parsedTime = parseDateTime(rawTime, true);
    return `${parsedTime?.date ?? ""} ${parsedTime?.time ?? ""}`;
  };

  const FormattedAddress = ({
    address = "",
    city = "",
    code = "",
    country = "",
    name = "",
    postalCode = "",
    state = "",
  }) => {
    return (
      <div>
        <Text block>{`${name} (${code})`}</Text>
        <Text block>{`${address}`}</Text>
        <Text block>{`${city} ${state} ${postalCode} ${country}`}</Text>
      </div>
    );
  };

  const AgingContent = ({ submissionDate }) => {
    if (!submissionDate) {
      return null;
    }

    const differenceInMins = moment(new Date()).diff(
      moment(submissionDate),
      "minutes",
    );
    const agingHour = Math.floor(differenceInMins / 60);
    const isAging = agingHour >= 72 ? true : false;

    return (
      <div css={{ display: "flex", flexDirection: "row" }}>
        <IoMdTimer
          style={{
            fontSize: 20,
            marginRight: 10,
            color: isAging ? Colors.holds.RED : Colors.holds.YELLOW,
          }}
        />
        <Text bold>{tsToDaysHrsMins(submissionDate)}</Text>
      </div>
    );
  };

  const detailElements = [
    {
      title: t("damageview-details:Submission ID"),
      content: submissionDetailsRequestData?.data?.external_id ?? "",
    },
    {
      title: t("damageview-details:Location"),
      content: submissionDetailsRequestData?.data?.location ?? "",
    },
    {
      title: t("damageview-details:Reporting Location"),
      content:
        submissionDetailsRequestData?.data?.location?.toLowerCase() ===
        "at location"
          ? FormattedAddress(
              submissionDetailsRequestData?.data?.reportingLocation ?? {},
            )
          : submissionDetailsRequestData?.data?.reportingLocation,
    },
    {
      title: t("damageview-details:Damage Location"),
      content: submissionDetailsRequestData?.data?.damageLocation ?? "",
    },
    {
      title: t("damageview-details:Chock Condition"),
      content: submissionDetailsRequestData?.data?.chockCondition ?? "",
    },
    {
      title: t("damageview-details:Primary Chock Type"),
      content: submissionDetailsRequestData?.data?.primaryChockType ?? "",
    },
    {
      title: t("damageview-details:Railcar Number"),
      content: submissionDetailsRequestData?.data?.railcarNumber ?? "",
    },
    {
      title: t("damageview-details:Railcar Type"),
      content: submissionDetailsRequestData?.data?.railcarType ?? "",
    },
    {
      title: t("damageview-details:Submitter Email"),
      content: submissionDetailsRequestData?.data?.submitter_email ?? "",
    },
    {
      title: t("damageview-details:Submitter Phone"),
      content: submissionDetailsRequestData?.data?.submitter_phone ?? "",
    },
    {
      title: t("damageview-details:Secondary Submitter Email"),
      content:
        submissionDetailsRequestData?.data?.secondarySubmitterEmail ?? "",
    },
    {
      title: t("damageview-details:Secondary Submitter Phone"),
      content:
        submissionDetailsRequestData?.data?.secondarySubmitterPhoneNumber ?? "",
    },
    {
      title: t("damageview-details:Submission Date"),
      content: formattedTime(
        submissionDetailsRequestData?.data?.submission_date ?? "",
      ),
    },
    ...(status?.value?.toLowerCase() === "submitted"
      ? [
          {
            title: t("damageview-details:Submission Aging"),
            content: AgingContent({
              submissionDate:
                submissionDetailsRequestData?.data?.submission_date,
            }),
          },
        ]
      : []),
    {
      title: t("damageview-details:Inspection Date"),
      content: formattedTime(
        submissionDetailsRequestData?.data?.inspection_date ?? "",
      ),
    },
    {
      title: t("damageview-details:Unload Date"),
      content: formattedTime(
        submissionDetailsRequestData?.data?.unloadDate ?? "",
      ),
    },
    ...(isCarrier(organization) || isPartner(organization)
      ? [
          {
            title: t("damageview-details:Shipper"),
            content: submissionDetailsRequestData?.data?.shipper,
          },
        ]
      : []),
  ];

  const loadAssignees = async (currentVal, pageNumber) => {
    let data = [];
    let metaData = null;
    const url = apiUrl(
      `/damageview/submission/filters?filterType=assignee&query=${searchText}&pageSize=20&pageNumber=${pageNumber}`,
    );
    await axios
      .get(url)
      .then((res) => {
        data = res?.data?.data?.map((assignee) => {
          return {
            label: assignee,
            value: assignee,
            data: assignee,
          };
        });
        metaData = res?.data?.meta;
      })
      .catch((err) => {
        console.log(err);
      });

    const dataValue = {
      options: data ?? [],
      hasMore: metaData
        ? metaData.currentPage + 1 < metaData.totalPages // currentPage start from 0
        : false,
      additional: {
        pageNumber,
        prevValue: currentVal,
      },
    };
    return dataValue;
  };

  const saveHandler = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (status?.value === "rejected") {
      if (!form.checkValidity()) {
        return;
      }
    }

    setIsSaving(true);
    if (status?.value !== "rejected") {
      setRejectedComments("");
    }
    let payload = {
      solutionId: solutionId,
      submissionId: submissionId,
      assignee: selectedAssignee?.value ?? "",
      status: status?.value ?? "",
      rejectedComments: rejectedComments ?? "",
      repairLocation: repairLocation ?? "",
    };

    if (isInProgressStatusConfigured && isShipper(organization)) {
      payload = {
        ...payload,
        inProgressStatus: inProgressStatus ?? null,
      };
    }

    saveSubmissionChanges(payload)
      .then((res) => {
        setIsEdited(false);
        setIsSaving(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadStatusOptions = (searchText) => {
    return fetchStatusOptions(searchText).then((fetchedOptions) => {
      const lowercasedFetchedOptions =
        fetchedOptions?.map((op) => op.toLowerCase()) ?? [];

      const translatedOptions = translatedStatusOptions.filter((obj) => {
        return lowercasedFetchedOptions.includes(obj.value);
      });

      return translatedOptions;
    });
  };

  const inProgressStatusHandler = (selection) => {
    setIsEdited(true);
    setInProgressStatus(selection?.value);
  };

  return (
    <Fragment>
      <PanelGroup>
        <PanelGroup.Header
          title={t("damageview-details:Submission Details")}
        ></PanelGroup.Header>
        <PanelGroup.Content style={{ padding: "1rem", minHeight: "5rem" }}>
          <Loader
            loaded={
              submissionDetailsRequestData?.isLoading
                ? false
                : !submissionDetailsRequestData?.isLoadingError
            }
          >
            <Form onSubmit={saveHandler}>
              <div
                css={{
                  display: "flex",
                  [MediaQueries.smallAndDown]: {
                    flexDirection: "column",
                  },
                  [MediaQueries.smallAndUp]: {
                    flexDirection: "row",
                  },
                  flexWrap: "wrap",
                }}
              >
                <div
                  css={{
                    display: "flex",
                    [MediaQueries.smallAndDown]: {
                      flexDirection: "column",
                    },
                    width: "100%",
                    flex: 6,
                    [MediaQueries.smallAndUp]: {
                      display: "grid",
                      gridTemplateRows: "repeat(6, 1fr)",
                      gridAutoFlow: "column",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: "1rem",
                    },
                  }}
                >
                  {detailElements.map((obj, itemIdx) => {
                    return (
                      <ListGroup.Item
                        key={`item${itemIdx}`}
                        styles={{
                          border: "none",
                          paddingRight: 0,
                          paddingLeft: 0,
                        }}
                      >
                        <Text bold block>
                          {t(`damageview-details:${obj.title}`)}
                        </Text>
                        <Text block>{obj.content}</Text>
                      </ListGroup.Item>
                    );
                  })}
                </div>
                <div css={{ flex: 3 }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <Text bold>{t("damageview-details:Assignee") + ":"}</Text>
                    <AsyncPaginate
                      isDisabled={!isShipper(organization) || isSaving}
                      css={{ width: "100%" }}
                      debounceTimeout={500}
                      isMulti={false}
                      cacheOptions={false}
                      onInputChange={(val) => {
                        setSearchText(val);
                      }}
                      hideSelectedOptions={false}
                      closeMenuOnSelect={true}
                      value={selectedAssignee}
                      loadOptions={async (
                        currentVal,
                        options,
                        additionalOptions,
                      ) => {
                        const data = await loadAssignees(
                          currentVal,
                          additionalOptions &&
                            currentVal === additionalOptions?.prevValue
                            ? additionalOptions.pageNumber + 1
                            : 0,
                        );
                        return data;
                      }}
                      cacheUniqs={[searchText]}
                      onChange={(selectedData) => {
                        setIsEdited(true);
                        setSelectedAssignee(selectedData ?? null);
                      }}
                      styles={{
                        menuList: (provided) => ({
                          ...provided,
                          height: "120px",
                          overflow: "auto",
                        }),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <Text bold>{t("damageview-details:Status") + ":"}</Text>
                    <AsyncSelect
                      defaultOptions
                      isSearchable={false}
                      value={status}
                      isDisabled={!isShipper(organization) || isSaving}
                      loadOptions={loadStatusOptions}
                      onChange={(selection) => {
                        setIsEdited(true);
                        setStatus(selection ?? translatedStatusOptions[0]);
                        setInProgressStatus(null);
                      }}
                      css={{ width: "100%" }}
                      styles={{
                        menuList: (provided) => ({
                          ...provided,
                          height: "120px",
                          overflow: "auto",
                        }),
                      }}
                    />
                  </div>
                  {showInProgressStatusDropdown ? (
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <Text bold css={{ minWidth: "7.5rem" }}>
                        {t("damageview-details:In Progress Status") + ":"}
                      </Text>
                      <SelectField
                        isSearchable={false}
                        isClearable={true}
                        stateValue={inProgressStatus}
                        placeholder={
                          !isShipper(organization) || isSaving
                            ? ""
                            : t("damageview-details:Select...")
                        }
                        onChange={inProgressStatusHandler}
                        options={inProgressStatusOptions}
                        isDisabled={!isShipper(organization) || isSaving}
                      />
                    </div>
                  ) : null}
                  {status?.value === "rejected" ? (
                    <Form.Group>
                      <Form.Label htmlFor="rejected-comments">
                        <Text bold>
                          {t("damageview-details:Rejected Comments") + ":"}{" "}
                          <Text css={{ color: Colors.text.RED }}>*</Text>
                        </Text>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        id="rejected-comments"
                        required={true}
                        rows={3}
                        value={rejectedComments}
                        disabled={!isShipper(organization) || isSaving}
                        onChange={(e) => {
                          setIsEdited(true);
                          setRejectedComments(e.target.value);
                        }}
                      />
                    </Form.Group>
                  ) : null}
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="repair-location">
                      <Text bold>
                        {t("damageview-details:Repair Location") + ":"}
                      </Text>
                    </Form.Label>
                    <Form.Control
                      disabled={!isShipper(organization) || isSaving}
                      id="repair-location"
                      type="text"
                      value={repairLocation}
                      onChange={(e) => {
                        setIsEdited(true);
                        setRepairLocation(e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>

              <div css={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  css={{ paddingLeft: "unset" }}
                  variant="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    exportPDFEntities(submissionId, userPreference?.locale);
                  }}
                >
                  {t("damageview-details:Download")}
                  {isExportingPDF ? (
                    <Icon
                      style={{ marginLeft: "0.5rem" }}
                      src={faSpinner}
                      spin
                    />
                  ) : null}
                </Button>
                {isShipper(organization) ? (
                  <Button
                    disabled={!isEdited || isSaving}
                    variant="success"
                    type="submit"
                  >
                    {t("damageview-details:Save Changes")}
                    {isSaving ? (
                      <Icon
                        style={{ marginLeft: "0.5rem" }}
                        src={faSpinner}
                        spin
                      />
                    ) : null}
                  </Button>
                ) : null}
              </div>
            </Form>
          </Loader>
        </PanelGroup.Content>
      </PanelGroup>
      <ExportModal
        exportIdentifier={exportPDFIdentifier}
        exportName={exportPDFName}
        resetExport={resetPDFExports}
        exportType={ExportType.CSV} // Currently using CSV because backend haven't added a new endpoint yet
      />
    </Fragment>
  );
};

SubmissionDetailsWidget.propTypes = {
  solutionId: PropTypes.string,
  submissionId: PropTypes.string,
  organization: PropTypes.object,
  fetchSubmissionDetails: PropTypes.func,
  saveSubmissionChanges: PropTypes.func,
  submissionDetailsRequestData: PropTypes.shape({
    data: PropTypes.object,
    isLoading: PropTypes.bool,
  }),
  exportPDFEntities: PropTypes.func,
  exportPDFIdentifier: PropTypes.string,
  exportPDFName: PropTypes.string,
  isExportingPDF: PropTypes.bool,
  resetPDFExports: PropTypes.func,
  userPreference: PropTypes.object,
  fetchStatusOptions: PropTypes.func,
  fetchInProgressStatusOptions: PropTypes.func,
  isInProgressStatusConfigured: PropTypes.bool,
  inProgressStatusOptionsMapping: PropTypes.object,
};
