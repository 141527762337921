/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import React, { useState, useEffect, forwardRef, useRef } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Overlay from "react-bootstrap/Overlay";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";
import moment from "moment-timezone";
import _ from "lodash";
import { MdClose } from "react-icons/md";
import { Text } from "components/atoms/Text.atom";
import {
  FUZZY_PREFIX,
  SearchableSelectList,
  useSelectOptions,
  buildFuzzyOption,
  SelectListSize,
} from "components/molecules/SearchableSelectList.molecule";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";

import Colors from "../../styles/colors";
import { BatchFilterModal } from "../../components/search-bar/BatchFilterModal";
import { Checkbox } from "components/atoms/Checkbox.atom";
import { useGetLatest } from "components/hooks/useGetLatest";
import { RequirementStrategy } from "./enums.utils";
import {
  getDateFormatFromUserPreferences,
  getTimeFormatFromUserPreferences,
  parseDateTime,
} from "utils/date-time";

const MAX_FILTER_LABEL_LENGTH = 20;

const ButtonWrapper = styled.div(
  {
    display: "flex",
    overflow: "hidden",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "1em",
    height: "2em",
    marginTop: "0.5em",
    marginBottom: "0.5em",
    marginRight: "0.5em",
    border: `1px solid ${Colors.background.DARK_BLUE}`,
    whiteSpace: "nowrap",
  },
  ({ toggled = false, hasValue = false }) => ({
    backgroundColor: hasValue
      ? "white"
      : toggled
      ? Colors.background.GRAY
      : Colors.background.LIGHT_GRAY,
    color: Colors.background.DARK_BLUE,
    ":hover": {
      cursor: "pointer",
      backgroundColor: Colors.background.GRAY,
    },
  }),
);

const FilterPopover = forwardRef(
  (
    {
      closeFilter,
      filterKey,
      style,
      label,
      popper,
      popoverPosProps = { left: 0, right: "auto" },
      children,
      helpText,
      onSearchClick,
      canUserSearch,
      hasSearchCriteriaChanged,
      hideSearchButton,
    },
    popoverRef,
  ) => {
    const { t } = useTranslation("components");

    // H2-3249: avoid cropping issues by updating popper when content changes
    useEffect(() => {
      popper.scheduleUpdate();
    }, [children, popper]);

    return (
      <div
        ref={popoverRef}
        css={{
          ...style,
          ...popoverPosProps,
          zIndex: 100,
          position: "absolute",
          boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.1)",
          borderRadius: 3,
          backgroundColor: Colors.background.LIGHT_GRAY,
        }}
      >
        <div
          css={{
            display: "flex",
            color: "white",
            backgroundColor: Colors.background.DARK_GRAY,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0.5em",
            fontSize: "1.2em",
            fontWeight: "300",
          }}
          data-qa={`header-popover-filter-button-${filterKey}`}
        >
          <span>
            {label}
            {helpText ? (
              <Tooltip
                style={{ marginLeft: "0.5em" }}
                tooltipChildren={helpText}
                placement="right"
              >
                <Icon src={faInfoCircle} />
              </Tooltip>
            ) : null}
          </span>
          <div
            css={{ ":hover": { cursor: "pointer" } }}
            onClick={() => closeFilter(filterKey)}
            data-qa={`button-close-filter-button-${filterKey}`}
          >
            <MdClose width="1.25em" height="1.25em" />
          </div>
        </div>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "1em",
            gap: "1em",
          }}
        >
          <div data-qa={`content-popover-filter-button-${filterKey}`}>
            {children}
          </div>
          {!hideSearchButton ? (
            <Button
              variant={
                hasSearchCriteriaChanged && canUserSearch ? "primary" : "dark"
              }
              pulse={hasSearchCriteriaChanged && canUserSearch}
              disabled={!canUserSearch}
              onClick={() => {
                onSearchClick();
                closeFilter(filterKey); // Dismiss the popup.
              }}
            >
              {t("components:Search")}
            </Button>
          ) : null}
        </div>
      </div>
    );
  },
);

FilterPopover.propTypes = {
  closeFilter: PropTypes.func,
  filterKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  style: PropTypes.object,
  label: PropTypes.string,
  popoverPosProps: PropTypes.object,
  children: PropTypes.node,
  helpText: PropTypes.string,
  canUserSearch: PropTypes.bool,
  onSearchClick: PropTypes.func,
  hasSearchCriteriaChanged: PropTypes.bool,
  hideSearchButton: PropTypes.bool,
  popper: PropTypes.object,
};

const FilterButton = (props) => {
  const {
    filterKey,
    filterValue,
    label,
    toggled,
    openFilter,
    closeFilter,
    clearSearchFilter,
    children,
    sectionRef,
    className,
    isNthFilter, //Used to support an array of filters in one drop down
  } = props;

  const containerRef = useRef();
  const targetRef = useRef();

  // Truncate filter value if it's long
  let filterLabel = filterValue;
  if (filterValue && filterValue.length > MAX_FILTER_LABEL_LENGTH) {
    filterLabel = `${filterLabel.slice(0, MAX_FILTER_LABEL_LENGTH)}…`;
  }

  return (
    <div css={{ position: "relative" }}>
      <div css={{ position: "relative" }} ref={containerRef}>
        <ButtonWrapper
          ref={targetRef}
          className={className}
          toggled={toggled}
          hasValue={filterValue != null}
          onClick={() => openFilter(filterKey)}
          data-qa={`filter-button-${filterKey}`}
        >
          <div css={{ marginLeft: "0.5em", marginRight: "0.5em" }}>{label}</div>
          {filterValue === null && (
            <div css={{ marginLeft: "0.5em", marginRight: "0.5em" }}>{"▾"}</div>
          )}
          {filterValue !== null && (
            <div
              css={{
                paddingLeft: "0.5em",
                paddingRight: "0.5em",
                display: "flex",
                alignItems: "center",
                height: "100%",
                backgroundColor: Colors.background.DARK_GRAY,
                color: "white",
              }}
            >
              {filterLabel}
              <div
                onClick={(e) => {
                  if (isNthFilter) {
                    for (let i = 0; i < filterKey.length; i++) {
                      clearSearchFilter(filterKey[i]);
                    }
                  } else {
                    clearSearchFilter(filterKey);
                  }
                  e.stopPropagation();
                  closeFilter(filterKey); // remove popover
                }}
                css={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                  color: Colors.background.DARK_BLUE,
                  marginLeft: "5px",
                  borderRadius: "1em",
                  width: "1em",
                  height: "1em",
                  ":hover": {
                    backgroundColor: Colors.highlight.RED,
                  },
                }}
              >
                <MdClose data-qa={`button-close-${filterKey}`} />
              </div>
            </div>
          )}
        </ButtonWrapper>
      </div>
      <Overlay
        show={toggled}
        placement="bottom"
        container={containerRef.current}
        target={targetRef.current}
        animation={false}
        rootClose={true}
        onHide={() => closeFilter(filterKey)}
        popperConfig={{
          modifiers: [
            {
              name: "preventOverflow",
              options: {
                // We are prevent overflow based on the parent FilterSection boundary
                boundary: sectionRef.current,
              },
            },
          ],
        }}
      >
        {(overlayProps) => (
          <FilterPopover {...props} {...overlayProps}>
            {children}
          </FilterPopover>
        )}
      </Overlay>
    </div>
  );
};

FilterButton.propTypes = {
  filterKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  toggled: PropTypes.bool,
  openFilter: PropTypes.func,
  closeFilter: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  sectionRef: PropTypes.shape({ current: PropTypes.object }),
  children: PropTypes.node,
  className: PropTypes.string,
  isNthFilter: PropTypes.bool,
  canUserSearch: PropTypes.bool,
  onSearchClick: PropTypes.func,
  hasSearchCriteriaChanged: PropTypes.bool,
  hideSearchButton: PropTypes.bool,
};

const SelectFilterButton = (props) => {
  const {
    filterKey,
    options,
    searchFilters,
    setSearchFilter,
    clearSearchFilter,
    hideSelectEmpty,
    selectListSize,
  } = props;

  const { t } = useTranslation("components");
  const { SELECT_EMPTY_OPTION } = useSelectOptions();

  const isSelectingEmpty =
    searchFilters[filterKey] === SELECT_EMPTY_OPTION.value;

  let selectedOption = searchFilters[filterKey]
    ? options.find((option) => option.value === searchFilters[filterKey])
    : null;

  const filterLabel = isSelectingEmpty
    ? t("components:Empty")
    : selectedOption?.label;

  return (
    <FilterButton
      {...props}
      filterValue={filterLabel ?? null}
      clearSearchFilter={clearSearchFilter}
    >
      <SelectFilterButtonField
        filterKey={filterKey}
        options={options}
        searchFilters={searchFilters}
        setSearchFilter={setSearchFilter}
        clearSearchFilter={clearSearchFilter}
        hideSelectEmpty={hideSelectEmpty}
        selectListSize={selectListSize}
      />
    </FilterButton>
  );
};

SelectFilterButton.propTypes = {
  filterKey: PropTypes.string,
  options: PropTypes.array,
  searchFilters: PropTypes.object,
  setSearchFilter: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  hideSelectEmpty: PropTypes.bool,
  selectListSize: PropTypes.oneOf([
    SelectListSize.REGULAR,
    SelectListSize.LARGE,
  ]),
};

// DEV-1070
// We're using dummy ids for the shipment_events filters to group multiple status ids.
// The grouped status ids are stored in the groupsIds attribute of each filter option.
// Since the status ids in the groupsIds list of each entry won't match the
// actual id of the filter option, we need to do some translation to make the select
// control display the group's label. Also we have need to make sure we're adding each
// grouping filter just once.
const getFilterValues = (selectedFilters, options) => {
  let selFilters = Array.isArray(selectedFilters)
    ? selectedFilters
    : [selectedFilters];
  let filterValues = [];

  let seenValues = new Set();
  selFilters.forEach((val) => {
    const option = options.find(
      (opt) => opt.groupsIds && opt.groupsIds.includes(val),
    );
    if (option) {
      // Check if we've already added this filter value.
      if (!seenValues.has(option.value)) {
        filterValues.push({ value: option.value, label: option.label });
        seenValues.add(option.value);
      }
    }
    // Couldn't find an option in the dropdown containing the value in the
    // groupsIds arrays, return the actual id of the option.
    else {
      const option = options.find((opt) => opt.value === val);
      if (option) {
        filterValues.push({ value: option.value, label: option.label });
      }
    }
  });

  return filterValues;
};

const MultiSelectFilterButton = (props) => {
  const {
    filterKey,
    label,
    options,
    searchFilters,
    setSearchFilter,
    clearSearchFilter,
    hideFuzzySearch,
    hideSelectAll,
    hideSelectEmpty,
  } = props;

  const { t } = useTranslation("components");
  const { SELECT_ALL_OPTION, SELECT_EMPTY_OPTION } = useSelectOptions();

  const currentFilter = searchFilters && searchFilters[filterKey];

  const isSelectingAll = currentFilter?.includes(SELECT_ALL_OPTION.value);
  const isSelectingEmpty = currentFilter?.includes(SELECT_EMPTY_OPTION.value);

  const fuzzyValues = currentFilter
    ? currentFilter
        .filter((value) => value.toString().startsWith(FUZZY_PREFIX))
        .map((value) => value.toString().substring(FUZZY_PREFIX.length))
    : [];

  // DEV-1070 Get the filter names for the selected options in the dropdown.
  const filterValues = currentFilter
    ? getFilterValues(currentFilter, options)
    : [];

  const numFilters = filterValues.length + (isSelectingEmpty ? 1 : 0);

  let filterValue = null;

  // For fuzzy values, we display matches with the values in quotes
  if (fuzzyValues.length > 0) {
    filterValue = `${t("components:matches")} "${fuzzyValues.toString()}"`;
  }
  // For special case, selecting all we display "All"
  else if (isSelectingAll) {
    filterValue = t("components:All");
  } else {
    if (numFilters > 0) {
      const firstFilterValue = currentFilter[0];
      // For special case, we're adding "Empty" for select empty values
      const optionsWithEmpty = [
        ...options,
        { label: t("components:Empty"), value: SELECT_EMPTY_OPTION.value },
      ];
      let foundFilter = optionsWithEmpty.find(
        (o) =>
          o.value === firstFilterValue ||
          (o.groupsIds && o.groupsIds.includes(firstFilterValue)),
      ); // DEV-1070

      // Label the filter with an actual value in the options.
      // Or, if no option found, with the filter value
      filterValue = foundFilter ? foundFilter.label : firstFilterValue;
    }

    if (numFilters > 1) {
      if (filterValue && filterValue.length > MAX_FILTER_LABEL_LENGTH - 8) {
        filterValue = `${filterValue.slice(0, MAX_FILTER_LABEL_LENGTH - 8)}…`;
      }

      filterValue = filterValue + ` + ${numFilters - 1}`;
    }
  }

  return (
    <FilterButton
      {...props}
      filterValue={filterValue}
      clearSearchFilter={clearSearchFilter}
    >
      <MultiSelectFilterButtonField
        filterKey={filterKey}
        label={label}
        searchFilters={searchFilters}
        setSearchFilter={setSearchFilter}
        options={options}
        hideFuzzySearch={hideFuzzySearch}
        hideSelectAll={hideSelectAll}
        hideSelectEmpty={hideSelectEmpty}
      />
    </FilterButton>
  );
};

MultiSelectFilterButton.propTypes = {
  filterKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  options: PropTypes.array,
  searchFilters: PropTypes.object,
  setSearchFilter: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  hideFuzzySearch: PropTypes.bool,
  hideSelectAll: PropTypes.bool,
  hideSelectEmpty: PropTypes.bool,
};

const MultiSelectFilterButtonField = (props) => {
  const {
    filterKey,
    label,
    options,
    searchFilters,
    setSearchFilter,
    hideFuzzySearch,
    hideSelectAll,
    hideSelectEmpty,
    selectListSize,
    required = false,
    defaultValue,
  } = props;
  const { SELECT_ALL_OPTION, SELECT_EMPTY_OPTION } = useSelectOptions();

  const currentFilter = searchFilters && searchFilters[filterKey];

  const isSelectingAll = currentFilter?.includes(SELECT_ALL_OPTION.value);
  const isSelectingEmpty = currentFilter?.includes(SELECT_EMPTY_OPTION.value);

  let selectedFuzzyOptions = [];

  if (currentFilter) {
    selectedFuzzyOptions = currentFilter
      .filter((key) =>
        // keys may not be strings
        key.toString().startsWith(FUZZY_PREFIX),
      )
      .map((key) => {
        const fuzzyValue = key.toString().substring(FUZZY_PREFIX.length);
        return buildFuzzyOption(fuzzyValue);
      });
  }

  let filterValues = currentFilter
    ? getFilterValues(currentFilter, options)
    : [];

  // Add back in all "static" options
  if (isSelectingAll) {
    filterValues = [SELECT_ALL_OPTION];
  }
  if (isSelectingEmpty) {
    filterValues = [...filterValues, SELECT_EMPTY_OPTION];
  }
  filterValues = [...filterValues, ...selectedFuzzyOptions];

  return (
    <SearchableSelectList
      multi
      maxFuzzySearch={hideFuzzySearch ? 0 : Infinity}
      hideSelectAll={hideSelectAll}
      hideSelectEmpty={hideSelectEmpty}
      name={label}
      options={options}
      selectedOptions={filterValues}
      onChange={(options) => {
        let values = [];
        if (options) {
          values = options
            .map((o) => {
              return o.groupsIds ? o.groupsIds : o.value; // DEV-1070
            })
            .flat();
        }
        setSearchFilter(filterKey, values);
      }}
      selectListSize={selectListSize}
      required={required}
      defaultValue={defaultValue}
    />
  );
};

MultiSelectFilterButtonField.propTypes = {
  filterKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  searchFilters: PropTypes.object,
  setSearchFilter: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.array,
  isMulti: PropTypes.bool,
  hideFuzzySearch: PropTypes.bool,
  hideSelectAll: PropTypes.bool,
  hideSelectEmpty: PropTypes.bool,
  selectListSize: PropTypes.oneOf([
    SelectListSize.REGULAR,
    SelectListSize.LARGE,
  ]),
  required: PropTypes.bool,
  defaultValue: PropTypes.any,
};

const SelectFilterButtonField = (props) => {
  const { SELECT_EMPTY_OPTION } = useSelectOptions();

  const {
    filterKey,
    options,
    searchFilters,
    setSearchFilter,
    clearSearchFilter,
    hideSelectEmpty,
    selectListSize,
    required = false,
    defaultValue,
  } = props;

  const currentFilter = searchFilters && searchFilters[filterKey];

  const isSelectingEmpty = currentFilter === SELECT_EMPTY_OPTION.value;

  let selectedOption = currentFilter
    ? options.find((option) => option.value === currentFilter)
    : null;

  if (isSelectingEmpty) {
    selectedOption = SELECT_EMPTY_OPTION;
  }

  return (
    <SearchableSelectList
      maxFuzzySearch={0}
      hideSelectAll
      hideSelectEmpty={hideSelectEmpty}
      options={options}
      selectedOption={selectedOption}
      onChange={(option) => {
        if (option === null) {
          clearSearchFilter(filterKey);
        } else {
          setSearchFilter(filterKey, option.value);
        }
      }}
      selectListSize={selectListSize}
      required={required}
      defaultValue={defaultValue}
    />
  );
};

SelectFilterButtonField.propTypes = {
  filterKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  searchFilters: PropTypes.object,
  setSearchFilter: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  options: PropTypes.array,
  hideSelectEmpty: PropTypes.bool,
  selectListSize: PropTypes.oneOf([
    SelectListSize.REGULAR,
    SelectListSize.LARGE,
  ]),
  required: PropTypes.bool,
  defaultValue: PropTypes.any,
};

/**
 * @description NFilterButton is used when we want to render a subfilter after selecting a parent filter value
 * Originally NFilterButton was designed to have N subfilters, but the original design can not be used
 * when we want to have more than 1 parent filter option to have a subfilter.
 * A change was made in release/2023/047 so that there will only
 * be 1 subfilter max. The change would allow more than 1 parent filter option to have a subfilter.
 * @discussion In the future maybe we can have a flag to allow different behavior such as N subfilters and more than
 * 1 filter option to have subfilter
 */
const NFilterButton = (props) => {
  const {
    filterKey: filterKeys,
    options,
    searchFilters,
    setSearchFilter,
    clearSearchFilter,
    nIsAsync,
    nOptionsState,
    nLabels,
    nRequirments,
    nIsMulti,
    nHideFuzzySearch,
    nHideSelectAll,
    nHideSelectEmpty,
    nDefaultValue,
    showAll = true,
    nIsDateRange,
    nRequirmentsStrategy,
  } = props;

  const { t } = useTranslation("components");
  const { SELECT_ALL_OPTION, SELECT_EMPTY_OPTION } = useSelectOptions();

  const getStateForFilter = (key) => {
    const isAsyncFilter = nIsAsync[key];
    const isDateRange = nIsDateRange?.[key];
    const values = searchFilters?.[key] ?? [];

    if (isAsyncFilter) {
      let optionValues = values.map((option) => option.value);
      return {
        // Async filters' values is the list of option objects.
        values: values,
        isSelectingAll: optionValues.includes(SELECT_ALL_OPTION.value),
        isSelectingEmpty: optionValues.includes(SELECT_EMPTY_OPTION.value),
      };
    }

    if (isDateRange) {
      let dateRangeValues = [];
      if (values && values.from) {
        const { date, time, timezone } = parseDateTime(values.from, true);
        const statusLabel = `${date} ${time} ${timezone}`;
        dateRangeValues.unshift({
          label: `From: ${statusLabel}`,
          value: values.from,
        });
      }
      if (values && values.to) {
        const { date, time, timezone } = parseDateTime(values.to, true);
        const statusLabel = `${date} ${time} ${timezone}`;
        dateRangeValues.unshift({
          label: `To: ${statusLabel}`,
          value: values.to,
        });
      }
      return {
        values: dateRangeValues,
      };
    }

    return {
      // Non-async filters' values is a list of values from the option (e.g. option.value).
      // `getFilterValues` will convert them to full options.
      values: getFilterValues(values, options[key]),
      isSelectingAll: values.includes(SELECT_ALL_OPTION.value),
      isSelectingEmpty: values.includes(SELECT_EMPTY_OPTION.value),
    };
  };

  const getFilterValueLabel = () => {
    const filterLabels = filterKeys
      // Get the state for that key
      .map((key) => getStateForFilter(key))
      // Reduce the array of state value arrays to one array
      .reduce((fullList, { values, isSelectingAll, isSelectingEmpty }) => {
        if (isSelectingAll) {
          return [...fullList, { label: t("components:All") }];
        }

        if (isSelectingEmpty) {
          return [...fullList, { label: t("components:Empty") }, ...values];
        }

        return [...fullList, ...values];
      }, [])
      // Handle fuzzy search option labels
      .map((option) => {
        if (option.fuzzy) {
          return { label: `${t("components:matches")} "${option.label}"` };
        }

        return option;
      })
      // Remove remaining static options (this will most likely just be the "Select Empty" option)
      .filter((option) => !option.static)
      // Take the label from each option
      .map((v) => v.label?.trim())
      .reverse();

    if (filterLabels.length === 0) {
      return null;
    }

    if (filterLabels.length > 3) {
      return `${filterLabels[0]} + ${filterLabels.length - 1}`;
    }

    return filterLabels.join(", ");
  };

  const childrenFilters = filterKeys.map((filterKey, index) => {
    const isFirstFilter = index === 0;
    const currentFilterKey = filterKey;
    const parentFilterKey = isFirstFilter ? null : filterKeys[0];
    const { values: currentValues } = getStateForFilter(currentFilterKey);
    const {
      values: parentFilterValues,
      isSelectingAll: isPreviousSelectingAll,
    } = getStateForFilter(parentFilterKey);

    const requirements = nRequirments[currentFilterKey];
    const requirementsStrategy =
      nRequirmentsStrategy?.[currentFilterKey] ?? RequirementStrategy.ANY;
    let areRequirementsMet = false;
    switch (requirementsStrategy) {
      // As long as one of the values from the requirements is selected
      case RequirementStrategy.ANY:
        areRequirementsMet =
          isPreviousSelectingAll ||
          parentFilterValues?.some((option) =>
            option ? requirements.includes(option.value) : false,
          );
        break;
      // Selected values has to match the requirements exactly
      case RequirementStrategy.MATCH:
        const parentFilterValuesValues = parentFilterValues?.map(
          (option) => option.value,
        );
        areRequirementsMet =
          isPreviousSelectingAll ||
          _.isEqual(parentFilterValuesValues?.sort(), requirements?.sort());
        break;
      default:
        break;
    }

    if (
      !isFirstFilter &&
      !areRequirementsMet &&
      !showAll &&
      currentValues?.length > 0
    ) {
      // TODO: Don't call in the render because it results in a React warning
      // "Warning: Cannot update a component (`ConnectFunction`) while rendering a different component (`NFilterButton`)"
      // When we call `clearSearchFilter`, it updates the FilterSection while we are rendering NFilterButton.
      clearSearchFilter(currentFilterKey);
    }

    const show = showAll || isFirstFilter || areRequirementsMet;
    const isAsyncFilter = nIsAsync[currentFilterKey];
    const label = nLabels[currentFilterKey];
    const isMulti = nIsMulti[currentFilterKey] ?? true;
    const optionsState = nOptionsState[currentFilterKey];
    const isHideFuzzySearch = nHideFuzzySearch[currentFilterKey] ?? false;
    const isHideSelectAll = nHideSelectAll[currentFilterKey] ?? false;
    const isHideSelectEmpty = nHideSelectEmpty[currentFilterKey] ?? false;
    const defaultValue = nDefaultValue?.[currentFilterKey];
    const isDateRange = nIsDateRange?.[currentFilterKey] ?? false;
    // If a sub filter has a required value, it is considered required
    // because for H2-4597, we don't want users to be able to remove a selection.
    // From H2-4597, VinView's VIN Status filter is an example where this is needed.
    const isRequired = !_.isNil(defaultValue);

    if (!show) {
      return null;
    }

    let filterComponent = null;

    if (isDateRange) {
      filterComponent = (
        <div css={{ marginBottom: "3.5em" }}>
          <DateRangeFilterButtonField
            filterKey={currentFilterKey}
            searchFilters={searchFilters[currentFilterKey]}
            setSearchFilter={(_ignoredFilterKey, objects) => {
              setSearchFilter(currentFilterKey, objects);
            }}
            clearSearchFilter={(_ignoredFilterKey) => {
              clearSearchFilter(currentFilterKey);
            }}
          />
        </div>
      );
    } else if (isAsyncFilter) {
      filterComponent = (
        <AsyncSelectFilterButtonField
          filterKey={currentFilterKey}
          isMulti={isMulti}
          values={currentValues}
          optionsState={optionsState}
          setSearchFilter={(_ignoredFilterKey, objects) => {
            setSearchFilter(currentFilterKey, objects);
          }}
          clearSearchFilter={(_ignoredFilterKey) => {
            clearSearchFilter(currentFilterKey);
          }}
          hideFuzzySearch={isHideFuzzySearch}
          hideSelectAll={isHideSelectAll}
          hideSelectEmpty={isHideSelectEmpty}
          required={isRequired}
          defaultValue={defaultValue}
        />
      );
    } else if (isMulti) {
      filterComponent = (
        <MultiSelectFilterButtonField
          filterKey={currentFilterKey}
          options={options[currentFilterKey]}
          searchFilters={searchFilters}
          setSearchFilter={(_ignoredFilterKey, value) => {
            setSearchFilter(currentFilterKey, value);
          }}
          hideFuzzySearch={isHideFuzzySearch}
          hideSelectAll={isHideSelectAll}
          hideSelectEmpty={isHideSelectEmpty}
          required={isRequired}
          defaultValue={defaultValue}
        />
      );
    } else {
      filterComponent = (
        <SelectFilterButtonField
          filterKey={currentFilterKey}
          options={options[currentFilterKey]}
          searchFilters={searchFilters}
          setSearchFilter={(_ignoredFilterKey, value) => {
            setSearchFilter(currentFilterKey, value);
          }}
          clearSearchFilter={(_ignoredFilterKey, value) => {
            clearSearchFilter(currentFilterKey, value);
          }}
          hideSelectEmpty={isHideSelectEmpty}
          required={isRequired}
          defaultValue={defaultValue}
        />
      );
    }

    return (
      <div key={currentFilterKey}>
        {label ? <Text color="black">{label}:</Text> : null}
        {filterComponent}
      </div>
    );
  });

  return (
    <React.Fragment>
      <FilterButton
        {...props}
        filterValue={getFilterValueLabel()}
        clearSearchFilter={clearSearchFilter}
        isNthFilter={true}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            gap: "1em",
          }}
        >
          {childrenFilters}
        </div>
      </FilterButton>
    </React.Fragment>
  );
};

NFilterButton.propTypes = {
  filterKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  searchFilters: PropTypes.object,
  setSearchFilter: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  muliLabels: PropTypes.object,
  nIsAsync: PropTypes.object,
  nOptionsState: PropTypes.object,
  nLabels: PropTypes.object,
  nRequirments: PropTypes.object,
  nIsMulti: PropTypes.object,
  nHideFuzzySearch: PropTypes.object,
  nHideSelectAll: PropTypes.object,
  nHideSelectEmpty: PropTypes.object,
  nDefaultValue: PropTypes.object,
  showAll: PropTypes.bool,
  nIsDateRange: PropTypes.object,
};

const AsyncSelectFilterButton = (props) => {
  const {
    filterKey,
    label,
    clearSearchFilter,
    values,
    optionsState,
    setSearchFilter,
    isMulti = true,
    hideFuzzySearch,
    hideSelectAll,
    hideSelectEmpty,
    selectListSize,
  } = props;

  const { t } = useTranslation("components");
  const { SELECT_ALL_OPTION, SELECT_EMPTY_OPTION } = useSelectOptions();

  // Validate type of `values`.
  // May not be an array because of bugs in development, etc.
  const selectedValues = Array.isArray(values) ? values : [];

  const isSelectingAll = selectedValues
    ?.map(({ value }) => value)
    .includes(SELECT_ALL_OPTION.value);

  const fuzzyValues = selectedValues
    ?.filter((value) => value.fuzzy)
    .map(({ value }) => value.toString().substring(FUZZY_PREFIX.length));

  const numFilters = selectedValues ? selectedValues.length : 0;

  let filterValue = null;

  // For special case, selecting all we display "All"
  if (isSelectingAll) {
    filterValue = t("components:All");
  } else {
    if (numFilters > 0) {
      const firstFilterValue = Object.assign({}, selectedValues[0]);
      if (firstFilterValue.value === SELECT_EMPTY_OPTION.value) {
        filterValue = t("components:Empty");
      }
      // For fuzzy values, we display matches with the values in quotes
      else if (fuzzyValues?.length > 0) {
        filterValue = `${t("components:matches")} "${fuzzyValues.toString()}"`;
      } else {
        filterValue = firstFilterValue.label;
      }
    }

    if (numFilters > 1) {
      if (filterValue && filterValue.length > MAX_FILTER_LABEL_LENGTH - 8) {
        filterValue = `${filterValue.slice(0, MAX_FILTER_LABEL_LENGTH - 8)}…`;
      }

      filterValue = filterValue + ` + ${numFilters - 1}`;
    }
  }

  return (
    <FilterButton
      {...props}
      filterValue={filterValue}
      clearSearchFilter={() => {
        clearSearchFilter(filterKey);
        setSearchFilter(filterKey, []);
      }}
    >
      <AsyncSelectFilterButtonField
        filterKey={filterKey}
        label={label}
        isMulti={isMulti}
        values={selectedValues}
        optionsState={optionsState}
        setSearchFilter={setSearchFilter}
        clearSearchFilter={clearSearchFilter}
        hideFuzzySearch={hideFuzzySearch}
        hideSelectAll={hideSelectAll}
        hideSelectEmpty={hideSelectEmpty}
        selectListSize={selectListSize}
      />
    </FilterButton>
  );
};

AsyncSelectFilterButton.propTypes = {
  filterKey: PropTypes.string,
  label: PropTypes.string,
  clearSearchFilter: PropTypes.func,
  values: PropTypes.array,
  loadOptions: PropTypes.func,
  setSearchFilter: PropTypes.func,
  isMulti: PropTypes.bool,
  optionsState: PropTypes.object,
  hideFuzzySearch: PropTypes.bool,
  hideSelectAll: PropTypes.bool,
  hideSelectEmpty: PropTypes.bool,
  selectListSize: PropTypes.oneOf([
    SelectListSize.REGULAR,
    SelectListSize.LARGE,
  ]),
};

const AsyncSelectFilterButtonField = (props) => {
  const {
    filterKey,
    isMulti,
    label,
    values,
    optionsState,
    setSearchFilter,
    clearSearchFilter,
    hideFuzzySearch,
    hideSelectAll,
    hideSelectEmpty,
    selectListSize,
    required = false,
    defaultValue,
  } = props;

  const dispatch = useDispatch();

  const options = useSelector(optionsState.selectors.getOptions);
  const hasMore = useSelector(optionsState.selectors.getHasMore);
  const totalCountsForFuzzySearch = useSelector(
    optionsState.selectors.getTotalCounts,
  );
  const isLoading = useSelector(optionsState.selectors.getIsLoading);
  const fetchNext = () => dispatch(optionsState.actionCreators.fetchNext());
  const onTextChange = _.debounce((query) => {
    dispatch(optionsState.actionCreators.setQueryText(query));
    dispatch(optionsState.actionCreators.fetchNext());
  }, 500);

  useEffect(() => {
    if (values) {
      let fuzzyOptions = values.filter(
        (selectedOptions) => selectedOptions.fuzzy,
      );
      dispatch(optionsState.actionCreators.initFuzzyOptionsCount(fuzzyOptions));
    }
    //eslint-disable-next-line
  }, []);

  if (isMulti) {
    return (
      <SearchableSelectList
        multi
        async
        name={label}
        selectedOptions={values}
        options={options}
        hasMore={hasMore}
        fetchNext={fetchNext}
        isLoading={isLoading}
        onTextChange={(text) => onTextChange(text)}
        onChange={(nextOptions) => {
          if (nextOptions.length === 0) {
            clearSearchFilter(filterKey);
          } else {
            setSearchFilter(filterKey, nextOptions);
          }
        }}
        totalCountsForFuzzySearch={totalCountsForFuzzySearch}
        maxFuzzySearch={hideFuzzySearch ? 0 : Infinity}
        hideSelectAll={hideSelectAll}
        hideSelectEmpty={hideSelectEmpty}
        selectListSize={selectListSize}
        required={required}
        defaultValue={defaultValue}
      />
    );
  }

  return (
    <SearchableSelectList
      async
      options={options}
      selectedOption={values?.[0]}
      hasMore={hasMore}
      fetchNext={fetchNext}
      isLoading={isLoading}
      onTextChange={(text) => onTextChange(text)}
      onChange={(nextOption) => {
        if (_.isNil(nextOption)) {
          clearSearchFilter(filterKey);
        } else {
          setSearchFilter(filterKey, [nextOption]);
        }
      }}
      hideSelectAll={hideSelectAll}
      hideSelectEmpty={hideSelectEmpty}
      selectListSize={selectListSize}
      required={required}
      defaultValue={defaultValue}
    />
  );
};

AsyncSelectFilterButtonField.propTypes = {
  filterKey: PropTypes.string,
  label: PropTypes.string,
  isMulti: PropTypes.bool,
  values: PropTypes.array,
  optionsState: PropTypes.object,
  setSearchFilter: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  hideFuzzySearch: PropTypes.bool,
  hideSelectAll: PropTypes.bool,
  hideSelectEmpty: PropTypes.bool,
  selectListSize: PropTypes.oneOf([
    SelectListSize.REGULAR,
    SelectListSize.LARGE,
  ]),
  required: PropTypes.bool,
  defaultValue: PropTypes.any,
};

// DEV-767 Add user timezone to time display
const zone = moment.tz.guess();

const DateRangeFilterButton = (props) => {
  const {
    filterKey,
    searchFilters,
    setSearchFilter,
    clearSearchFilter,
    dateTypeOptions = [],
    toggled,
    isValueValid,
  } = props;

  const { t } = useTranslation("components");

  const getLatestProps = useGetLatest(props);
  // This effect does two things:
  // - sets the default dateType checkbox when the filter is opened and there is no value in state.
  // - clears the value if the filter is closed and the value is not valid.
  useEffect(() => {
    const {
      isValueValid,
      clearSearchFilter,
      setSearchFilter,
      filterKey,
      searchFilters,
      dateTypeOptions = [],
    } = getLatestProps();

    const value = _.get(searchFilters, filterKey);

    if (!toggled && !isValueValid(value)) {
      clearSearchFilter(filterKey, true);
    } else if (toggled && _.isNil(value)) {
      // Set default
      setSearchFilter(
        filterKey,
        {
          dateType: dateTypeOptions
            .filter((option) => option.default)
            .map((option) => option.value),
        },
        true,
      );
    }
  }, [toggled, getLatestProps]);

  const value = _.get(searchFilters, filterKey);
  const fromValue = value?.from ?? null;
  const toValue = value?.to ?? null;
  const fromValueDate = fromValue ? new Date(fromValue) : null;
  const toValueDate = toValue ? new Date(toValue) : null;
  const dateFormat = getDateFormatFromUserPreferences(); // : "l"
  const timeFormat = getTimeFormatFromUserPreferences();
  const dateTimeFormat = dateFormat + " " + timeFormat;

  // Find the selected checkbox options
  const selectedCheckboxOptions = dateTypeOptions.filter((option) => {
    return value?.dateType?.includes(option.value) ?? false;
  });

  let filterLabel = null;
  let filterText =
    selectedCheckboxOptions.length > 0 ? (
      <strong>
        {selectedCheckboxOptions.length > 1
          ? `${selectedCheckboxOptions[0].label} + ${
              selectedCheckboxOptions.length - 1
            }`
          : selectedCheckboxOptions[0].label}
      </strong>
    ) : null;
  if (fromValue && !toValue) {
    filterLabel = (
      <div>
        {filterText}
        <strong> From: {moment(fromValue).format(dateFormat)} </strong>
        {moment(fromValue).format(` ${timeFormat} `)}
        {moment().tz(zone).format("z")}
      </div>
    );
  } else if (toValue && !fromValue) {
    filterLabel = (
      <div>
        {filterText}
        <strong> To: {moment(toValue).format(dateFormat)} </strong>
        {moment(toValue).format(` ${timeFormat} `)}
        {moment().tz(zone).format("z")}
      </div>
    );
  } else if (toValue && fromValue) {
    filterLabel = (
      <div>
        {filterText}
        <strong> {moment(fromValue).format(dateFormat)} </strong>
        {moment(fromValue).format(` ${timeFormat} `)}
        {moment().tz(zone).format("z ")}-{" "}
        <strong> {moment(toValue).format(dateFormat)} </strong>
        {moment(toValue).format(` ${timeFormat} `)}
        {moment().tz(zone).format("z")}
      </div>
    );
  }

  // Don't use setSearchFilter directly! Use this function to update the value in redux.
  const updateFilterValue = (value = {}) => {
    // If there are no values to store in state, clear the filter.
    // This ensures an "empty" value will be undefined.
    // instead of `{ dateType: [] }`.
    if (value.dateType?.length <= 0 && !value.to && !value.from) {
      clearSearchFilter(filterKey, !isValueValid(value));
    } else {
      setSearchFilter(filterKey, value, !isValueValid(value));
    }
  };

  const handleDateTypeCheckChange = (dateType, checked) => {
    // Local var so we can update the list.
    let dateTypes = value?.dateType ? [...value?.dateType] : [];

    // If its checked, ensure the dateType is in the list.
    // Otherwise, remove it.
    if (checked) {
      if (!dateTypes.includes(dateType)) {
        dateTypes.push(dateType);
      }
    } else {
      dateTypes = dateTypes.filter((type) => type !== dateType);
    }

    updateFilterValue({
      ...value,
      dateType: dateTypes,
    });
  };

  const onToDateChange = (date) => {
    updateFilterValue({
      ...value,
      from: date,
    });
  };

  const onFromDateChange = (date) => {
    updateFilterValue({
      ...value,
      to: date,
    });
  };

  return (
    <FilterButton
      {...props}
      clearSearchFilter={clearSearchFilter}
      filterValue={filterLabel}
    >
      {dateTypeOptions?.length > 0 ? (
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "1.5rem",
          }}
        >
          {dateTypeOptions.map((option) => {
            // This ID will pair the <label> and input together.
            // This allows the user to click the label to cause the onChange
            // for the input (Checkbox).
            const htmlId = `${filterKey}_${option.value}`;
            return (
              <label
                key={option.value}
                htmlFor={htmlId}
                css={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingRight: 10,
                  userSelect: "none",
                  cursor: "pointer",
                }}
              >
                <Checkbox
                  id={htmlId}
                  color={Colors.filters.CHECK_DEFAULT_COLOR}
                  checkedColor={Colors.filters.CHECK_SELECTED_COLOR}
                  disabledColor={Colors.text.DISABLED}
                  checked={selectedCheckboxOptions.includes(option)}
                  onChange={(checked) =>
                    handleDateTypeCheckChange(option.value, checked)
                  }
                />
                {option.label}
              </label>
            );
          })}
        </div>
      ) : null}
      <div css={{ marginBottom: "1em" }}>
        <Text>{t("components:From")}:</Text>
        <DateTimePicker
          style={{ minWidth: "17em" }}
          defaultValue={fromValueDate}
          // H1-504: Pick up date and delivery date filters time should default to 0:00.
          defaultCurrentDate={moment().startOf("day").toDate()}
          max={toValueDate ?? undefined}
          format={dateTimeFormat}
          timeFormat={timeFormat}
          onChange={onToDateChange}
        />
      </div>
      <div>
        <Text>{t("components:To")}:</Text>
        <DateTimePicker
          // Force a different instance of the "To" input when
          // the "From" value changes so that the new default
          // values are taken into account.
          // Since both inputs render at the same time,
          // the updated defaults aren't used unless the component remounts.
          key={fromValueDate}
          style={{ minWidth: "17em" }}
          defaultValue={toValueDate}
          defaultCurrentDate={
            // Default is set to what "From" is since
            // we can't select datetimes before this.
            fromValueDate ??
            // H1-504: Pick up date and delivery date filters time should default to 0:00.
            moment().startOf("day").toDate()
          }
          min={fromValueDate ?? undefined}
          format={dateTimeFormat}
          timeFormat={timeFormat}
          onChange={onFromDateChange}
        />
      </div>
    </FilterButton>
  );
};

DateRangeFilterButton.propTypes = {
  filterKey: PropTypes.string,
  searchFilters: PropTypes.object,
  setSearchFilter: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  dateTypeOptions: PropTypes.array,
  toggled: PropTypes.bool,
  isValueValid: PropTypes.func,
};

const DateRangeFilterButtonField = (props) => {
  const { filterKey, searchFilters, setSearchFilter, clearSearchFilter } =
    props;

  const { t } = useTranslation("components");
  const [stateFromValue, setStateFromValue] = useState(
    searchFilters?.from ?? null,
  );
  const [stateToValue, setStateToValue] = useState(searchFilters?.to ?? null);
  const fromValueDate = stateFromValue ? new Date(stateFromValue) : null;
  const toValueDate = stateToValue ? new Date(stateToValue) : null;
  const dateFormat = getDateFormatFromUserPreferences();
  const timeFormat = getTimeFormatFromUserPreferences();
  const dateTimeFormat = dateFormat + " " + timeFormat;
  useEffect(() => {
    updateFilterValue();
  }, [stateFromValue, stateToValue]);

  useEffect(() => {
    // Disable the input box on date picker and time pickers
    const widgetInputs = document.querySelectorAll(".rw-widget-input");
    widgetInputs.forEach((widget) => {
      widget.setAttribute("readonly", true);
    });
  });

  const updateFilterValue = (value = {}) => {
    if (value.dateType?.length <= 0 && !value.to && !value.from) {
      clearSearchFilter(filterKey);
    } else {
      setSearchFilter(filterKey, { from: stateFromValue, to: stateToValue });
    }
  };

  return (
    <React.Fragment>
      <div css={{ marginBottom: "1em" }}>
        <Text>{t("components:From")}:</Text>
        <DateTimePicker
          style={{ minWidth: "17em" }}
          defaultValue={fromValueDate}
          defaultCurrentDate={moment().startOf("day").toDate()}
          max={toValueDate ?? undefined}
          onChange={(val) => {
            setStateFromValue(val);
          }}
          format={dateTimeFormat}
          timeFormat={timeFormat}
        />
      </div>
      <div>
        <Text>{t("components:To")}:</Text>
        <DateTimePicker
          key={fromValueDate}
          style={{ minWidth: "17em" }}
          defaultValue={toValueDate}
          defaultCurrentDate={fromValueDate ?? moment().startOf("day").toDate()}
          min={fromValueDate ?? undefined}
          onChange={(val) => {
            setStateToValue(val);
          }}
          format={dateTimeFormat}
          timeFormat={timeFormat}
        />
      </div>
    </React.Fragment>
  );
};

DateRangeFilterButtonField.propTypes = {
  filterKey: PropTypes.string,
  searchFilters: PropTypes.object,
  setSearchFilter: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  isValueValid: PropTypes.func,
};

// Button to toggle Batch Search filter modal (allows user to upload CSV or enter line-separated filter values)
const BatchFilterButton = (props) => {
  const {
    filterKey,
    label,
    options,
    values,
    setSearchFilter,
    clearSearchFilter,
    isFilterContainerVisible,
    onSearchClick,
  } = props;

  const [showBatchModal, setShowBatchModal] = useState(false);
  useEffect(() => {
    setShowBatchModal(showBatchModal);
  }, [showBatchModal]);

  return (
    <div>
      <div
        css={{
          display: isFilterContainerVisible ? "flex" : "none",
          alignItems: "center",
          height: "2em",
          padding: "0 0.5em",
          border: values ? "1px solid " + Colors.background.DARK_GRAY : "",
          borderRadius: "1em",
          backgroundColor: values ? Colors.background.DARK_GRAY : "transparent",
          marginRight: "1.5em",
          marginBottom: "0.5em",
          position: "absolute",
          right: 0,
          bottom: 0,
          cursor: "pointer",
        }}
      >
        <div
          onClick={() => setShowBatchModal(!showBatchModal)}
          className="batch-search-button"
          css={{
            fontSize: 12.5,
            fontStyle: "italic",
            display: "flex",
            alignItems: "center",
            color: values ? "white" : Colors.background.DARK_GRAY,
            textDecoration: values ? "none" : "underline",
          }}
          data-qa="button-batch-search"
        >
          {label}
        </div>
        {values ? (
          <div
            onClick={(e) => {
              clearSearchFilter(filterKey);
            }}
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              color: Colors.background.DARK_GRAY,
              marginLeft: "5px",
              borderRadius: "1em",
              width: "1em",
              height: "1em",
              ":hover": {
                backgroundColor: Colors.highlight.RED,
              },
            }}
          >
            <MdClose />
          </div>
        ) : null}
      </div>
      <BatchFilterModal
        batchType={values?.batch_type}
        batchList={values?.batch_list}
        show={showBatchModal}
        hide={() => setShowBatchModal(false)}
        searchTypeOptions={options}
        onSubmit={(data) => {
          setSearchFilter(filterKey, data);
          onSearchClick();
        }}
      />
    </div>
  );
};

BatchFilterButton.propTypes = {
  filterKey: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  values: PropTypes.shape({
    batch_type: PropTypes.string,
    batch_list: PropTypes.string,
  }),
  setSearchFilter: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  isFilterContainerVisible: PropTypes.bool,
  onSearchClick: PropTypes.func,
};

// Filter control that displays a single-line text input when clicked
const TextFilterButton = (props) => {
  const {
    filterKey,
    searchFilters,
    setSearchFilter,
    clearSearchFilter,
    closeFilter,
  } = props;
  const currentFilterValue = searchFilters[filterKey];
  const [text, setText] = useState("");
  // Update state when the filter value actually changes
  useEffect(() => {
    setText(currentFilterValue || "");
  }, [currentFilterValue]);
  return (
    <FilterButton
      {...props}
      filterValue={searchFilters[filterKey] || null}
      clearSearchFilter={clearSearchFilter}
    >
      <input
        type="text"
        className="form-control"
        style={{ minWidth: "17em" }}
        name="text-filter"
        value={text}
        // Update state when the user enters text
        onChange={(event) => setText(event.target.value)}
        // When user moves focus from this input, set the filter value to the text in state
        onBlur={(event) => setSearchFilter(filterKey, text)}
        // When user hits Enter, set the filter value to the text in state
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            setSearchFilter(filterKey, text);
            closeFilter(filterKey);
          }
        }}
      />
    </FilterButton>
  );
};

TextFilterButton.propTypes = {
  filterKey: PropTypes.string,
  searchFilters: PropTypes.object,
  setSearchFilter: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  closeFilter: PropTypes.func,
};

export {
  NFilterButton,
  SelectFilterButton,
  MultiSelectFilterButton,
  AsyncSelectFilterButton,
  DateRangeFilterButton,
  BatchFilterButton,
  TextFilterButton,
};
