import _ from "lodash";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import { combineReducers } from "redux";

import apiUrl from "api-url";

export const getBaseFilterUrl = (filterType) => {
  return apiUrl(`/damageview/submission/filters?filterType=${filterType}`);
};
export const assigneesUrl = () => {
  return getBaseFilterUrl("assignee");
};

export const partnersUrl = () => {
  return getBaseFilterUrl("partner");
};

export const currentPositionCodesUrl = () => {
  return getBaseFilterUrl("atLocation");
};

export const productTypesUrl = () => {
  return apiUrl(`/damageview/submission/filters?filterType=productType`);
};

export const damageAreaUrl = () => {
  return apiUrl(`/damageview/submission/filters?filterType=damageArea`);
};

export const lastMilestoneUrl = () => {
  return apiUrl(`/damageview/submission/filters?filterType=lastMilestone`);
};

const STORE_MOUNT_POINT = "damageViewDomainData";

const damageViewPositionTypesDuck = buildFetchDuck(
  "damageViewPositionTypesDuck",
);
const damageViewStatusDuck = buildFetchDuck("damageViewStatusDuck");
const damageViewInProgressStatusDuck = buildFetchDuck(
  "damageViewInProgressStatusDuck",
);

export function fetchDomainData(solutionId) {
  const urlPositionTypes = solutionId
    ? apiUrl(
        `/entity-search/solution/${solutionId}/list?currentPositionTypes=1`,
      )
    : apiUrl(`/entity-search/list?currentPositionTypes=1`);

  const statusUrl = getBaseFilterUrl("status");
  const inProgressStatusUrl = getBaseFilterUrl("inProgressStatus");

  return (dispatch) => {
    dispatch(damageViewPositionTypesDuck.fetch(urlPositionTypes));
    dispatch(damageViewStatusDuck.fetch(statusUrl));
    dispatch(damageViewInProgressStatusDuck.fetch(inProgressStatusUrl));
  };
}

/**
 * Given a property name, this function knows how to get its related data from
 * the fv domain mount point in the store.
 *
 * @param string property: property you want to get from the state.
 * @param string sort: key you want to sort on the return.
 * @param boolean sort: if there is no key, a true value will mean: sort it for
 * me.
 *
 * It avoids code duplication to create selectors based on the same data
 * source.
 */
const createSelectorFromProperty = (property, parent, sort = false) => {
  return (state) => {
    let dataFromState = state[STORE_MOUNT_POINT][parent]?.data[property];
    if (dataFromState) {
      if (sort) {
        // Sorting
        if (_.isString(sort)) {
          return _.sortBy(dataFromState, sort);
        }

        // Check if the data is prone to sort. In this case, the only possible
        // type which comes from the backend and is not sortable is object
        if (_.isObject(dataFromState[0])) {
          throw Error(
            `Property ${property} contains objects that can not be sorted`,
          );
        }

        return dataFromState.sort();
      }
      return dataFromState;
    }
    return [];
  };
};

const getCurrentPositionTypes = createSelectorFromProperty(
  "currentPositionTypes",
  "currentPositionTypes",
);

const getStatus = createSelectorFromProperty("data", "statusData");
const getInProgressStatus = createSelectorFromProperty(
  "data",
  "inProgressStatusData",
);

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getCurrentPositionTypes,
    getStatus,
    getInProgressStatus,
  },
  reducer: combineReducers({
    currentPositionTypes: damageViewPositionTypesDuck.reducer,
    statusData: damageViewStatusDuck.reducer,
    inProgressStatusData: damageViewInProgressStatusDuck.reducer,
  }),
};
