/** @jsxImportSource @emotion/react */
//External Pacakges
import styled from "@emotion/styled";
import PropTypes from "prop-types";

//External hooks or supporting code from React
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Internal hooks
import { useSetTitleOnMount } from "components/hooks/useSetTitle";

//Components
import { DownloadDataButton } from "components/molecules/DownloadDataButton.molecule";
import { SaveThisSearchButton } from "components/saved-search/SaveThisSearchButton";
import { ResetSearchButton } from "components/saved-search/ResetSearchButton";
import {
  ButtonSeparator,
  SearchButtonContainer,
} from "components/saved-search/ButtonSeparator";
import { isValidFilter } from "components/search-bar/search-filter-query-strings";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import ExportModal from "modules/exports/ExportModal";
import { ExportAlert } from "modules/exports/ExportAlert";
import EditSavedSearchModalContainer from "pages/shipments/components/search/Shipments.EditSavedSearchModal.container";
import SearchBarContainer from "pages/shipments/components/search/Shipments.SearchBar.container";
import FiltersContainer from "pages/shipments/components/search/Shipments.SearchFilters.container";
import { getColumns } from "./Shipments.Search.columns";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import Colors from "styles/colors";

const Section = styled.section({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "0 1em 1em 1em",
});

export const SearchResultsTable = ({
  totalPages,
  page,
  pageSize,
  sortColumn,
  reverseSort,
  shipments,
  columns,
  isLoading,
  setPagination,
  setSort,
  pushShipmentDetailView,
}) => {
  const rowClickHandler = (row, cell) => {
    if (!row || !cell) {
      return;
    }

    // Prevent navigation if clicking in "Watch" columns.
    if (cell.column.id === "ACTIONS_COLUMN") {
      return;
    }

    // Navigate to Shipment Details when clicking row
    pushShipmentDetailView(row.original.id);
  };

  return (
    <BaseTable
      data-qa="shipments-search-results"
      data={shipments}
      columns={columns}
      isLoading={isLoading}
      showPagination={true}
      isManualPagination={true}
      isManualSorting={true}
      pageIndex={page}
      pageCount={totalPages}
      pageSize={pageSize}
      defaultSortColumn="destination_earliest_arrival"
      defaultReverseSort={false}
      sortColumn={sortColumn}
      reverseSort={reverseSort}
      onPageChange={(newPage) => {
        setPagination(null, newPage, pageSize);
      }}
      onPageSizeChange={(newPageSize) => {
        setPagination(null, 0, newPageSize);
      }}
      onPageSort={(column, isReverse) => {
        setSort(null, column, isReverse);
      }}
      fixPaginationToBottom={true}
      rowClickHandler={rowClickHandler}
    />
  );
};

SearchResultsTable.propTypes = {
  totalPages: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  shipments: PropTypes.array,
  columns: PropTypes.array,
  isLoading: PropTypes.bool,
  setPagination: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  pushShipmentDetailView: PropTypes.func.isRequired,
};

export const ShipmentsSearchView = ({
  totalShipments,
  searchFilters,
  searchEntities,
  shipments,
  isLoading,
  exportIdentifier,
  exportName,
  isExporting,
  exportFailed,
  page,
  pageSize,
  totalPages,
  sortColumn,
  reverseSort,
  setPagination,
  setSort,
  resetSearch,
  exportAllShipments,
  showFilters,
  toggleShowFilters,
  pushShipmentDetailView,
  resetExport,
}) => {
  const { t } = useTranslation("shipment-search");

  useSetTitleOnMount(t("shipment-search:Search Results"));
  useTrackWithMixpanelOnce("Viewed Page: Shipments / Search");

  const [totalFilters, setTotalFilters] = useState(0);
  const [showEditSavedSearchModal, setShowEditSavedSearchModal] =
    useState(false);

  // When we've finished loading a search, update the filter count
  useEffect(() => {
    let numFilters = 0;
    Object.keys(searchFilters).forEach((f) => {
      const filterValue = searchFilters[f];
      if (isValidFilter(filterValue)) {
        numFilters++;
      }
    });

    setTotalFilters(numFilters);

    // Note: We only want this effect to run when loading is complete.
    // We don't want this to run when searchFilters changes because then the text
    // displayed implies the current results are filtered, even though we haven't hit Search yet.
    // Eg: Viewing 400 shipments with 1 filter
    //eslint-disable-next-line
  }, [isLoading]);

  const columns = getColumns({
    t,
    onActionFinished: () => {
      searchEntities(null, false, true);
    },
  });

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div css={{ marginTop: "1em", marginLeft: "1em", marginRight: "1em" }}>
        <SearchBarContainer
          isShowingFilters={showFilters}
          toggleShowFilters={() => toggleShowFilters(!showFilters)}
        />
        <FiltersContainer
          show={showFilters}
          backgroundColor={Colors.background.GRAY}
          sectionStyle={{ paddingRight: "8em" }}
        />
        <ExportAlert exportFailed={exportFailed} className="my-2" />
      </div>
      <Section>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            css={{
              flexDirection: "row",
              color: Colors.text.GRAY,
              marginTop: "0.5em",
              marginBottom: "0.5em",
            }}
          >
            {t("shipment-search:Viewing")}{" "}
            <span
              css={{ color: Colors.background.GRAY_BLUE, fontWeight: "bold" }}
              data-qa="total-search-results"
            >{`${totalShipments} ${t("shipment-search:shipments")}`}</span>
            {totalFilters > 0 && ` ${t("shipment-search:with")} `}
            {totalFilters > 0 && (
              <span
                css={{ color: Colors.background.GRAY_BLUE, fontWeight: "bold" }}
              >{`${totalFilters} ${
                totalFilters > 1
                  ? t("shipment-search:filters")
                  : t("shipment-search:filter")
              }`}</span>
            )}
          </div>
          <SearchButtonContainer>
            <ResetSearchButton onClick={() => resetSearch()} />
            <ButtonSeparator />
            <SaveThisSearchButton
              onClick={() => setShowEditSavedSearchModal(true)}
            />
            <ButtonSeparator />
            <DownloadDataButton
              onClick={() => {
                exportAllShipments(t);
              }}
              isExporting={isExporting}
              isDisabled={shipments.length === 0}
              data-qa="button-search-results-export"
            />
          </SearchButtonContainer>
        </div>
        <SearchResultsTable
          totalPages={totalPages}
          page={page}
          pageSize={pageSize}
          sortColumn={sortColumn}
          reverseSort={reverseSort}
          shipments={shipments}
          columns={columns}
          isLoading={isLoading}
          setPagination={setPagination}
          setSort={setSort}
          pushShipmentDetailView={pushShipmentDetailView}
        />
        <EditSavedSearchModalContainer
          show={showEditSavedSearchModal}
          hide={() => setShowEditSavedSearchModal(false)}
        />
        <ExportModal
          exportIdentifier={exportIdentifier}
          exportName={exportName}
          resetExport={resetExport}
        />
      </Section>
    </div>
  );
};

ShipmentsSearchView.propTypes = {
  exportAllShipments: PropTypes.func.isRequired,
  exportIdentifier: PropTypes.string,
  isExporting: PropTypes.bool,
  exportFailed: PropTypes.bool,
  searchEntities: PropTypes.func,
  isLoading: PropTypes.bool,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  exportName: PropTypes.string,
  pushShipmentDetailView: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  searchFilters: PropTypes.object,
  setPagination: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  shipments: PropTypes.any,
  showFilters: PropTypes.any,
  toggleShowFilters: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  totalShipments: PropTypes.number,
  resetExport: PropTypes.func,
};
