import {
  getBasicQueryString,
  getDateRangeQueryString,
  getMultiSelectQueryString,
  getLocationQueryString,
} from "components/search-bar/search-filter-query-strings";
import {
  DateRangeFilterButton,
  AsyncSelectFilterButton,
} from "components/search-bar/FilterButtons";
import { isDateRangeValueValid } from "components/search-bar/search-filter-validators";
import {
  dealerOriginStateOptionsState,
  dealerDestinationStateOptionsState,
  dealerPartNumberOptionsState,
  dealerPackageStatusOptionsState,
  orderPriorityOptionsState,
  dealerPVOrgOptionsState,
} from "pages/partview/redux/DealerPartViewSearchFilterLoaders";
import { OrganizationFilterButton } from "./OrganizationFilterButton.molecule";
import { Features, Privileges } from "modules/auth/Authorization";
import { OrganizationType } from "shared/constants/organization.const";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "partNumber:contains",
    label: (t) => t("partview-search:Part #"),
    placeholder: (t) => t("partview-search:Search Part #"),
    queryBuilder: getBasicQueryString,
    loaderProperty: "fetchPartNumberOptions",
    property: "partNumberOptions",
    loadingProperty: "isPartNumberOptionsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value",
  },
  {
    queryKey: "orderNumber:contains",
    label: (t) => t("partview-search:Order #"),
    placeholder: (t) => t("partview-search:Search Order #"),
    queryBuilder: getBasicQueryString,
    loaderProperty: "fetchOrderNumberOptions",
    property: "orderNumberOptions",
    loadingProperty: "isOrderNumberOptionsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value",
  },
  {
    queryKey: "shipmentId:contains",
    label: (t) => t("partview-search:Shipment ID"),
    placeholder: (t) => t("partview-search:Search Shipment ID"),
    queryBuilder: getBasicQueryString,
    loaderProperty: "fetchShipmentIdOptions",
    property: "shipmentIdOptions",
    loadingProperty: "isShipmentIdOptionsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value",
  },
  {
    queryKey: "trackingNumber:contains",
    label: (t) => t("partview-search:Package #"),
    placeholder: (t) => t("partview-search:Search Package #"),
    queryBuilder: getBasicQueryString,
    loaderProperty: "fetchPackageNumberOptions",
    property: "packageNumberOptions",
    loadingProperty: "isPackageNumberOptionsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value",
  },
  {
    queryKey: "billOfLading:contains",
    label: (t) => t("partview-search:Bill of Lading"),
    placeholder: (t) => t("partview-search:Search Bill of Lading"),
    queryBuilder: getBasicQueryString,
    loaderProperty: "fetchBillOfLadingOptions",
    property: "billOfLadingOptions",
    loadingProperty: "isBillOfLadingOptionsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value",
  },
];

export const FILTERS = [
  {
    queryKey: "fvOrgId",
    label: (t) => t("partview-search:Organization"),
    Component: OrganizationFilterButton,
    optionsState: dealerPVOrgOptionsState,
    queryBuilder: getMultiSelectQueryString,
    // PartViewSeller with the PartView feature will need this fitler.
    requiredOrgTypes: [OrganizationType.SHIPPER],
    privileges: [Privileges.VIEW_PARTVIEW_SELLER],
    requiredFeatures: [Features.PART_VIEW],
    // Prevents clearing this filter when we clear all filters.
    onlyUserClearable: true,
    // Prevent selecting other filters before this one is selected.
    prerequisiteForSearchAndFilters: true,
  },
  {
    queryKey: "origin",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("partview-search:Origin"),
    optionsState: dealerOriginStateOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getLocationQueryString("originCode", filterValue, {
        transformValue: (code) => `"${code}"`,
      }),
    hideFuzzySearch: true,
  },
  {
    queryKey: "destination",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("partview-search:Destination"),
    optionsState: dealerDestinationStateOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getLocationQueryString("destinationCode", filterValue, {
        transformValue: (code) => `"${code}"`,
      }),
    hideFuzzySearch: true,
    hidden: (props) => !props.showDestinationFilter,
  },

  {
    queryKey: "partNumber",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("partview-search:Parts"),
    optionsState: dealerPartNumberOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: "lifecycleState",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("partview-search:Package Status"),
    optionsState: dealerPackageStatusOptionsState,
    queryBuilder: getMultiSelectQueryString,
    hideFuzzySearch: true,
  },
  {
    queryKey: "deliveryDate",
    label: (t) => t("partview-search:Delivery Date"),
    Component: DateRangeFilterButton,
    queryBuilder: (queryParameter, filterValue) => {
      return getDateRangeQueryString(queryParameter, filterValue, {
        convertToUtc: true,
      });
    },
    dateTypeOptions: (t) => {
      let dateOptions = [
        { label: t("partview-search:Actual"), value: "actual" },
        { label: t("partview-search:ETA"), value: "eta" },
      ];

      return dateOptions;
    },
    isValueValid: isDateRangeValueValid,
  },
  {
    queryKey: "orderDate",
    label: (t) => t("partview-search:Order Date"),
    Component: DateRangeFilterButton,
    queryBuilder: getDateRangeQueryString,
  },
];
