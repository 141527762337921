import i18next from "i18next";
import moment from "moment-timezone";
import _ from "lodash";
import {
  AsyncSelectFilterButton,
  NFilterButton,
  BatchFilterButton,
} from "components/search-bar/FilterButtons";
import {
  getBasicQueryString,
  getBasicWithStaticOptionsQueryString,
  getLocationQueryString,
  getMultiSelectQueryString,
  getNQueryStringFilterValuePriority,
  getStatusEventTimeDateRangeQueryString,
  getEmptyQueryString,
} from "components/search-bar/search-filter-query-strings";
import { batchCsvVinExample } from "components/search-bar/batch-comment-csv-data";
import {
  driveAwayLocationsOptionsState,
  driveAwayCustomersOptionsState,
  driveAwayDealersOptionsState,
  driveAwayStatusOptionsState,
  driveAwayDealerRegionOptionsState,
  driveAwayDealerZoneOptionsState,
  driveAwayDealerDistrictOptionsState,
  driveAwayProductTypeOptionsState,
  lastMilestoneOptionsState,
} from "pages/driveaway/redux/DriveAwaySearchFilterLoaders";

import { OrganizationType } from "shared/constants/organization.const";
import { RequirementStrategy } from "components/search-bar/enums.utils";
import { DateRangeFilterButton } from "../../../../components/search-bar/FilterButtons";
import { DPUStatus } from "pages/driveaway/utils/enums.utils";

// Load the namesapce for labels
i18next.loadNamespaces("driveaway-search");

export const SEARCH_CATEGORIES = [
  {
    queryKey: "vin",
    label: (t) => t("driveaway-search:VIN"),
    placeholder: (t) => t("driveaway-search:Search VIN"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("entityId", filterValue),
    loaderProperty: "searchVins",
    property: "vins",
    loadingProperty: "vinsLoading",
  },
];

export const FILTERS = [
  {
    queryKey: "ddaLocation",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("driveaway-search:DPU Location"),
    optionsState: driveAwayLocationsOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getLocationQueryString("ddaLocation", filterValue, { valueKey: "code" }),
    hideSelectEmpty: true,
    hideFuzzySearch: true,
  },
  // Status filter for shipper and carrier
  {
    queryKey: ["ddaStatus", "ddaTimeWindow", "agingDuration"],
    label: (t) => t("driveaway-search:Status"),
    Component: NFilterButton,
    nIsMulti: {
      ddaStatus: true,
      ddaTimeWindow: false,
      agingDuration: false,
    },
    nIsAsync: {
      ddaStatus: false,
      ddaTimeWindow: false,
      agingDuration: false,
    },
    nIsDateRange: {
      ddaStatus: false,
      ddaTimeWindow: true,
      agingDuration: false,
    },
    nOptionsState: {
      ddaStatus: driveAwayStatusOptionsState,
      ddaTimeWindow: null,
      agingDuration: null,
    },
    nLabels: {
      ddaStatus: null,
      ddaTimeWindow: "Pick Up Window Time",
      agingDuration: "Aging Duration",
    },
    nRequirments: {
      ddaTimeWindow: [DPUStatus.APPROVED],
      agingDuration: [DPUStatus.SUBMITTED],
    },
    nRequirmentsStrategy: {
      ddaTimeWindow: RequirementStrategy.MATCH,
      agingDuration: RequirementStrategy.MATCH,
    },
    // Hides fuzzy search options
    nHideFuzzySearch: {
      ddaStatus: true,
      ddaTimeWindow: true,
      agingDuration: true,
    },
    // Hides select all option
    nHideSelectAll: {
      ddaStatus: true,
      ddaTimeWindow: true,
      agingDuration: true,
    },
    // Hides select empty option
    nHideSelectEmpty: {
      ddaStatus: true,
      ddaTimeWindow: true,
      agingDuration: true,
    },
    //Showall filters with no requirments or contraints
    showAll: false,
    optionsGetter: (props, t) => {
      return {
        ddaStatus: props.statusStateFilterOptions ?? "",
        agingDuration: [
          { label: t(">24 Hours"), value: "24" },
          { label: t(">48 Hours"), value: "48" },
          { label: t(">72 Hours"), value: "72" },
          { label: t(">96 Hours"), value: "96" },
        ],
      };
    },
    queryBuilder: (queryKeys, filterValues) => {
      const queryFilterValues = {};
      if (filterValues?.ddaStatus) {
        queryFilterValues.ddaStatus = filterValues.ddaStatus;
      }
      if (filterValues?.agingDuration) {
        queryFilterValues.agingDuration = filterValues.agingDuration;
      }
      if (filterValues?.ddaTimeWindow?.from) {
        queryFilterValues.ddaPickupWindowStart = moment(
          filterValues.ddaTimeWindow.from,
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      if (filterValues?.ddaTimeWindow?.to) {
        queryFilterValues.ddaPickupWindowEnd = moment(
          filterValues.ddaTimeWindow.to,
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      return getNQueryStringFilterValuePriority(
        [
          "ddaStatus",
          "ddaPickupWindowStart",
          "ddaPickupWindowEnd",
          "agingDuration",
        ],
        queryFilterValues,
      );
    },
    requiredOrgTypes: [OrganizationType.CARRIER, OrganizationType.SHIPPER],
  },
  // Status filter for dealer
  {
    queryKey: ["ddaStatus", "ddaTimeWindow"],
    label: (t) => t("driveaway-search:Status"),
    Component: NFilterButton,
    nIsMulti: {
      ddaStatus: true,
      ddaTimeWindow: false,
    },
    nIsAsync: {
      ddaStatus: false,
      ddaTimeWindow: false,
    },
    nIsDateRange: {
      ddaStatus: false,
      ddaTimeWindow: true,
    },
    nOptionsState: {
      ddaStatus: driveAwayStatusOptionsState,
      ddaTimeWindow: null,
    },
    nLabels: {
      ddaStatus: null,
      ddaTimeWindow: "Pick Up Window Time",
    },
    nRequirments: {
      ddaTimeWindow: ["Approved"],
    },
    nRequirmentsStrategy: {
      ddaTimeWindow: RequirementStrategy.MATCH,
    },
    // Hides fuzzy search options
    nHideFuzzySearch: {
      ddaStatus: false,
      ddaTimeWindow: true,
    },
    // Hides select all option
    nHideSelectAll: {
      ddaStatus: true,
      ddaTimeWindow: true,
    },
    // Hides select empty option
    nHideSelectEmpty: {
      ddaStatus: true,
      ddaTimeWindow: true,
    },
    //Showall filters with no requirments or contraints
    showAll: false,
    optionsGetter: (props, t) => {
      return {
        ddaStatus: props.statusStateFilterOptions ?? "",
      };
    },
    queryBuilder: (queryKeys, filterValues) => {
      const queryFilterValues = {};
      if (filterValues?.ddaStatus) {
        queryFilterValues.ddaStatus = filterValues.ddaStatus;
      }
      if (filterValues?.ddaTimeWindow?.from) {
        queryFilterValues.ddaPickupWindowStart = moment(
          filterValues.ddaTimeWindow.from,
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      if (filterValues?.ddaTimeWindow?.to) {
        queryFilterValues.ddaPickupWindowEnd = moment(
          filterValues.ddaTimeWindow.to,
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      return getNQueryStringFilterValuePriority(
        ["ddaStatus", "ddaPickupWindowStart", "ddaPickupWindowEnd"],
        queryFilterValues,
      );
    },
    requiredOrgTypes: [OrganizationType.DEALER],
  },
  {
    queryKey: "ddaCarrierId",
    label: (t) => t("driveaway-search:Carrier"),
    optionsGetter: (props) => props.carrierFilterOptions,
    queryBuilder: getBasicWithStaticOptionsQueryString,
    requiredOrgTypes: [OrganizationType.DEALER, OrganizationType.SHIPPER],
    hideSelectEmpty: true,
    hideFuzzySearch: true,
  },
  {
    queryKey: "ddaDealerId",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("driveaway-search:Dealer"),
    optionsState: driveAwayDealersOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString("ddaDealerId", filterValue),
    requiredOrgTypes: [OrganizationType.CARRIER, OrganizationType.SHIPPER],
    hideSelectEmpty: true,
    hideFuzzySearch: true,
  },
  {
    queryKey: "ddaCustomerId",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("driveaway-search:Customer"),
    optionsState: driveAwayCustomersOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString("ddaCustomerId", filterValue),
    requiredOrgTypes: [OrganizationType.CARRIER],
    hideSelectEmpty: true,
    hideFuzzySearch: true,
  },
  {
    queryKey: "ddaDealerRegion",
    Component: AsyncSelectFilterButton,
    label: (t) => t("driveaway-search:Dealer Region"),
    isMulti: true,
    optionsState: driveAwayDealerRegionOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
    requiredOrgTypes: [OrganizationType.SHIPPER],
    hideSelectEmpty: true,
    hideFuzzySearch: true,
  },
  {
    queryKey: "lastMilestone",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("driveaway-search:Last Milestone"),
    optionsState: lastMilestoneOptionsState,
    requiredOrgTypes: [
      OrganizationType.SHIPPER,
      OrganizationType.DEALER,
      OrganizationType.CARRIER,
    ],
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "ddaDealerZone",
    Component: AsyncSelectFilterButton,
    label: (t) => t("driveaway-search:Dealer Zone"),
    isMulti: true,
    optionsState: driveAwayDealerZoneOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
    requiredOrgTypes: [OrganizationType.SHIPPER],
    hideSelectEmpty: true,
    hideFuzzySearch: true,
  },
  {
    queryKey: "ddaDealerDistrict",
    Component: AsyncSelectFilterButton,
    label: (t) => t("driveaway-search:Dealer District"),
    isMulti: true,
    optionsState: driveAwayDealerDistrictOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
    requiredOrgTypes: [OrganizationType.SHIPPER],
    hideSelectEmpty: true,
    hideFuzzySearch: true,
  },
  {
    queryKey: "ddaCountryCode",
    label: (t) => t("driveaway-search:Dealer Country"),
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
    requiredOrgTypes: [OrganizationType.SHIPPER],
    optionsGetter: (props, t) => {
      return [
        {
          label: t("driveaway-search:USA"),
          value: "US",
        },
        {
          label: t("driveaway-search:CANADA"),
          value: "CA",
        },
      ];
    },
    hideSelectEmpty: true,
    hideFuzzySearch: true,
  },
  {
    queryKey: "ddaStatusEventTime",
    label: (t) => t("driveaway-search:Status Event Time"),
    Component: DateRangeFilterButton,
    queryBuilder: (queryParameter, filterValue) =>
      getStatusEventTimeDateRangeQueryString(queryParameter, filterValue),
    requiredOrgTypes: [OrganizationType.CARRIER, OrganizationType.SHIPPER],
    isValueValid: (value) => {
      if (_.isNil(value)) {
        return true;
      }
      const hasDateValues = !_.isNil(value.to) || !_.isNil(value.from);
      if (!hasDateValues) {
        return false;
      }
      return true;
    },
  },
  {
    queryKey: "ddaProductType",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("driveaway-search:Product Type"),
    optionsState: driveAwayProductTypeOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "batch",
    label: (t) => t("driveaway-search:Batch Search"),
    Component: BatchFilterButton,
    optionsGetter: (props, t) => {
      return [
        {
          label: t("driveaway-search:VIN"),
          value: "vin",
          batchCsvExample: batchCsvVinExample,
        },
      ];
    },
    queryBuilder: getEmptyQueryString,
  },
];
