/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Text, FontSize } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { useDamageViewFormLabelTranslation } from "pages/damageview/utils/translation.utils";

export const useColumns = (
  setShowPhotoViewer,
  setPhotoVin,
  setShowAreaTypePopup,
  setAreaTableData,
) => {
  const { t } = useTranslation("damageview-details");
  const {
    getTranslatedAreaCode,
    getTranslatedTypeCode,
    getTranslatedSeverityCode,
  } = useDamageViewFormLabelTranslation();

  return [
    {
      Header: t("damageview-details:VIN"),
      id: "vin",
      accessor: (obj) => {
        return {
          vin: obj?.id ?? "",
        };
      },
      width: 220,
      disableSortBy: true,
      Cell: VinCell,
    },
    {
      Header: t("damageview-details:Last Milestone"),
      id: "lastmilestone",
      accessor: (obj) => {
        return {
          code: obj?.lastStatusUpdate?.code ?? "",
          codeDescription: obj?.lastStatusUpdate?.codeDescription ?? "",
        };
      },
      width: 220,
      disableSortBy: true,
      Cell: LastMileStoneCell,
    },
    {
      Header: t("damageview-details:Railcar Position"),
      id: "railcarPosition",
      accessor: (obj) => {
        return {
          railcarPosition: obj?.railcarPosition ?? "",
        };
      },
      disableSortBy: true,
      Cell: RailcarPositionCell,
    },
    {
      Header: t("damageview-details:Bay Location"),
      id: "bayLocation",
      accessor: (obj) => {
        return {
          bayLocation: obj?.bayLocation ?? "",
        };
      },
      disableSortBy: true,
      Cell: BayLocationCell,
    },
    {
      Header: t("damageview-details:Area / Type / Severity"),
      id: "area_type_severity",
      accessor: (obj) => {
        const areaTypeSeverityArray =
          obj?.areaTypeSeverity.map((obj) => {
            return {
              area: getTranslatedAreaCode(obj?.area?.value),
              type: getTranslatedTypeCode(obj?.type?.value),
              severity: getTranslatedSeverityCode(obj?.severity?.value),
            };
          }) ?? [];
        return {
          data: areaTypeSeverityArray ?? [],
          setShowAreaTypePopup,
          setAreaTableData,
        };
      },
      disableSortBy: true,
      Cell: AreaTypeSeverityCell,
    },
    {
      Header: t("damageview-details:VIN Comment"),
      id: "vinComment",
      accessor: (obj) => {
        return {
          content: obj?.comments ?? "",
        };
      },
      disableSortBy: true,
      minWidth: 200,
      Cell: VinCommentCell,
    },
    {
      Header: t("damageview-details:Photos"),
      id: "photos",
      accessor: (obj) => {
        return {
          setShowPhotoViewer: setShowPhotoViewer,
          setPhotoVin: setPhotoVin,
          vin: obj?.id ?? "",
        };
      },
      width: 80,
      disableSortBy: true,
      Cell: PhotosCell,
    },
  ];
};

// Cells

const VinCell = (props) => {
  const { vin } = props.value;
  return (
    <div>
      <Text bold block size={FontSize.size14}>
        {vin}
      </Text>
    </div>
  );
};

VinCell.propTypes = {
  value: PropTypes.shape({
    vin: PropTypes.string,
  }),
};

const LastMileStoneCell = (props) => {
  const { t } = useTranslation("damageview-details");
  const { code, codeDescription } = props.value;
  const value = `${codeDescription}${code ? ` (${code})` : ""}`;
  return (
    <div>
      <Text bold block size={FontSize.size14}>
        {value ? value : t("damageview-details:No updates available")}
      </Text>
    </div>
  );
};

LastMileStoneCell.propTypes = {
  value: PropTypes.shape({
    code: PropTypes.string,
    codeDescription: PropTypes.string,
  }),
};

const RailcarPositionCell = (props) => {
  const { railcarPosition } = props.value;
  return (
    <div>
      <Text block>{railcarPosition}</Text>
    </div>
  );
};

RailcarPositionCell.propTypes = {
  value: PropTypes.shape({
    railcarPosition: PropTypes.string,
  }),
};

const BayLocationCell = (props) => {
  const { t } = useTranslation("damageview-details");
  const { bayLocation } = props.value;
  return (
    <div>
      <Text block>{bayLocation}</Text>
    </div>
  );
};

BayLocationCell.propTypes = {
  value: PropTypes.shape({
    bayLocation: PropTypes.string,
  }),
};

const AreaTypeSeverityCell = (props) => {
  const { t } = useTranslation("damage-view");
  const { data, setShowAreaTypePopup, setAreaTableData } = props.value;
  return (
    <div>
      {data.length === 1 ? (
        <div>
          <div>
            <Text bold style={{ marginRight: 3 }}>
              {t("damage-view:Area")}:
            </Text>
            <Text>{data?.[0]?.area}</Text>
          </div>
          <div>
            <Text bold style={{ marginRight: 3 }}>
              {t("damage-view:Type")}:
            </Text>
            <Text>{data?.[0]?.type}</Text>
          </div>
          <div>
            <Text bold style={{ marginRight: 3 }}>
              {t("damage-view:Severity")}:
            </Text>
            <Text>{data?.[0]?.severity}</Text>
          </div>
        </div>
      ) : (
        <Button
          css={{ paddingLeft: "unset", cursor: "pointer" }}
          variant="link"
          onClick={(e) => {
            e.stopPropagation();
            setShowAreaTypePopup(true);
            setAreaTableData(data ? data : []);
          }}
        >
          {t("damageview-details:Click here")}
        </Button>
      )}
    </div>
  );
};

AreaTypeSeverityCell.propTypes = {
  value: PropTypes.shape({
    data: PropTypes.array,
    setShowAreaTypePopup: PropTypes.func,
    setAreaTableData: PropTypes.func,
  }),
};

const VinCommentCell = (props) => {
  const { content } = props.value;
  return (
    <div>
      <Text block>{content}</Text>
    </div>
  );
};

VinCommentCell.propTypes = {
  value: PropTypes.shape({
    content: PropTypes.string,
  }),
};

const PhotosCell = (props) => {
  const { t } = useTranslation("damageview-details");
  const { vin, setPhotoVin, setShowPhotoViewer } = props.value;
  return (
    <div>
      <Button
        css={{ paddingLeft: "unset", cursor: "pointer" }}
        variant="link"
        onClick={(e) => {
          e.stopPropagation();
          setShowPhotoViewer(true);
          setPhotoVin(vin);
        }}
      >
        {t("damageview-details:Photo Viewer")}
      </Button>
    </div>
  );
};

PhotosCell.propTypes = {
  value: PropTypes.shape({
    vin: PropTypes.string,
    setPhotoVin: PropTypes.func,
    setShowPhotoViewer: PropTypes.func,
  }),
};
