/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { useUsersTranslation } from "modules/users/utils/users.utils";

import {
  TiPencil,
  TiTrash,
  TiUserOutline,
  TiMail,
  TiPlus,
} from "react-icons/ti";

import { FlexColDiv, FlexDiv } from "styles/container-elements";
import Colors from "styles/colors";
import {
  LinkButton,
  markerStyle,
  actionButtonStyle,
} from "styles/table-action-styles";

const getStatusColor = (status) => {
  let color;

  switch (status) {
    case "Active":
      color = Colors.highlight.GREEN;
      break;
    case "Disabled":
    case "Invitation Expired":
      color = Colors.highlight.RED;
      break;
    default:
      color = "black";
      break;
  }

  return color;
};

const RoleTypeCell = (props) => {
  const { roles, id } = props.value;
  const { getTranslatedFriendlyName } = useUsersTranslation();

  const items = roles
    ? roles.map((role) => {
        const item =
          role.users.includes(id) && role.name ? (
            <div key={`${role.name}`}>
              {getTranslatedFriendlyName(role.name)}
            </div>
          ) : null;
        return item;
      })
    : null;

  return <FlexColDiv>{items}</FlexColDiv>;
};

RoleTypeCell.propTypes = {
  value: PropTypes.object.isRequired,
};

const StatusCell = (props) => {
  const { actionHandler, status, t } = props.value;
  const { original, index } = props.row;
  const { getTranslatedStatus } = useUsersTranslation();

  return (
    <FlexDiv>
      <div style={{ color: getStatusColor(status), fontSize: 12 }}>
        {getTranslatedStatus(status)}
      </div>
      {status === "Invitation Sent" ? (
        <LinkButton
          clickHandler={() =>
            actionHandler("RESEND", {
              ...original,
              rowID: index,
            })
          }
        >
          {t("users:(Resend)")}
        </LinkButton>
      ) : null}
    </FlexDiv>
  );
};

StatusCell.propTypes = {
  value: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
};

const ActionCell = (props) => {
  const { actionHandler, roles, status, t } = props.value;
  const { original, index } = props.row;

  return (
    <div
      css={{
        "button:last-child svg": { marginRight: "0 !important" },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LinkButton
        style={actionButtonStyle}
        clickHandler={() =>
          actionHandler(status === "Disabled" ? "UNBLOCK" : "BLOCK", {
            ...original,
            rowID: index,
          })
        }
      >
        {status === "Disabled"
          ? t("users:Mark Active")
          : t("users:Mark Inactive")}{" "}
        <TiUserOutline style={markerStyle} />
      </LinkButton>
      <LinkButton
        style={actionButtonStyle}
        clickHandler={() =>
          actionHandler("UPDATE", {
            ...original,
            rowID: index,
          })
        }
      >
        {t("users:Edit")} <TiPencil style={markerStyle} />
      </LinkButton>
      <LinkButton
        style={actionButtonStyle}
        clickHandler={() =>
          actionHandler("DELETE", {
            ...original,
            rowID: index,
          })
        }
      >
        {t("users:Delete")} <TiTrash style={markerStyle} />
      </LinkButton>
      {roles && ( // H1-533: Only FV_ADMIN and ORG_ADMIN can set/reset user's passwords.
        <LinkButton
          style={actionButtonStyle}
          clickHandler={() =>
            actionHandler("RESET", {
              ...original,
              rowID: index,
            })
          }
        >
          {t("users:Reset Password")} <TiMail style={markerStyle} />
        </LinkButton>
      )}
      <LinkButton
        style={actionButtonStyle}
        clickHandler={() =>
          actionHandler("DETAILS", {
            ...original,
            rowID: index,
          })
        }
      >
        {t("users:Additional Details")} <TiPlus style={markerStyle} />
      </LinkButton>
    </div>
  );
};

ActionCell.propTypes = {
  value: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
};

const UsersTable = ({
  t,
  actionHandler,
  data,
  roles,
  isLoaded,
  pageIndex,
  pageSize,
  pageCount,
  totalCount,
  onPageChange,
  onPageSizeChange,
  onPageSort,
  sortColumn,
  reverseSort,
  defaultSortColumn,
  defaultReverseSort,
}) => {
  const usersColumns = [
    {
      Header: t("users:Email"),
      accessor: "email",
      minWidth: 250,
    },
    {
      Header: t("users:Role or Roles"),
      id: "user-roles",
      minWidth: 200,
      disableSortBy: true,
      accessor: (d) => {
        return {
          roles: roles,
          id: d.user_id,
        };
      },
      Cell: RoleTypeCell,
    },
    {
      Header: t("users:Status"),
      id: "status",
      minWidth: 100,
      width: 100,
      accessor: (d) => {
        return {
          actionHandler: actionHandler,
          status: d.status,
          t: t,
        };
      },
      Cell: StatusCell,
    },
    {
      Header: t("users:Actions"),
      id: "user-actions",
      minWidth: 600,
      width: 600,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: ActionCell,
      accessor: (d) => {
        return {
          actionHandler: actionHandler,
          roles: roles,
          status: d.status,
          t: t,
        };
      },
    },
  ];

  return (
    <BaseTable
      columns={usersColumns}
      data={data}
      isLoading={!isLoaded}
      showPagination={true}
      fixPaginationToBottom={true}
      isManualPagination={true}
      pageIndex={pageIndex}
      pageSize={pageSize}
      pageCount={pageCount}
      totalCount={totalCount}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      isManualSorting={true}
      onPageSort={onPageSort}
      sortColumn={sortColumn}
      reverseSort={reverseSort}
      defaultSortColumn={defaultSortColumn}
      defaultReverseSort={defaultReverseSort}
    />
  );
};

UsersTable.propTypes = {
  t: PropTypes.func.isRequired,
  actionHandler: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  isLoaded: PropTypes.bool,
};

export default withTranslation(["users"])(UsersTable);
