import { connect } from "react-redux";
import ReportFolderModalState from "pages/reports/redux/ReportFolderModalState";
import { MoveReportModal } from "./MoveReport.modal";

import ReportsState from "pages/reports/redux/ReportsState";
import ShipmentsSearchBarState from "pages/shipments/redux/ShipmentsSearchBarState";
import ShipmentsFilterSectionContainer from "pages/shipments/components/search/Shipments.SearchFilters.container";
import FinishedVehicleSearchBarState from "modules/fv-search/FinVehicleSearchBarState";
import FinishedVehicleFilterSectionContainer from "modules/fv-search/FinVehicleSearchFiltersContainer";
import finVehicleDomainData from "modules/domain-data/FinVehicleDomainData";
import {
  fetchLocationDetails,
  getLocations,
} from "pages/administration/location-management/redux/LocationsState";
import { getSolutionId } from "modules/organizations/OrganizationsState";

const { getCategoryFolderData, getMoveFolderReportData, getIsLoading } =
  ReportFolderModalState.selectors;
const { moveFolderReportModal } = ReportFolderModalState.actionCreators;

const mapStateToProps = (state) => {
  return {
    reportCategoryFolderData: getCategoryFolderData(state),
    moveFolderReport: getMoveFolderReportData(state),
    isLoading: getIsLoading(state),

    solutionId: getSolutionId(state),
    shipmentSearchFilters:
      ShipmentsSearchBarState.selectors.getSearchFilters(state),
    ShipmentFilterSectionContainer: ShipmentsFilterSectionContainer,
    finishedVehicleSearchFilters:
      FinishedVehicleSearchBarState.selectors.getSearchFilters(state),
    FinishedVehicleFilterSectionContainer:
      FinishedVehicleFilterSectionContainer,
    locations: getLocations(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveFolderReportModal: (reportId, filterSetId, payload) =>
      dispatch(moveFolderReportModal(reportId, filterSetId, payload)),

    setShipmentSearchFilter: (key, value) =>
      dispatch(
        ShipmentsSearchBarState.actionCreators.setSearchFilter(key, value),
      ),
    clearShipmentSearchFilters: () =>
      dispatch(ShipmentsSearchBarState.actionCreators.clearSearchFilters()),
    setFinishedVehicleSearchFilter: (key, value) =>
      dispatch(
        FinishedVehicleSearchBarState.actionCreators.setSearchFilter(
          key,
          value,
        ),
      ),
    clearFinishedVehicleSearchFilters: () =>
      dispatch(
        FinishedVehicleSearchBarState.actionCreators.clearSearchFilters(),
      ),
    fetchDomainData: (solutionId) =>
      dispatch(finVehicleDomainData.actionCreators.fetchDomainData(solutionId)),
    fetchReports: () => dispatch(ReportsState.actionCreators.fetchReports()),
    fetchLocationDetails: (locationId) =>
      dispatch(fetchLocationDetails(locationId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoveReportModal);
