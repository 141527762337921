import _ from "lodash";

import {
  getBasicQueryString,
  getMultiSelectQueryString,
  getDateRangeQueryString,
  getNQueryStringFilterValuePriority,
} from "components/search-bar/search-filter-query-strings";
import {
  AsyncSelectFilterButton,
  MultiSelectFilterButton,
  NFilterButton,
} from "components/search-bar/FilterButtons";

import { OrganizationType } from "shared/constants/organization.const";
import {
  SELECT_ALL_OPTION_VALUE,
  SELECT_EMPTY_OPTION_VALUE,
} from "components/molecules/SearchableSelectList.molecule.tsx";

import {
  damageAreaOptionsState,
  damageViewAssigneesOptionsState,
  damageViewPartnersOptionsState,
  currentPositionsCodeOptionsState,
  productTypeOptionsState,
  lastMilestoneOptionsState,
} from "../../redux/DamageViewSearchFilterLoaders";
import { DateRangeFilterButton } from "components/search-bar/FilterButtons";
import { useDamageViewStatusTranslation } from "pages/damageview/utils/translation.utils";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "vin",
    label: (t) => t("damageview-search:VIN"),
    placeholder: (t) => t("damageview-search:Search VIN"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("entityId", filterValue),
    loaderProperty: "searchVins",
    property: "vins",
    loadingProperty: "vinsLoading",
  },
  {
    queryKey: "submissionId",
    label: (t) => t("damageview-search:Submission ID"),
    placeholder: (t) => t("damageview-search:Search Submission ID"),
    queryBuilder: getBasicQueryString,
  },
];

export const SEARCH_FILTERS = [
  {
    queryKey: "status",
    label: (t) => t("damageview-search:Status"),
    optionsGetter: (props) => props.statusFilterOptions,
    Component: (props) => {
      const { getTranslatedStatus } = useDamageViewStatusTranslation();
      const options = props.options.map((option) => {
        return {
          ...option,
          label: getTranslatedStatus(option?.label),
        };
      });
      return <MultiSelectFilterButton {...props} options={options} />;
    },
    hideSelectEmpty: true,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: "inProgressStatus",
    label: (t) => t("damageview-search:In Progress Status"),
    optionsGetter: (props) => props.inProgressStatusFilterOptions,
    Component: (props) => {
      const { getTranslatedInProgressStatus } =
        useDamageViewStatusTranslation();
      const options = props.options.map((option) => {
        return {
          ...option,
          label: getTranslatedInProgressStatus(option?.label),
        };
      });
      return <MultiSelectFilterButton {...props} options={options} />;
    },
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: "assignee",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("damageview-search:Assignee"),
    optionsState: damageViewAssigneesOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString("assignee", filterValue),
    hideFuzzySearch: true,
  },
  {
    queryKey: "partner",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("damageview-search:Partner"),
    optionsState: damageViewPartnersOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString("partner", filterValue),
    requiredOrgTypes: [OrganizationType.SHIPPER],
    hideFuzzySearch: true,
  },
  {
    queryKey: "submission_date",
    label: (t) => t("damageview-search:Submission Date"),
    Component: DateRangeFilterButton,
    queryBuilder: getDateRangeQueryString,
    isValueValid: (value) => {
      if (_.isNil(value)) {
        return true;
      }
      const hasDateValues = !_.isNil(value.to) || !_.isNil(value.from);
      if (!hasDateValues) {
        return false;
      }
      return true;
    },
  },
  {
    queryKey: "lastMilestone",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("damageview-search:Last Milestone"),
    optionsState: lastMilestoneOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    // ordered top down from 0 - N
    queryKey: ["currentPositionTypes", "currentPositionCodes"],
    label: (t) => t("damageview-search:Location"),
    Component: NFilterButton,
    nIsAsync: {
      currentPositionTypes: false,
      currentPositionCodes: true,
    },
    nOptionsState: {
      currentPositionTypes: null,
      currentPositionCodes: currentPositionsCodeOptionsState,
    },
    //Label of filter where key is corresponding select
    nLabels: {
      currentPositionTypes: null,
      currentPositionCodes: "At Location",
    },
    //Fields required from previous filter
    //Field with contraints as key
    nRequirments: {
      currentPositionCodes: ["AtLocation"],
    },
    // Hides fuzzy search options
    nHideFuzzySearch: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    // Hides select all option
    nHideSelectAll: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    // Hides select empty option
    nHideSelectEmpty: {
      currentPositionTypes: true,
      currentPositionCodes: true,
    },
    //Showall filters with no requirments or contraints
    showAll: false,
    //Object key as query key
    optionsGetter: (props, t) => {
      const options = [
        {
          label: t("damageview-search:At Location"),
          value: "AtLocation",
        },
        {
          label: t("damageview-search:In Transit"),
          value: "InTransit",
        },
      ];
      return {
        currentPositionTypes: options,
      };
    },
    queryBuilder: getNQueryStringFilterValuePriority,
  },
  {
    queryKey: "productType",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("damageview-search:Product Type"),
    optionsState: productTypeOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "damageArea",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("damageview-search:Damage Area"),
    optionsState: damageAreaOptionsState,
    queryBuilder: (queryParameter, filterValue) => {
      if (filterValue[0] && filterValue[0].value === SELECT_ALL_OPTION_VALUE) {
        return getMultiSelectQueryString(queryParameter, filterValue);
      }
      let isNullValue = "";
      const updatedValue = [];
      filterValue?.forEach((data) => {
        if (data.value === SELECT_EMPTY_OPTION_VALUE) {
          isNullValue = getMultiSelectQueryString(queryParameter, [data]);
        } else {
          updatedValue.push(data.value);
        }
      });
      return (
        isNullValue +
        `&${queryParameter}=${encodeURIComponent(JSON.stringify(updatedValue))}`
      );
    },
    requiredOrgTypes: [OrganizationType.SHIPPER],
  },
  {
    queryKey: "damageHold",
    label: (t) => t("damageview-search:Damage Hold"),
    Component: MultiSelectFilterButton,
    optionsGetter: (props, t) => {
      return [
        {
          label: t("damageview-search:Yes"),
          value: "true",
        },
        {
          label: t("damageview-search:No"),
          value: "false",
        },
      ];
    },
    queryBuilder: getBasicQueryString,
    hideSelectAll: true,
    hideSelectEmpty: true,
    requiredEntitySystemConfig: [
      { key: "SHOW_DAMAGED_VINS_COUNT_WIDGET", value: "true" },
    ],
  },
];

export const SubmissionsByStatusFilters = [
  {
    queryKey: "productType",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("damageview-search:Product Type"),
    optionsState: productTypeOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
];
