/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useCallback } from "react";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import {
  LocationAddressColumn,
  LocationNameColumn,
  LocationCodeColumn,
  LocationShipperColumn,
  LocationTypeColumn,
  LocationParentColumn,
  LocationGeofenceColumn,
  ParentLocationCodeColumn,
} from "pages/administration/location-management/utils/table-columns";

const LocationTable = (props) => {
  const {
    loading,
    page,
    pageSize,
    totalPages,
    defaultSortColumn,
    defaultReverseSort,
    sortColumn,
    reverseSort,
    setPagination,
    setSort,
    solutionId,
    data,
    rowClickHandler,
    filters,
    onFilterChange,
    showCarrierColumns,
  } = props;

  return (
    <BaseTable
      columns={[
        LocationParentColumn,
        LocationNameColumn,
        ...(showCarrierColumns ? [LocationShipperColumn] : []),
        LocationTypeColumn(showCarrierColumns),
        LocationGeofenceColumn,
        LocationCodeColumn,
        LocationAddressColumn,
        ParentLocationCodeColumn,
      ]}
      data={data}
      isLoading={loading}
      rowClickHandler={rowClickHandler}
      showPagination={true}
      fixPaginationToBottom={true}
      isManualPagination={true}
      isManualSorting={true}
      pageIndex={page}
      pageCount={totalPages}
      pageSize={pageSize}
      defaultSortColumn={defaultSortColumn}
      defaultReverseSort={defaultReverseSort}
      sortColumn={sortColumn}
      reverseSort={reverseSort}
      onPageChange={(newPage) => {
        setPagination(solutionId, newPage, pageSize);
      }}
      onPageSizeChange={(newPageSize) => {
        setPagination(solutionId, 0, newPageSize);
      }}
      onPageSort={useCallback(
        (column, isReverse) => {
          setSort(solutionId, column, isReverse);
        },
        [solutionId, setSort],
      )}
      showFilters={true}
      isManualFilterable={true}
      filters={filters}
      onFilterChange={onFilterChange}
    />
  );
};

LocationTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  defaultSortColumn: PropTypes.string,
  defaultReverseSort: PropTypes.bool,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  setPagination: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  solutionId: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  rowClickHandler: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  showCarrierColumns: PropTypes.bool.isRequired,
};

export default LocationTable;
