/** @jsxImportSource @emotion/react */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { getSolutionId } from "../organizations/OrganizationsState";
import MilestoneDashboardSearchBarState from "./MilestoneDashboardSearchBarState";
import {
  milestoneEventsTableColumns,
  keyByIdOrAccessor,
} from "./MilestoneEventsTableColumns";
import { MilestoneDashboardExpandedRow } from "./MilestoneDashboardExpandedRow";

const {
  getMilestoneEvents,
  getIsLoading,
  getLoadingError,
  getPage,
  getPageSize,
  getTotalPages,
  getDefaultSortColumn,
  getDefaultReverseSort,
  getSortColumn,
  getReverseSort,
} = MilestoneDashboardSearchBarState.selectors;
const { setSort } = MilestoneDashboardSearchBarState.actionCreators;

const alertCss = {};

const ErrorAlert = (props) => {
  const { t } = useTranslation("milestone-search");
  const isLoading = useSelector(getIsLoading);
  const loadingError = useSelector(getLoadingError);

  const isDetailedError =
    loadingError && loadingError.response && loadingError.response.data;
  const detailedError = isDetailedError
    ? loadingError?.response?.data?.error ?? loadingError?.response?.data
    : null;

  if (isLoading || !loadingError) {
    return null;
  }

  return isDetailedError ? (
    <Alert variant="danger" css={alertCss}>
      <h3>{t("milestone-search:An Error Occurred")}</h3>
      <div data-qa="text-alert-message">{detailedError?.message}</div>
      <div data-qa="text-alert-response">{detailedError?.response}</div>
      <div data-qa="text-alert-stack">{detailedError?.stack}</div>
    </Alert>
  ) : (
    <Alert variant="warning" css={alertCss}>
      <div data-qa="text-alert-message">
        {t(`milestone-search:${loadingError}`)}
      </div>
    </Alert>
  );
};

const MilestoneEventsTable = (props) => {
  const { t } = useTranslation("milestone-search");
  const dispatch = useDispatch();

  const milestoneEvents = useSelector(getMilestoneEvents);
  const solutionId = useSelector(getSolutionId);
  const isLoading = useSelector(getIsLoading);
  const page = useSelector(getPage);
  const pageSize = useSelector(getPageSize);
  const totalPages = useSelector(getTotalPages);
  const defaultSortColumn = useSelector(getDefaultSortColumn);
  const defaultReverseSort = useSelector(getDefaultReverseSort);
  const sortColumn = useSelector(getSortColumn);
  const reverseSort = useSelector(getReverseSort);

  const columns = milestoneEventsTableColumns(t);
  const colDefsByColId = keyByIdOrAccessor(columns);

  return (
    <React.Fragment>
      <ErrorAlert />
      <BaseTable
        data={milestoneEvents}
        columns={columns}
        isLoading={isLoading}
        showPagination={true}
        isManualPagination={true}
        isManualSorting={true}
        pageIndex={page}
        pageSize={pageSize}
        pageCount={totalPages}
        defaultSortColumn={defaultSortColumn}
        defaultReverseSort={defaultReverseSort}
        sortColumn={sortColumn}
        reverseSort={reverseSort}
        onPageChange={(newPage) => {
          dispatch(
            MilestoneDashboardSearchBarState.actionCreators.setPagination(
              solutionId,
              newPage,
              pageSize,
            ),
          );
        }}
        onPageSizeChange={(newPageSize) => {
          dispatch(
            MilestoneDashboardSearchBarState.actionCreators.setPagination(
              solutionId,
              0,
              newPageSize,
            ),
          );
        }}
        onPageSort={(newSortColumn, newReverseSort) => {
          // Use the sortColumn (which is going to be the ID) to get column definition
          const newColDef = colDefsByColId[newSortColumn];
          if (!newColDef) {
            // No column definition, meaning go back to default sort
            return dispatch(setSort(solutionId, null, false, null));
          }

          // Pass the sortColumnOverride to search
          dispatch(
            setSort(
              solutionId,
              newSortColumn,
              newReverseSort,
              newColDef.sortColumnOverride,
            ),
          );
        }}
        subComponent={(row) => <MilestoneDashboardExpandedRow row={row.row} />}
      />
    </React.Fragment>
  );
};

export default MilestoneEventsTable;
