/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Component, Suspense, useEffect } from "react";

import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import i18n, { options as i18nOptions } from "i18n";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";

import {
  fetchDomainData,
  fetchOrganizationTypeDomainData,
} from "./modules/domain-data/DomainDataState";
import { searchLocations } from "./pages/administration/location-management/redux/LocationsState";
import {
  getActiveOrganization,
  getCurrentOrganizationId,
  setActiveOrganization,
  getSolutionId,
  getFeatureData,
  getEntitySystemConfigOptions,
  getOrganizationImageConfig,
  fetchOrganizationImages,
  fetchOrganizations,
  getOrganizationByDbId,
  getOrganizationsLoadingError,
} from "./modules/organizations/OrganizationsState";
import AppConfigurationState from "shared/redux/AppConfigurationState";
import { Sidebar } from "./modules/appnav/SidebarNav";
import RolesState from "./modules/roles/RolesState";
import ShipmentsSearchBarState from "pages/shipments/redux/ShipmentsSearchBarState";
import { resetAllSearchBarStates } from "./components/search-bar/SearchBarStateBuilder";
import { clearTitles } from "components/hooks/useSetTitle";
import AuthenticationState from "modules/auth/AuthenticationState";
import ProfileState, {
  fetchUserPreferences,
} from "modules/profile/ProfileState";
import {
  DATE_FORMAT,
  TIME_FORMAT,
  setDateFormat,
  setTimeFormat,
} from "utils/date-time";

import AuthorizedComponentWithRedirect from "./modules/auth/AuthorizedComponentWithRedirect";
import AuthenticationUtils from "./modules/auth/authentication";
import Authorization, { Privileges } from "./modules/auth/Authorization";

import LadsState from "./modules/lads/LadsState";
import MapState, { getGoogleMaps } from "./modules/map/MapState";
import HeaderBar from "./modules/header-bar/HeaderBarView.container";

import { useIsMediumAndDown } from "./components/responsive";
import classNames from "classnames";
import {
  routeForLocation,
  verifyRouteForShipper,
  verifyBrowser,
} from "./routes";
import { isCarrier } from "shared/utils/organizations.utils";
import { useState } from "react";
import "./styles/App.scss";
import Colors from "./styles/colors";

import { getNavBarCollapseStateFromStorage } from "./utils/nav-utils";
import NotificationsState from "./modules/notifications/NotificationsState";
import { window } from "globalthis/implementation";
import { setMeasurementUnits } from "utils/measurement-utils";
import { findSupportedLanguage } from "utils/language-utils";
import FederatedUserEmailUpdateModal from "modules/auth/FederatedUserPreferenceUpdateModalContainer";
import {
  UserAuthorizationNamespace,
  FederatedUserEmailTrail,
} from "./modules/auth/Authorization";

const { fetchNotification } = NotificationsState.actionCreators;

const isErrorView = (location) => {
  const { type } = location;
  return (
    type &&
    (type === "VERIFY_USER_ERROR" ||
      type === "ACCESS_FORBIDDEN_ERROR" ||
      type === "UNSUPPORTED_BROWSER" ||
      type === "NOT_FOUND" ||
      type === "@@redux-first-router/NOT_FOUND")
  );
};

const ApplicationSidebar = ({
  isLoginLoading,
  currentOrganizationId,
  activeOrganization,
  location,
  setSidebarMinimize,
  sidebarMinimize,
  organizationImageConfig,
}) => {
  if (!AuthenticationUtils.isAuthenticated()) {
    return null;
  }

  if (isErrorView(location)) {
    return null;
  }

  if (!currentOrganizationId) {
    return null;
  }

  return (
    <Sidebar
      activeOrganization={activeOrganization}
      isLoginLoading={isLoginLoading}
      location={location}
      setSidebarMinimize={setSidebarMinimize}
      sidebarMinimize={sidebarMinimize}
      organizationImageConfig={organizationImageConfig}
    />
  );
};

ApplicationSidebar.propTypes = {
  isLoginLoading: PropTypes.bool,
  currentOrganizationId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  activeOrganization: PropTypes.shape({
    org_type: PropTypes.string,
  }),
  location: PropTypes.object.isRequired,
  setSidebarMinimize: PropTypes.func.isRequired,
  sidebarMinimize: PropTypes.bool.isRequired,
  organizationImageConfig: PropTypes.shape({
    logoPath: PropTypes.string,
    iconPath: PropTypes.string,
  }),
};

/**
 * Not all pages should have a header rendered.
 * @param location
 * @return {null|*}
 * @constructor
 */
const ApplicationHeader = ({
  currentOrganizationId,
  location,
  isHeaderHidden = false,
}) => {
  if (isHeaderHidden) {
    return null;
  }

  if (isErrorView(location)) {
    return null;
  }

  const showNotifications =
    Boolean(currentOrganizationId) && AuthenticationUtils.isAuthenticated();
  const showOrganization =
    Boolean(currentOrganizationId) && AuthenticationUtils.isAuthenticated();

  return (
    <Suspense fallback="">
      <HeaderBar
        location={location}
        showNotifications={showNotifications}
        showOrganization={showOrganization}
        style={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          borderBottom: "1px solid #ddd",
          minHeight: "3.3em",
          padding: "0.25em 0.25em 0.25em 1em",
        }}
      />
    </Suspense>
  );
};

ApplicationHeader.propTypes = {
  currentOrganizationId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  location: PropTypes.object.isRequired,
  isHeaderHidden: PropTypes.bool,
};

const AppView = ({
  isLoginLoading,
  currentOrganizationId,
  activeOrganization,
  location,
  mainView,
  organizationImageConfig,
  currentUser,
}) => {
  const dispatch = useDispatch();
  const mediumAndDown = useIsMediumAndDown();
  const [sidebarMinimize, setSidebarMinimize] = useState(
    getNavBarCollapseStateFromStorage(mediumAndDown),
  );

  const isNavigationHidden =
    location?.routesMap[location?.type]?.isNavigationHidden;
  const isHeaderHidden = location?.routesMap[location?.type]?.isHeaderHidden;

  const currentUserEmail = currentUser
    ? currentUser[UserAuthorizationNamespace]?.email
    : "";

  const showPreferenceUpdateModal = currentUserEmail?.endsWith(
    FederatedUserEmailTrail,
  );

  const mainPanel = classNames("main-panel", {
    "main-panel-expanded": !isNavigationHidden && sidebarMinimize,
    "main-panel-normal": !isNavigationHidden && !sidebarMinimize,
    "main-panel-hidden": isNavigationHidden,
  });

  useEffect(() => {
    // Clear the title so that the next page has to set it,
    // and we don't accidentally see the previous page's title.
    dispatch(clearTitles());
  }, [dispatch, location]);

  // Fetch the page config when the org or route location changes
  useEffect(() => {
    if (!_.isEmpty(activeOrganization)) {
      dispatch(AppConfigurationState.actionCreators.fetchPageConfig());
    }
  }, [dispatch, activeOrganization, location]);

  return (
    <>
      {!isNavigationHidden ? (
        <ApplicationSidebar
          isLoginLoading={isLoginLoading}
          sidebarMinimize={sidebarMinimize}
          setSidebarMinimize={setSidebarMinimize}
          location={location}
          currentOrganizationId={currentOrganizationId}
          activeOrganization={activeOrganization}
          organizationImageConfig={organizationImageConfig}
        />
      ) : null}
      <div className={mainPanel}>
        {!isHeaderHidden ? (
          <ApplicationHeader
            currentOrganizationId={currentOrganizationId}
            location={location}
            isHeaderHidden={isHeaderHidden}
          />
        ) : null}
        <div className="content">
          <Suspense fallback="">
            {mainView}
            {mainView && showPreferenceUpdateModal ? (
              <FederatedUserEmailUpdateModal />
            ) : null}
          </Suspense>
        </div>
      </div>
    </>
  );
};

AppView.propTypes = {
  isLoginLoading: PropTypes.bool,
  currentOrganizationId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  activeOrganization: PropTypes.shape({
    org_type: PropTypes.string,
  }),
  location: PropTypes.object.isRequired,
  mainView: PropTypes.object.isRequired,
  organizationImageConfig: PropTypes.shape({
    logoPath: PropTypes.string,
    iconPath: PropTypes.string,
  }),
  currentUser: PropTypes.object,
};

class App extends Component {
  static propTypes = {
    isLoginLoading: PropTypes.bool,
    activeOrganization: PropTypes.any,
    currentOrganizationId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    currentUser: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    featureData: PropTypes.array,
    globalAppConfig: PropTypes.object,
    entitySystemConfigOptions: PropTypes.array,
    federationData: PropTypes.object,
    googleMaps: PropTypes.object,
    location: PropTypes.object,
    mapTypeOverride: PropTypes.string,
    organizations: PropTypes.array,
    organizationImageConfig: PropTypes.any,
    userPreferences: PropTypes.object,
    isOrganizationsLoading: PropTypes.bool,
    organizationsLoadingError: PropTypes.object,
  };

  state = { mainView: null };

  constructor(props) {
    super(props);
    this.setMainView = this.setMainView.bind(this);
  }

  reloadDomainData() {
    const { dispatch } = this.props;

    if (AuthenticationUtils.isAuthenticated()) {
      // Always dispatch these methods, we cannot get
      // here unless we have made it pass the auth interceptor
      dispatch(searchLocations());
      dispatch(LadsState.actionCreators.fetchLads());
      dispatch(fetchDomainData());
      dispatch(fetchOrganizationImages());
      dispatch(fetchUserPreferences());
    }
  }

  reloadActiveOrganizationDomainData() {
    const {
      dispatch,
      currentUser,
      activeOrganization,
      federationData,
      featureData,
      globalAppConfig,
      entitySystemConfigOptions,
    } = this.props;

    if (AuthenticationUtils.isAuthenticated() && activeOrganization) {
      const authorization = new Authorization(
        currentUser,
        activeOrganization,
        federationData,
        featureData,
        globalAppConfig,
        entitySystemConfigOptions,
      );
      const isCarrierOrg = isCarrier(activeOrganization);

      // H1-476: Restrict loading the roles list to the manage users privilege.
      if (authorization.hasPrivileges([Privileges.MANAGE_USERS])) {
        dispatch(RolesState.actionCreators.fetchRoles());
      }

      dispatch(fetchOrganizationTypeDomainData(isCarrierOrg));
    }
  }

  /**
   *
   * @param location
   */
  setMainView(location) {
    const { activeOrganization } = this.props;

    // if not a shipper make sure user does not have access to locations
    let checkLocation = verifyRouteForShipper(location, activeOrganization);

    const mainViewRoute = routeForLocation(verifyBrowser(checkLocation));
    const { view, privileges, features, globalAppConfig } = mainViewRoute;
    if (privileges || features || globalAppConfig) {
      const MainView = AuthorizedComponentWithRedirect(
        view,
        location,
        privileges,
        features,
        globalAppConfig,
      );

      this.setState({
        mainView: <MainView />,
      });
    } else {
      this.setState({ mainView: view });
    }
  }

  componentDidMount() {
    //
    // TODO: Do we need to format dates/times/numbers?
    moment.locale("en");
    momentLocalizer();

    const { dispatch } = this.props;

    this.reloadDomainData();

    dispatch(
      MapState.actionCreators.initHere(
        window.H,
        window.google,
        window.mapsindoors,
      ),
    );

    if (this.props.location) {
      this.setMainView(this.props.location);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isLoginLoading,
      googleMaps,
      activeOrganization,
      currentOrganizationId,
      mapTypeOverride,
      dispatch,
      organizations,
      isOrganizationsLoading,
      organizationsLoadingError,
      location,
      userPreferences,
    } = this.props;

    // Sometimes Google Maps takes a second to load, so if we don't have it yet
    // and window.google has it, let's initialize
    if (window.google && !googleMaps) {
      dispatch(
        MapState.actionCreators.initHere(
          window.H,
          window.google,
          window.mapsindoors,
        ),
      );
    }

    // If we don't have the active org, set it if we have the org loaded. Otherwise, fetch the org.
    if (_.isEmpty(activeOrganization) && currentOrganizationId) {
      let org = _.find(organizations, {
        organization_id: Number(currentOrganizationId),
      });

      if (!_.isNil(org)) {
        dispatch(setActiveOrganization(org));
      }

      // TODO: Is this needed? setCurrentOrganization fetches the org already.
      if (!isOrganizationsLoading && !organizationsLoadingError) {
        dispatch(fetchOrganizations({ ids: [currentOrganizationId] }));
      }
    }

    // Whenever the selected organization changes (eg: via the org switcher)
    if (
      currentOrganizationId &&
      prevProps.currentOrganizationId &&
      currentOrganizationId !== prevProps.currentOrganizationId
    ) {
      // Reload domain data for the new organization
      this.reloadDomainData();

      // If we're not on the login-loading screen, redirect to root
      if (!isLoginLoading) {
        dispatch({ type: "ROOT" });
      }

      dispatch(resetAllSearchBarStates());
    }

    // If the user's locale language is different
    // than user-preference language
    // reset language to user-preference language
    if (userPreferences && userPreferences !== prevProps.userPreferences) {
      if (userPreferences.locale) {
        let newI18nOptions = i18nOptions;
        const supportedLanguage = findSupportedLanguage(
          i18nOptions.userLanguage,
        );
        if (supportedLanguage) {
          newI18nOptions.lng = userPreferences.locale;
          i18n.init(newI18nOptions);
        }
      }

      if (userPreferences.dateformat) {
        const dateFormat = DATE_FORMAT[userPreferences.dateformat];
        setDateFormat(dateFormat);
      }
      if (userPreferences.timeformat) {
        const timeFormat = TIME_FORMAT[userPreferences.timeformat];
        setTimeFormat(timeFormat);
      }
      if (userPreferences.units_of_measurement) {
        setMeasurementUnits(userPreferences.units_of_measurement);
      }
    }

    if (mapTypeOverride !== prevProps.mapTypeOverride) {
      // Refresh the page so that we can update the current map platform
      window.location.reload(false);
    }

    // Reload domain data that depends on the activeOrganization (once available)
    if (
      activeOrganization &&
      activeOrganization !== prevProps.activeOrganization
    ) {
      this.reloadActiveOrganizationDomainData();
    }

    /* DEV-1437 clear search bar when navigating away from Shipment Search via sidebar */
    /* H1-694: extend clearing search bar to navigating away from detail pages */
    if (
      (prevProps.location.type === "SHIPMENT_SEARCH" ||
        prevProps.location.type === "SHIPMENT_DETAIL" ||
        prevProps.location.type === "SHIPMENT_DETAIL_CUSTOMER") &&
      (location.type === "ROOT" ||
        location.type === "END_TO_END" ||
        location.type === "LOCATION_MANAGEMENT" ||
        location.type === "USER_MANAGEMENT" ||
        location.type === "ORGANIZATION_MANAGEMENT" ||
        location.type === "REPORTS" ||
        location.type === "CREATE_SHIPMENT")
    ) {
      dispatch(ShipmentsSearchBarState.actionCreators.resetSearchAndFilters());
    }

    if (
      !isLoginLoading &&
      (location !== prevProps.location ||
        activeOrganization !== prevProps.activeOrganization)
    ) {
      this.setMainView(location);
    }
  }

  render() {
    const {
      currentOrganizationId,
      activeOrganization,
      location,
      isLoginLoading,
      organizationImageConfig,
      currentUser,
    } = this.props;

    const { mainView } = this.state;

    if (!mainView) {
      return null;
    }

    // i18n translations might still be loaded by the xhr backend
    // use react's Suspense
    return (
      <AppView
        isLoginLoading={isLoginLoading}
        currentOrganizationId={currentOrganizationId}
        activeOrganization={activeOrganization}
        mainView={mainView}
        location={location}
        organizationImageConfig={organizationImageConfig}
        currentUser={currentUser}
      />
    );
  }
}

function mapStateToProps(state) {
  const currentOrganizationId = getCurrentOrganizationId(state);
  const { organizations, isLoading: isOrganizationsLoading } =
    getOrganizationByDbId([currentOrganizationId])(state);
  return {
    isLoginLoading: AuthenticationState.selectors.getIsLoginLoading(state),
    location: state.location,
    currentUser: state.users.currentUser,
    federationData: state.organizations.federationData,
    googleMaps: getGoogleMaps(state),
    activeOrganization: getActiveOrganization(state),
    currentOrganizationId: currentOrganizationId,
    organizations: organizations,
    isOrganizationsLoading: isOrganizationsLoading,
    organizationsLoadingError: getOrganizationsLoadingError(state),
    solutionId: getSolutionId(state),
    featureData: getFeatureData(state),
    globalAppConfig: AppConfigurationState.selectors.getGlobalAppConfig(state),
    entitySystemConfigOptions: getEntitySystemConfigOptions(state),
    mapTypeOverride: MapState.selectors.getMapTypeOverride(state),
    organizationImageConfig: getOrganizationImageConfig(state),
    userPreferences: ProfileState.selectors.getUserPreferences(state),
  };
}

export default connect(mapStateToProps)(App);
