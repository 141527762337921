import moment from "moment";
import _ from "lodash";

import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { SEARCH_CATEGORIES } from "../ContainerTracingSearchCategoryDefs";
import { FILTERS } from "../ContainerTrackingSearchFilterDefs";

const STORE_MOUNT_POINT = "ctSearch";
const FETCH_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/FETCH_TOTAL_COUNT_FOR_SEARCH`;
const RECEIVE_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/RECEIVE_TOTAL_COUNT_FOR_SEARCH`;

const entitiesUrl = (_ignored_solutionId, queryString) => {
  return apiUrl(
    `/containertracking/api/reuse-trip-container-search/container?${queryString}`,
  );
};

const batchSearchUrl = (_ignored_solutionId, queryString, batchType) => {
  return apiUrl(
    `/containertracking/api/reuse-trip-container-search/container?${queryString}&batchType=${batchType}`,
  );
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
    },
  };
};

const fetchSearch = (
  queryString = "",
  _ignored_solutionId,
  duck,
  dispatch,
  state,
  preventRedirect,
) => {
  let url = entitiesUrl(null, queryString);
  let config = axiosConfig();
  const batchFilter = state[STORE_MOUNT_POINT].searchFilters.batch;

  if (batchFilter) {
    // Batch search POST
    url = batchSearchUrl(null, queryString, batchFilter.batch_type);

    config = {
      ...config,
      method: "post",
      data: {
        batch_list: batchFilter.batch_list,
      },
    };
  }

  dispatch(duck.fetch(url, config));

  // Prevent redirect if needed
  if (!preventRedirect) {
    dispatch({ type: "CONTAINER_TRACKING_SEARCH" });
  }
};

// Selectors
const getTotalCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].data?.meta?.totalCount || 0;

const getTotalPageCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].data?.meta?.totalPages || 0;

const getTotalCountForSearchIsLoading = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearchIsLoading || false;

// Reducer
const ctSearchBarReducer = (
  state = {
    totalCountForSearch: 0,
    totalPageCountForSearch: 0,
    totalCountForSearchIsLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearchIsLoading: state.totalCountForSearch ? false : true,
      };
    case RECEIVE_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearch: action.count,
        totalPageCountForSearch: action.totalPages,
        totalCountForSearchIsLoading: false,
      };
    default:
      return state;
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [ctSearchBarReducer],
);

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  exportSearch: _.partial(
    SearchBarState.actionCreators.exportEntities,
    entitiesUrl,
    batchSearchUrl,
    { headers: { Accept: "text/csv;version=basic" } },
    "container-tracking-search-results",
  ),
};

SearchBarState.selectors = {
  ...SearchBarState.selectors,
  getTotalCountForSearch,
  getTotalPageCountForSearch,
  getTotalCountForSearchIsLoading,
};

export default SearchBarState;
