/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LadChicletCSS as Chiclet } from "components/chiclets";
import Colors from "styles/colors";
import { getLadName, useLadsTranslation } from "modules/lads/utils/lads.utils";
import LadLabelForm from "./LadLabelForm";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";

const CHICLET_HEIGHT = 60;

const LocationTypeCellRenderer = (props) => {
  const { updateLadLabel, ...lad } = props.value;
  const originalLabel = getLadName(lad);
  return (
    <div css={{ display: "flex", flexDirection: "row" }}>
      <div css={{ marginLeft: "1em" }}>
        <Chiclet
          lad={lad}
          height={CHICLET_HEIGHT}
          width={CHICLET_HEIGHT}
          showLadLabel
        />
      </div>
      <LadLabelForm
        lad={lad}
        height={CHICLET_HEIGHT}
        originalLabel={originalLabel}
        updateLadLabel={updateLadLabel}
      />
    </div>
  );
};

LocationTypeCellRenderer.propTypes = {
  value: PropTypes.object,
};

const LadDefinitionCellRenderer = ({ row, value }) => {
  const { getTranslatedLadDefinition } = useLadsTranslation();
  const color = Colors.editLadsLabelText;

  return (
    <div css={{ color: { color }, whiteSpace: "normal", paddingLeft: "1em" }}>
      {getTranslatedLadDefinition(value, row.original.description)}
    </div>
  );
};

LadDefinitionCellRenderer.propTypes = {
  value: PropTypes.string,
};

const connectedLadEditColumns = (t, updateLadLabel) => {
  return [
    {
      Header: t("lads:Location types"),
      id: "code-name-chiclet",
      accessor: (d) => ({
        updateLadLabel,
        ...d,
      }),
      Cell: LocationTypeCellRenderer,
    },

    {
      Header: t("lads:Definition"),
      accessor: (d) => getLadName(d),
      Cell: LadDefinitionCellRenderer,
    },
  ];
};

const LadTable = ({ data, updateLadLabel }) => {
  const { t } = useTranslation("lads");
  const columns = connectedLadEditColumns(t, updateLadLabel);

  return (
    <div className="p-2">
      <BaseTable
        data={data ?? []}
        columns={columns}
        showPagination={true}
        fixPaginationToBottom={true}
      />
    </div>
  );
};

LadTable.propTypes = {
  data: PropTypes.array.isRequired,
  updateLadLabel: PropTypes.func.isRequired,
};

export default LadTable;
