import { connect } from "react-redux";

import { ProfileView } from "./ProfileView";
import ShipmentsDomainDataState from "modules/domain-data/ShipmentsDomainDataState";
import ProfileState from "./ProfileState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { Features } from "modules/auth/Authorization";

function mapStateToProps(state) {
  const auth = getAuthorization(state);
  return {
    ...state.profile,
    userPreferences: ProfileState.selectors.getUserPreferences(state),
    hasDealerPickUpFeature: auth.hasFeatures([Features.DEALER_DRIVE_AWAY]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDomainData: () =>
      dispatch(ShipmentsDomainDataState.actionCreators.fetchDomainData()),
    changePassword: (payload, userId) =>
      dispatch(ProfileState.actionCreators.changePassword(payload, userId)),
    setPreferences: (payload) =>
      dispatch(ProfileState.actionCreators.setPreferences(payload)),
    resetPasswordForm: () =>
      dispatch(ProfileState.actionCreators.resetPasswordForm()),
    getUserPreferences: () =>
      dispatch(ProfileState.actionCreators.getUserPreferences()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
