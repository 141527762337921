/** @jsxImportSource @emotion/react */
// Third party Libraries
import PropTypes from "prop-types";
import _ from "lodash";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useTranslation } from "react-i18next";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { Fragment, useEffect, useState } from "react";
import { isShipper } from "shared/utils/organizations.utils";

// UI Libraries
import { Search } from "components/templates/Search.template";
import { Button } from "react-bootstrap";
import { faCheckSquare } from "@fortawesome/pro-regular-svg-icons";
import Loader from "react-loader";

// Components
import SearchBarContainer from "../components/search/DamageView.SearchBar.container";
import SearchFiltersContainer from "../components/search/DamageView.SearchFilters.container";
import { useColumns } from "../search/DamageView.Search.columns";
import { Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { BatchAssignModal } from "./components/BatchAssign.modal";
import { ExportType } from "modules/exports/ExportsState";
import ExportModal from "modules/exports/ExportModal";

const DamageViewSearch = ({
  pushDamageViewDetailsPage,
  solutionId,
  activeOrganization,
  searchEntities,
  toggleShowFilters,
  damageViewSearchResults,
  isLoading,
  isLoadingError,
  loadingError,
  showFilters,
  sortColumn,
  reverseSort,
  defaultSortColumn,
  defaultReverseSort,
  page,
  pageSize,
  totalPages,
  setPagination,
  setSort,
  resetSearch,
  totalCount,
  totalCountIsLoading,
  exportEntities,
  isExporting,
  exportIdentifier,
  exportName,
  exportFailed,
  clearExportErrors,
  resetExport,
  allSubmissionIds,
  allSubmissionIdsIsLoading,
  saveBatchAssigned,
  exportPDFEntities,
  userPreference,
  isExportingPDF,
  exportPDFIdentifier,
  exportPDFName,
  resetPDFExports,
  watchActions,
  currentUser,
}) => {
  const { t } = useTranslation("damageview-search");
  useSetTitleOnMount(t("damageview-search:Search Results"));

  useTrackWithMixpanelOnce("Viewed Page: DamageView / Search");

  const [selectedIds, setSelectedIds] = useState(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showBatchAssignModal, setShowBatchAssignModal] = useState(false);

  const rowClickHandler = (row, cell) => {
    if (!row || !cell) {
      return;
    }

    pushDamageViewDetailsPage(row.original.id);
  };

  const addSelectedId = (id) => {
    setSelectedIds((prev) => {
      prev.add(id);
      return new Set(prev);
    });
  };

  const removeSelectedId = (id) => {
    setSelectedIds((prev) => {
      prev.delete(id);
      return new Set(prev);
    });
  };

  const selectAllSelections = () => {
    setSelectedIds(new Set(allSubmissionIds));
  };

  const clearAllSelections = () => {
    setSelectedIds(new Set());
  };

  useEffect(() => {
    if ([...selectedIds].length !== 0) {
      if ([...selectedIds].length === allSubmissionIds.length) {
        setIsAllSelected(true);
      } else {
        setIsAllSelected(false);
      }
    } else {
      setIsAllSelected(false);
    }
  }, [selectedIds, allSubmissionIds]);

  const columns = useColumns(
    selectedIds,
    addSelectedId,
    removeSelectedId,
    selectAllSelections,
    clearAllSelections,
    isAllSelected,
    allSubmissionIdsIsLoading,
    exportPDFEntities,
    userPreference,
    isExportingPDF,
    solutionId,
    watchActions,
    isShipper(activeOrganization),
  );

  const batchAssignButtonContainer = isShipper(activeOrganization)
    ? [
        <Button
          variant="link"
          css={{
            color: "#8b8b8b",
            ":hover": {
              color: "#273042",
            },
            display: "flex",
            alignItems: "center",
          }}
          disabled={[...selectedIds].length === 0}
          onClick={() => {
            setShowBatchAssignModal(true);
          }}
        >
          <Text italic underline>
            {t("damageview-search:Batch Assign")}
          </Text>
          <Icon src={faCheckSquare} style={{ marginLeft: "0.3em" }} />
        </Button>,
      ]
    : [];

  return (
    <Fragment>
      <Loader loaded={!isExportingPDF} className="spinner" shadow={true} />
      <Search
        isLoading={isLoading}
        searchResults={damageViewSearchResults}
        totalCount={totalCount}
        totalCountIsLoading={totalCountIsLoading}
        totalCountTitle={t("damageview-search:Total Submissions")}
        hideSavedSearch={true}
        resetSearch={resetSearch}
        showFilters={showFilters}
        toggleShowFilters={toggleShowFilters}
        SearchBarContainer={SearchBarContainer}
        FiltersContainer={SearchFiltersContainer}
        showError={isLoadingError}
        showErrorMessage={loadingError?.response?.data?.error?.message}
        productSpecificSearchBtn={batchAssignButtonContainer}
        exportProps={{
          exportEntities: exportEntities,
          exportIdentifier: exportIdentifier,
          exportName: exportName,
          isExporting: isExporting,
          exportFailed: exportFailed,
          clearExportErrors: clearExportErrors,
          resetExport: resetExport,
        }}
        tableProps={{
          data: damageViewSearchResults,
          columns: columns,
          isLoading: isLoading,
          showPagination: true,
          fixPaginationToBottom: true,
          isManualPagination: true,
          isManualSorting: true,
          sortColumn: sortColumn,
          reverseSort: reverseSort,
          defaultSortColumn: defaultSortColumn,
          defaultReverseSort: defaultReverseSort,
          pageIndex: page,
          pageSize: pageSize,
          pageCount: totalPages,
          onPageChange: (newPage) => {
            setPagination(solutionId, newPage, pageSize);
          },
          onPageSizeChange: (newPageSize) => {
            setPagination(solutionId, 0, newPageSize);
          },
          onPageSort: (column, isReverse) => {
            setSort(column, isReverse);
          },
          rowClickHandler: rowClickHandler,
        }}
      />
      <BatchAssignModal
        show={showBatchAssignModal}
        solutionId={solutionId}
        setShow={setShowBatchAssignModal}
        searchEntities={searchEntities}
        saveBatchAssigned={saveBatchAssigned}
        selectedIds={[...selectedIds]}
        clearAllSelections={clearAllSelections}
        currentUser={currentUser}
      />
      <ExportModal
        exportIdentifier={exportPDFIdentifier}
        exportName={exportPDFName}
        resetExport={resetPDFExports}
        exportType={ExportType.CSV} // Currently using CSV because backend haven't added a new endpoint yet
      />
    </Fragment>
  );
};

DamageViewSearch.propTypes = {
  solutionId: PropTypes.string,
  activeOrganization: PropTypes.object,
  pushDamageViewDetailsPage: PropTypes.func.isRequired,
  searchEntities: PropTypes.func.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
  damageViewSearchResults: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingError: PropTypes.bool,
  loadingError: PropTypes.any,
  showFilters: PropTypes.bool.isRequired,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  defaultSortColumn: PropTypes.string,
  defaultReverseSort: PropTypes.bool,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setPagination: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  exportEntities: PropTypes.func,
  isExporting: PropTypes.bool.isRequired,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  exportFailed: PropTypes.bool,
  clearExportErrors: PropTypes.func,
  resetExport: PropTypes.func,
  allSubmissionIds: PropTypes.array,
  allSubmissionIdsIsLoading: PropTypes.bool,
  totalCount: PropTypes.number.isRequired,
  totalCountIsLoading: PropTypes.bool.isRequired,
  saveBatchAssigned: PropTypes.func,
  exportPDFEntities: PropTypes.func,
  exportPDFIdentifier: PropTypes.string,
  exportPDFName: PropTypes.string,
  isExportingPDF: PropTypes.bool,
  resetPDFExports: PropTypes.func,
  userPreference: PropTypes.object,
  watchActions: PropTypes.func,
  currentUser: PropTypes.object,
};

export default DamageViewSearch;
