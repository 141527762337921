import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useDamageViewStatusTranslation = () => {
  const { t } = useTranslation("damage-view");

  const getTranslatedStatus = useCallback(
    (status) => {
      switch (status?.toLowerCase()) {
        case "submitted":
          return t("damage-view:Submitted");
        case "minor":
          return t("damage-view:Minor");
        case "major":
          return t("damage-view:Major");
        case "warranty":
          return t("damage-view:Warranty");
        case "rejected":
          return t("damage-view:Rejected");
        case "completed":
          return t("damage-view:Completed");
        default:
          return status;
      }
    },
    [t],
  );

  const getTranslatedInProgressStatus = useCallback(
    (status) => {
      switch (status) {
        case "Repair Complete, Continue Shipping":
          return t("damage-view:Repair Complete, Continue Shipping");
        case "Ship As Is":
          return t("damage-view:Ship As Is");
        case "Pending Repair":
          return t("damage-view:Pending Repair");
        case "VTIMS Trip Closure":
          return t("damage-view:VTIMS Trip Closure");
        case "Pending Determination":
          return t("damage-view:Pending Determination");
        case "Mobile Repair in Progress":
          return t("damage-view:Mobile Repair in Progress");
        default:
          return status;
      }
    },
    [t],
  );

  return { getTranslatedStatus, getTranslatedInProgressStatus };
};

export const useDamageViewFormLabelTranslation = () => {
  const { t } = useTranslation("damage-view");

  const getTranslatedFormLabel = useCallback(
    (label) => {
      switch (label?.toLowerCase()) {
        case "chock condition":
          return t("damage-view:Chock Condition");
        case "damage location":
          return t("damage-view:Damage Location");
        case "choose location":
          return t("damage-view:Choose Location");
        case "primary chock type":
          return t("damage-view:Primary Chock Type");
        case "railcar number":
          return t("damage-view:Railcar Number");
        case "railcar type":
          return t("damage-view:Railcar Type");
        case "rejected comments":
          return t("damage-view:Rejected Comments");
        case "repair location":
          return t("damage-view:Repair Location");
        case "reporting location":
          return t("damage-view:Reporting Location");
        case "secondary submitter email":
          return t("damage-view:Secondary Submitter Email");
        case "secondary submitter phone number":
          return t("damage-view:Secondary Submitter Phone Number");
        case "unload date":
          return t("damage-view:Unload Date");
        case "submitter email":
          return t("damage-view:Submitter Email");
        case "submitter phone":
          return t("damage-view:Submitter Phone");
        case "submission date":
          return t("damage-view:Submission Date");
        case "inspection date":
          return t("damage-view:Inspection Date");

        // Step 2 form labels

        case "vin":
          return t("damage-view:VIN");
        case "railcar position":
          return t("damage-view:Railcar Position");
        case "bay location":
          return t("damage-view:Bay Location");
        case "area":
          return t("damage-view:Area");
        case "type":
          return t("damage-view:Type");
        case "severity":
          return t("damage-view:Severity");
        case "comments":
          return t("damage-view:Comments");
        case "bay tag":
          return t("damage-view:Bay Tag");
        case "front right":
          return t("damage-view:Front Right");
        case "front left":
          return t("damage-view:Front Left");
        case "rear right":
          return t("damage-view:Rear Right");
        case "rear left":
          return t("damage-view:Rear Left");
        case "overview of damaged area":
          return t("damage-view:Overview of Damaged Area");
        case "close up of damage":
          return t("damage-view:Close Up of Damage");
        case "railcar":
          return t("damage-view:Railcar");
        case "dashboard overview of the whole unit":
          return t("damage-view:Dashboard Overview of the Whole Unit");
        case "overview of whole unit":
          return t("damage-view:Overview of Whole Unit");
        case "interior damages":
          return t("damage-view:Interior Damages");
        case "additional photos":
          return t("damage-view:Additional Photos");
        default:
          return label ?? "";
      }
    },
    [t],
  );

  const getTranslatedFormTextContext = useCallback(
    (context) => {
      switch (context) {
        case "Please enter the value with country code":
          return t("damage-view:Please enter the value with country code");
        case "Please confirm Reporting Location name and address is accurate":
          return t(
            "damage-view:Please confirm Reporting Location name and address is accurate",
          );
        default:
          return context ?? "";
      }
    },
    [t],
  );

  const getTranslatedAreaCode = useCallback(
    (areaCode) => {
      const code = areaCode?.toString().toLowerCase();
      const formatLabel = (label) => `${code} - ${label}`;

      switch (code) {
        case "01":
          return formatLabel(t("damage-view:Antenna/Antenna Base"));
        case "02":
          return formatLabel(t("damage-view:Battery"));
        case "03":
          return formatLabel(t("damage-view:Bumper/Cover/Exterior, Front"));
        case "04":
          return formatLabel(t("damage-view:Bumper/Cover/Exterior, Rear"));
        case "05":
          return formatLabel(t("damage-view:Bumper Guard/Strip, Front"));
        case "06":
          return formatLabel(t("damage-view:Bumper Guard/Strip, Rear"));
        case "07":
          return formatLabel(t("damage-view:Door Back Cargo - Right"));
        case "08":
          return formatLabel(t("damage-view:Door Back Cargo- Left"));
        case "09":
          return formatLabel(t("damage-view:Door, Cargo (Sliding) R/L"));
        case "10":
          return formatLabel(t("damage-view:Door, Left Front"));
        case "11":
          return formatLabel(t("damage-view:Door, Left Rear"));
        case "12":
          return formatLabel(t("damage-view:Door, Right Front"));
        case "13":
          return formatLabel(t("damage-view:Door, Right Rear"));
        case "14":
          return formatLabel(t("damage-view:Fender, Left Front"));
        case "15":
          return formatLabel(t("damage-view:Qtr. Panel or Pick-Up Box, Left"));
        case "16":
          return formatLabel(t("damage-view:Fender, Right Front"));
        case "17":
          return formatLabel(t("damage-view:Qtr. Panel /Pick-Up Box Right"));
        case "18":
          return formatLabel(t("damage-view:Front Floor Mats"));
        case "19":
          return formatLabel(t("damage-view:Rear Floor Mats"));
        case "20":
          return formatLabel(t("damage-view:Glass Windshield"));
        case "21":
          return formatLabel(t("damage-view:Glass Rear"));
        case "22":
          return formatLabel(t("damage-view:Grille"));
        case "23":
          return formatLabel(t("damage-view:Accessory Bag/Box"));
        case "24":
          return formatLabel(t("damage-view:Headlight/Cover/Turn Signal"));
        case "25":
          return formatLabel(t("damage-view:Lamps, Fog/Driving/Spot Lt."));
        case "26":
          return formatLabel(t("damage-view:Headliner"));
        case "27":
          return formatLabel(t("damage-view:Hood"));
        case "28":
          return formatLabel(t("damage-view:Keys"));
        case "29":
          return formatLabel(t("damage-view:Keyless Remote"));
        case "30":
          return formatLabel(t("damage-view:Mirror, Outside, Left"));
        case "31":
          return formatLabel(t("damage-view:Mirror, Outside, Right"));
        case "32":
          return formatLabel(t("damage-view:Major Damage"));
        case "33":
          return formatLabel(t("damage-view:Front Multimedia"));
        case "34":
          return formatLabel(t("damage-view:Rear Multimedia"));
        case "35":
          return formatLabel(t("damage-view:Rocker Panel /Outer Sill Left"));
        case "36":
          return formatLabel(t("damage-view:Rocker Panel /Outer Sill Right"));
        case "37":
          return formatLabel(t("damage-view:Roof"));
        case "38":
          return formatLabel(t("damage-view:Running Board/Step, Left"));
        case "39":
          return formatLabel(t("damage-view:Running Board/Step, Right"));
        case "40":
          return formatLabel(t("damage-view:Spare Tire"));
        case "41":
          return formatLabel(
            t("damage-view:Charging Cable for Battery Electric Vehicle (BEV)"),
          );
        case "42":
          return formatLabel(t("damage-view:Splash Panel/Spoiler Front"));
        case "43":
          return formatLabel(t("damage-view:Open"));
        case "44":
          return formatLabel(t("damage-view:Gas Tank"));
        case "45":
          return formatLabel(t("damage-view:Tail Light/Hardware"));
        case "46":
          return formatLabel(t("damage-view:Truck Cab, Rear"));
        case "47":
          return formatLabel(t("damage-view:Open"));
        case "48":
          return formatLabel(t("damage-view:Left Front Interior Trim Panel"));
        case "49":
          return formatLabel(t("damage-view:Open"));
        case "50":
          return formatLabel(t("damage-view:Right Front Interior Trim Panel"));
        case "51":
          return formatLabel(t("damage-view:Tonneau Cover"));
        case "52":
          return formatLabel(t("damage-view:Deck Lid/Tailgate/Hatchback"));
        case "53":
          return formatLabel(t("damage-view:Sun Roof/Glass Roof"));
        case "54":
          return formatLabel(t("damage-view:Undercarriage/Other"));
        case "55":
          return formatLabel(t("damage-view:Cargo Area, Other"));
        case "56":
          return formatLabel(t("damage-view:Convertible Top"));
        case "57":
          return formatLabel(t("damage-view:Wheel Covers/Cap"));
        case "58":
          return formatLabel(t("damage-view:Radio Speakers"));
        case "59":
          return formatLabel(t("damage-view:Wipers, All"));
        case "60":
          return formatLabel(t("damage-view:Jumped Chocks"));
        case "61":
          return formatLabel(t("damage-view:Box Interior, Pick-Up Truck"));
        case "62":
          return formatLabel(t("damage-view:Entire vehicle"));
        case "63":
          return formatLabel(t("damage-view:Rails, Truck bed/Light Bar"));
        case "64":
          return formatLabel(t("damage-view:Deflector/Spoiler, Rear"));
        case "65":
          return formatLabel(t("damage-view:Luggage Rack /Strips/Drip Rail"));
        case "66":
          return formatLabel(t("damage-view:Dash/Instrument Panel"));
        case "67":
          return formatLabel(t("damage-view:Cigarette Lighter/Ashtray"));
        case "68":
          return formatLabel(t("damage-view:Carpet, Front"));
        case "69":
          return formatLabel(t("damage-view:Center Post, Right"));
        case "70":
          return formatLabel(t("damage-view:Center Post, Left"));
        case "71":
          return formatLabel(t("damage-view:Corner Post"));
        case "72":
          return formatLabel(t("damage-view:Left Front Tire"));
        case "73":
          return formatLabel(t("damage-view:Left Front Wheel/Rim"));
        case "74":
          return formatLabel(t("damage-view:Left Rear Tire"));
        case "75":
          return formatLabel(t("damage-view:Left Rear Wheel/Rim"));
        case "76":
          return formatLabel(t("damage-view:Right Rear Tire"));
        case "77":
          return formatLabel(t("damage-view:Right Rear Wheel/Rim"));
        case "78":
          return formatLabel(t("damage-view:Right Front Tire"));
        case "79":
          return formatLabel(t("damage-view:Right Front Wheel/Rim"));
        case "80":
          return formatLabel(t("damage-view:Cowl"));
        case "81":
          return formatLabel(t("damage-view:Gas/Battery Door/Fuel Cap"));
        case "82":
          return formatLabel(t("damage-view:Fender- rear, left"));
        case "83":
          return formatLabel(t("damage-view:Fender - rear, right"));
        case "84":
          return formatLabel(
            t("damage-view:Tools/Jacks/Spare-Tire Mount & Lock"),
          );
        case "85":
          return formatLabel(t("damage-view:SD/Multimedia Kit"));
        case "86":
          return formatLabel(t("damage-view:Parking Sonar System"));
        case "87":
          return formatLabel(t("damage-view:Open"));
        case "88":
          return formatLabel(t("damage-view:Open"));
        case "89":
          return formatLabel(
            t("damage-view:Trailer Hitch, Wiring Harness Tow Hooks"),
          );
        case "90":
          return formatLabel(t("damage-view:Frame"));
        case "91":
          return formatLabel(t("damage-view:Exhaust System"));
        case "92":
          return formatLabel(t("damage-view:License Bracket"));
        case "93":
          return formatLabel(t("damage-view:Steering Wheel/Airbag"));
        case "94":
          return formatLabel(t("damage-view:Seat, Left Front"));
        case "95":
          return formatLabel(t("damage-view:Seat, Right Front"));
        case "96":
          return formatLabel(t("damage-view:Seat, Rear"));
        case "97":
          return formatLabel(t("damage-view:Carpet, Rear"));
        case "98":
          return formatLabel(t("damage-view:Interior"));
        case "99":
          return formatLabel(t("damage-view:Engine Compartment, Other"));
        default:
          return areaCode ?? "";
      }
    },
    [t],
  );

  const getTranslatedSeverityCode = useCallback(
    (severityCode) => {
      const code = severityCode?.toString().toLowerCase();
      const formatLabel = (label) => `${code} - ${label}`;

      switch (code) {
        case "1":
          return formatLabel(
            t(
              'damage-view:Damage up to and including 1" in length / diameter - less than 2.5 cm',
            ),
          );
        case "2":
          return formatLabel(
            t(
              'damage-view:Damage over 1" up to and including 3" in length / diameter - 2.5 cm up to 7.5 cm',
            ),
          );
        case "3":
          return formatLabel(
            t(
              'damage-view:Damage over 3" up to and including 6" in length / diameter - 7.5 cm up to 15 cm',
            ),
          );
        case "4":
          return formatLabel(
            t(
              'damage-view:Damage over 6" up to and including 12" in length / diameter - 15 cm up to 30 cm',
            ),
          );
        case "5":
          return formatLabel(
            t(
              'damage-view:Damage over 12" in length / diameter - 30 cm and over',
            ),
          );
        case "6":
          return formatLabel(t("damage-view:Missing/Major Damage"));
        default:
          return severityCode ?? "";
      }
    },
    [t],
  );

  const getTranslatedTypeCode = useCallback(
    (typeCode) => {
      const code = typeCode?.toString().toLowerCase();
      const formatLabel = (label) => `${code} - ${label}`;

      switch (code) {
        case "01":
          return formatLabel(t("damage-view:Bent"));
        case "02":
          return formatLabel(t("damage-view:Inoperable"));
        case "03":
          return formatLabel(t("damage-view:Cut"));
        case "04":
          return formatLabel(t("damage-view:Dented - Paint or Chrome damaged"));
        case "05":
          return formatLabel(
            t("damage-view:Chipped - Does not apply to glass or panel edge"),
          );
        case "06":
          return formatLabel(
            t("damage-view:Cracked - Does not apply to glass"),
          );
        case "07":
          return formatLabel(t("damage-view:Gouged"));
        case "08":
          return formatLabel(t("damage-view:Missing"));
        case "09":
          return formatLabel(t("damage-view:Scuffed"));
        case "10":
          return formatLabel(t("damage-view:Stained or Soiled - Interior"));
        case "11":
          return formatLabel(t("damage-view:Punctured"));
        case "12":
          return formatLabel(
            t("damage-view:Scratched - Does not apply to glass"),
          );
        case "13":
          return formatLabel(t("damage-view:Torn"));
        case "14":
          return formatLabel(t("damage-view:Dented Paint/Chrome not damaged"));
        case "15":
          return formatLabel(t("damage-view:Full body car cover - damaged"));
        case "16":
          return formatLabel(t("damage-view:Thermal Event/Fire"));
        case "18":
          return formatLabel(
            t("damage-view:Molding/Emblem/Weather-Strip Damaged"),
          );
        case "19":
          return formatLabel(
            t("damage-view:Molding/Emblem/Weather-strip Loose"),
          );
        case "20":
          return formatLabel(t("damage-view:Glass Cracked"));
        case "21":
          return formatLabel(t("damage-view:Glass Broken"));
        case "22":
          return formatLabel(t("damage-view:Glass Chipped"));
        case "23":
          return formatLabel(t("damage-view:Glass Scratched"));
        case "24":
          return formatLabel(t("damage-view:Marker Light Damaged"));
        case "25":
          return formatLabel(t("damage-view:Decal/Paint Stripe Damaged"));
        case "29":
          return formatLabel(t("damage-view:Contamination, Exterior"));
        case "30":
          return formatLabel(t("damage-view:Fluid Spillage, Exterior"));
        case "31":
          return formatLabel(t("damage-view:Theft/Vandalism"));
        case "34":
          return formatLabel(t("damage-view:Chipped Panel Edge"));
        case "36":
          return formatLabel(
            t("damage-view:Incorrect Part or Option not as Invoiced"),
          );
        case "37":
          return formatLabel(t("damage-view:Hardware - Damaged"));
        case "38":
          return formatLabel(t("damage-view:Hardware - Loose, Missing"));
        default:
          return typeCode ?? "";
      }
    },
    [t],
  );

  return {
    getTranslatedFormLabel,
    getTranslatedAreaCode,
    getTranslatedSeverityCode,
    getTranslatedTypeCode,
    getTranslatedFormTextContext,
  };
};

export const useDamageViewStatusOptions = () => {
  const { getTranslatedStatus } = useDamageViewStatusTranslation();

  const options = useMemo(() => {
    const statuses = [
      "submitted",
      "minor",
      "major",
      "warranty",
      "rejected",
      "completed",
    ];
    return statuses.map((status) => ({
      label: getTranslatedStatus(status),
      value: status,
    }));
  }, [getTranslatedStatus]);

  return { options };
};
